import {createSlice} from "@reduxjs/toolkit";
import {useSelector} from "react-redux";
import {store} from "~/store";

const initialState = {
	educations: {},
	customer: false
}

const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		updateEducations: (state, action) => {
			state.educations = action.payload
		},
		updateCustomer: (state, action) => {
			state.customer = action.payload
		}
	}
})

export const { updateEducations, updateCustomer } = appSlice.actions

export const useEducations = () => useSelector(state => state.app.educations)
export const useCustomer = () => useSelector(state => state.app.customer)

export const appState = {
	setEducation: data => store.dispatch(updateEducations(data)),
	setCustomer: data => store.dispatch(updateCustomer(data))
}

export default appSlice.reducer
