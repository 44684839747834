import {useField} from "formik";
import classNames from "classnames";
import {useTranslation} from "react-i18next";

export default function Switch({label = '', status = false, children = '', ...props}) {

	const {t} = useTranslation()
	const [field, meta, helpers] = useField(props)

	return (
		<button
			type="button"
			onClick={() => {
				helpers.setValue(!field.value)
			}}
			className="block w-full text-left">
			<div className={classNames("flex items-center gap-x-4", {
				"justify-between": !label
			})}>
				{children && (
					<p className="text-[15px]">{t(children)}</p>
				)}
				<div className={classNames("h-[14px] flex-shrink-0 w-[37px] rounded-full relative transition-all", {
					"bg-[#4caf5052]": field.value,
					"bg-[#cfcfcf]": !field.value,
				})}>
					<div className={classNames("w-5 h-5 rounded-full absolute top-1/2 -translate-y-1/2 transition-all", {
						"bg-[#4caf50] left-[17px]": field.value,
						"bg-[#595959] left-0": !field.value,
					})} />
				</div>
				{label && <h6 className="text-sm font-medium text-[#242A4A]">
					{t(label)}
					{' '}
				</h6>}
				{status && (field.value ? <span className="text-[#4caf50]">{t('Aktif')}</span> : <span className="text-zinc-500">{t('Pasif')}</span>)}
			</div>
		</button>
	)
}
