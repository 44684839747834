import Yup from "./validation"

export const serviceValidation = Yup.object().shape({
	service_name: Yup.string()
		.required(),
	service_gender: Yup.string()
		.required(),
	service_status: Yup.boolean()
		.required(),
	service_group_id: Yup.number()
		.required(),
	service_type: Yup.number()
		.required(),
	service_price: Yup.number()
		.required(),
	service_currency: Yup.string()
		.required(),
	service_duration: Yup.number()
		.required(),
});
