import Yup from "./validation"

export const servicePackageValidation = Yup.object().shape({
	package_title: Yup.string()
		.required(),
	package_gender: Yup.string()
		.required(),
	package_status: Yup.boolean()
		.required(),
	package_service_group_id: Yup.number()
		.required(),
	package_price: Yup.number()
		.required(),
	package_currency: Yup.string()
		.required(),
	package_seance: Yup.number()
		.required(),
	package_services: Yup.array()
		.min(1)
		.required(),
});
