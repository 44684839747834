import {findReportRangeDate, REPORT_TYPES} from "~/utils/const";
import {useQuery} from "@tanstack/react-query";
import {reportService} from "~/services/app";
import Loader from "~/components/loader";
import {moneyFormat, numberFormat} from "~/utils/format";
import {useParams} from "react-router-dom";
import {dayjs} from "~/utils/date";
import {ShowTotal} from "~/pages/app/dashboard/detail/report-box";

export default function ReportBox({report, interval}) {

    const {token} = useParams()
    const {
        data,
        isFetching,
        isLoading
    } = useQuery(['dashboard/report/detail/public', report.report_id], () => reportService.public(token, report.report_id), {
        keepPreviousData: true,
        refetchInterval: interval
    })

    return (
        <div
            className="bg-white border rounded aspect-square flex flex-col"
        >
            <header className="h-[50px] border-b flex px-4 items-center gap-3 flex-shrink-0 font-medium">
                {REPORT_TYPES[report.report_type].icon}
                <div className="truncate">
                    {report.report_title}
                </div>
            </header>
            <div className="flex items-center justify-center flex-1 relative flex-col py-12 pb-20">
                {isLoading && <Loader/>}
                {data && (
                    <>
                        {isFetching && (
                            <div className="w-full h-full bg-white/40 flex items-center justify-center absolute inset-0 z-20">
                                <Loader/>
                            </div>
                        )}
                        <div
                            className="text-[#676767] tracking-tight absolute bottom-0 left-0 pb-2 w-full text-xs font-light flex justify-center flex-wrap gap-1.5">
                            {data?.data?.data?.report_description?.filter(d => !['date', 'date_start', 'date_end'].includes(d.column))?.map(desc => (
                                <span className="bg-zinc-100 px-1.5 py-0.5 rounded">
                                    {/*<span className="text-green-600 mr-1">{desc.name}:</span>*/}
                                    {typeof desc.value === 'object' ? desc.value.join(', ') : findReportRangeDate(desc.value)}
                                </span>
                            ))}
                        </div>
                        <div
                            className="text-4xl text-[#676767] font-medium tracking-tight text-center">
                            <ShowTotal total={data?.data?.report?.total} />
                            <div className="text-base mt-2 text-zinc-400">
                                {findReportRangeDate(data?.data?.data?.report_description?.find(d => d.column === 'date')?.value)}
                                {data?.data?.data?.report_description?.find(d => d.column === 'date_end')?.value && (
                                    <>
                                        {data?.data?.data?.report_description?.find(d => d.column === 'date_start')?.value && ' - '}
                                        {dayjs(data?.data?.data?.report_description?.find(d => d.column === 'date_end')?.value).format('DD MMMM YYYY')}
                                    </>
                                )}
                            </div>
                        </div>

                    </>
                )}
            </div>
        </div>
    )
}