import {createSlice} from "@reduxjs/toolkit";
import {useSelector} from "react-redux";
import {store} from "~/store";

const initialState = {
	tablet: JSON.parse(localStorage.getItem('tablet')) || null
}

const tablet = createSlice({
	name: 'tablet',
	initialState,
	reducers: {
		_setTablet: (state, action) => {
			state.tablet = action.payload
			localStorage.setItem('tablet', JSON.stringify(action.payload))
		},
		_removeTablet: state => {
			state.tablet = null
			localStorage.removeItem('tablet')
		}
	}
})

export const { _setTablet, _removeTablet } = tablet.actions

export const useTablet = username => {
	const tablet = useSelector(state => state.tablet.tablet)
	if (tablet?.tablet?.username === Number(username)) {
		return tablet
	}
	return false
}
export const setTablet = data => store.dispatch(_setTablet(data))
export const removeTablet = data => store.dispatch(_removeTablet(data))

export default tablet.reducer
