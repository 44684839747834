import {createSlice} from "@reduxjs/toolkit";
import {useSelector} from "react-redux";
import {store} from "~/store";

const initialState = {
	admin: null,
	sidebar: true,
	token: localStorage.getItem('adminToken') || ''
}

const adminSlice = createSlice({
	name: 'admin',
	initialState,
	reducers: {
		updateToken: (state, action) => {
			state.token = action.payload
			localStorage.setItem('adminToken', action.payload)
		},
		updateSidebar: (state, action) => {
			state.sidebar = action.payload
		},
		updateAdmin: (state, action) => {
			state.admin = action.payload
		},
		logoutAdmin: (state) => {
			state.admin = null
			state.token = ''
			localStorage.removeItem('adminToken')
		}
	}
})

export const { updateToken, updateAdmin, updateSidebar, logoutAdmin } = adminSlice.actions

export const useAdmin = () => useSelector(state => state.admin.admin)
export const useAdminToken = () => useSelector(state => state.admin.token)
export const useAdminSidebar = () => useSelector(state => state.admin.sidebar)

export const adminState = {
	setToken: token => store.dispatch(updateToken(token)),
	logout: () => store.dispatch(logoutAdmin()),
	setAdmin: data => store.dispatch(updateAdmin(data)),
	setSidebar: data => store.dispatch(updateSidebar(data)),
}

export default adminSlice.reducer
