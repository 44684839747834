import {get, post} from "~/services/request";

export const authService = {
	login: data => post('auth/login', data),
	google: data => post('auth/google', data),
	register: data => post('auth/register', data),
	info: data => post('auth/info', data),
	changeBranch: data => post('auth/change-branch', data)
}

export const webService = {
	cms: {
		features: data => post('web/cms/feature', data),
		categories: data => post('web/cms/category', data),
		categoryDetail: (id, data) => post(`web/cms/category/${id}`, data),
		pageDetail: (id, data) => post(`web/cms/page/${id}`, data),
	},
	packages: () => post('web/packages'),
	settings: () => get('settings')
}
