import Yup from "./validation"

export const registerValidation = Yup.object().shape({
	user_fullname: Yup.string()
		.required(),
	user_email: Yup.string()
		.required()
		.email(),
	user_password: Yup.string()
		.required()
});
