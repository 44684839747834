import {createContext, useContext, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

const TabletContext = createContext()

export const useTabletData = () => useContext(TabletContext)

export const TabletProvider = ({ children }) => {

	const location = useLocation()
	const [showDevice, setShowDevice] = useState(false)
	const [showSettings, setShowSettings] = useState(false)
	const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light')
	const [zoom, setZoom] = useState(localStorage.getItem('zoom') ? Number(localStorage.getItem('zoom')) : 1)

	useEffect(() => {
		localStorage.setItem('zoom', zoom)
		localStorage.setItem('theme', theme)
		document.body.style.zoom = zoom
		document.body.className = theme
	}, [zoom, theme])

	useEffect(() => {
		setShowSettings(false)
		setShowDevice(false)
	}, [location.pathname]);


	const data = {
		showDevice,
		setShowDevice,
		showSettings,
		setShowSettings,
		zoom,
		setZoom,
		theme,
		setTheme
	}

	return (
		<TabletContext.Provider value={data}>
			{children}
		</TabletContext.Provider>
	)
}
