import {Route, Routes} from "react-router-dom";
import Home from "~/pages/tablet/hardware/home";
import AppointmentDetail from "~/pages/tablet/hardware/appointment";
import Devices from "~/pages/tablet/hardware/devices";
import Settings from "~/pages/tablet/hardware/settings";
import {useTabletData} from "~/pages/tablet/context";
import {useEffect} from "react";

export default function Hardware() {
	const {theme, showDevice, showSettings} = useTabletData()

	useEffect(() => {
		if (theme === 'dark') {
			document.body.style.backgroundColor = '#000'
		} else {
			document.body.style.backgroundColor = '#fff'
		}
	}, [theme]);

	return (
		<>
			{showDevice && <Devices />}
			{showSettings && <Settings />}
			<Routes>
				<Route path="/" element={<Home/>}/>
				<Route path="/appointment/:id" element={<AppointmentDetail />}/>
			</Routes>
		</>
	)
}
