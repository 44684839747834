import { createSlice } from '@reduxjs/toolkit'
import {useSelector} from "react-redux";
import {store} from "~/store/index";

const initialState = {
	appointments: [],
	refetch: () => {}
}

export const appointmentSlice = createSlice({
	name: 'appointment',
	initialState,
	reducers: {
		set: (state, action) => {
			state.appointments = action.payload
		},
		setRefetch: (state, action) => {
			state.refetch = action.payload
		},
		append: (state, action) => {
			state.appointments.push(action.payload)
		}
	},
})

export const { set, setRefetch, append } = appointmentSlice.actions

export const useAppointments = () => {
	const appointments = useSelector(state => state.appointment.appointments)
	return appointments.reduce((acc, curr) => {
		if (!acc[curr.appointment_room_id]) {
			acc[curr.appointment_room_id] = []
		}
		acc[curr.appointment_room_id].push(curr)
		return acc
	}, {})
}

export const useAppointmentList = () => {
	const {appointments, refetch} = useSelector(state => state.appointment)
	return {
		appointments: [...appointments].sort((a, b) => a.appointment_date_start.localeCompare(b.appointment_date_start)),
		refetch
	}
}
export const useAppointment = roomId => {
	const appointments = useAppointments()
	return appointments?.[roomId] || []
}

export const appointment = {
	set: data => store.dispatch(set(data)),
	refetch: data => store.dispatch(setRefetch(data)),
	append: data => store.dispatch(append(data)),
}

export default appointmentSlice.reducer
