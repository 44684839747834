import {createSlice} from "@reduxjs/toolkit";
import {useSelector} from "react-redux";
import {store} from "~/store";

const initialState = {
	user: null,
	subscription: false,
	business: false,
	branch: false,
	token: localStorage.getItem('token') || ''
}

const auth = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		updateToken: (state, action) => {
			state.token = action.payload
			localStorage.setItem('token', action.payload)
		},
		updateUser: (state, action) => {
			state.user = action.payload
		},
		setBusiness: (state, action) => {
			state.business = action.payload
		},
		setBranch: (state, action) => {
			state.branch = action.payload
		},
		setSubscription: (state, action) => {
			state.subscription = action.payload
		},
		logoutUser: (state) => {
			state.user = null
			state.token = ''
			localStorage.removeItem('token')
		}
	}
})

export const { updateToken, logoutUser, updateUser, setSubscription, setBusiness, setBranch } = auth.actions

export const useAuth = () => useSelector(state => state.auth.user)
export const useToken = () => useSelector(state => state.auth.token)
export const useSubscription = () => useSelector(state => state.auth.subscription)
export const useBusiness = () => useSelector(state => state.auth.business)
export const useBranch = () => useSelector(state => state.auth.branch)
export const user = {
	setToken: token => store.dispatch(updateToken(token)),
	setSubscription: data => store.dispatch(setSubscription(data)),
	setBusiness: data => store.dispatch(setBusiness(data)),
	setBranch: data => store.dispatch(setBranch(data)),
	logout: () => store.dispatch(logoutUser()),
	setUser: data => store.dispatch(updateUser(data)),
}

export default auth.reducer
