import {store} from "~/store";

export const permission = (key, value) => {
	const states = store.getState()
	const permissions = states.business.staff.permissions
	return permissions?.[key]?.[value]
}

export function generateRandomNumber() {
	const randomDecimal = Math.random();
	const randomInteger = Math.floor(randomDecimal * 10000000000);
	return randomInteger.toString().padStart(10, '0');
}
