import { createElement } from "react";
import classNames from "classnames";
import { TailSpin } from "react-loader-spinner";
import PropTypes from "prop-types";

export default function Button(props) {

  const {variant, size, loadingColor, loading, as, type, className, children, ...otherProps} = props
  otherProps.type = type

  return createElement(as, {
    className: classNames("flex px-4 items-center gap-x-2.5 justify-center rounded-md disabled:opacity-50 disabled:cursor-not-allowed", {
      "h-14 !px-4 text-[17px]": size === 'big',
      "h-12 !px-4 text-base": size === 'medium',
      "h-10 text-[15px]": size === 'normal',
      "h-7 text-[13px]": size === 'small',
      "!bg-blue-500 text-white hover:!bg-blue-600/90": variant === 'primary',
      "!bg-zinc-100 border text-zinc-700 hover:!bg-zinc-200/60 hover:border-zinc-300": variant === 'default',
      "!bg-white border text-zinc-700 hover:!bg-zinc-100 hover:border-zinc-300 dark:!bg-zinc-800 dark:hover:!bg-zinc-700 dark:text-white dark:!border-zinc-700": variant === 'white',
      "!bg-white border border-zinc-700 text-zinc-700 hover:!bg-zinc-100": variant === 'black-outline',
      "!bg-black border border-black text-white hover:!bg-black/90 dark:!bg-zinc-600 dark:!border-zinc-700 dark:hover:!bg-zinc-600": variant === 'black',
      "!bg-green-600 text-white hover:!bg-green-600/90": variant === 'success',
      "!bg-red-500 text-white hover:!bg-red-500/90": variant === 'delete',
      "!bg-red-50 !border !border-red-600/50 text-red-700 hover:!bg-red-100/60": variant === 'delete-outline',
      "bg-[#4caf50] text-white hover:bg-opacity-90": variant === 'green',
      [className]: !!className
    }),
    ...otherProps
  }, loading ? (
    <TailSpin
      height="20"
      width="20"
      color={loadingColor}
      radius="1"
      visible={true}
    />
  ) : children)
}

Button.propTypes = {
  variant: PropTypes.oneOf(['primary', 'default', 'white', 'black', 'black-outline', 'success', 'delete', 'delete-outline', 'green']),
  size: PropTypes.oneOf(['small', 'normal', 'medium', 'big']),
  loadingColor: PropTypes.string,
  loading: PropTypes.bool,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func
}

Button.defaultProps = {
  variant: 'primary',
  size: 'normal',
  loadingColor: '#fff',
  loading: false,
  as: 'button',
  type: 'button'
}