import {createSlice} from "@reduxjs/toolkit";
import {useSelector} from "react-redux";
import {store} from "~/store";

const initialState = {
	basket: [],
	active: false,
	customer: false,
	step: 1,
	payments: [],
	paidPrice: 0,
	includeTax: false,
	staff: '',
	sale: false,
	packageStepComplete: false
}

const saleReducer = createSlice({
	name: 'sale',
	initialState,
	reducers: {
		updateBasket: (state, action) => {
			state.basket = action.payload
		},
		updateActive: (state, action) => {
			state.active = action.payload
		},
		updateCustomer: (state, action) => {
			state.customer = action.payload
		},
		updateStep: (state, action) => {
			state.step = action.payload
		},
		updatePayments: (state, action) => {
			state.payments = action.payload
			state.paidPrice = action.payload.reduce((total, payment) => total + payment.price, 0);
		},
		updatePackageStepComplete: (state, action) => {
			state.packageStepComplete = action.payload
		},
		updateTax: (state, action) => {
			state.includeTax = action.payload
		},
		updateStaff: (state, action) => {
			state.staff = action.payload
		},
		updateSale: (state, action) => {
			state.sale = action.payload
		},
		clearSale: state => {
			state.basket = []
			state.active = false
			state.step = 1
			// state.customer = false
			state.payments = []
			state.paidPrice = 0
			state.includeTax = false
			state.staff = ''
			state.sale = false
			state.packageStepComplete = false
		}
	}
})

export const {updateBasket, updateTax, updateStaff, updateSale, updatePayments, updateStep, updateActive, updateCustomer, clearSale, updatePackageStepComplete} = saleReducer.actions

export const useBasket = () => useSelector(state => state.sale.basket)
export const useActive = () => useSelector(state => state.sale.active)
export const useSale = () => useSelector(state => state.sale)
export const useCurrentSale = () => useSelector(state => state.sale.sale)
export const usePackageStepComplete = () => useSelector(state => state.sale.packageStepComplete)
export const useStep = () => useSelector(state => state.sale.step)
export const setBasket = data => store.dispatch(updateBasket(data))
export const setActive = data => store.dispatch(updateActive(data))
export const setTax = data => store.dispatch(updateTax(data))
export const setStaff = data => store.dispatch(updateStaff(data))
export const setCustomer = data => store.dispatch(updateCustomer(data))
export const setStep = data => store.dispatch(updateStep(data))
export const setSale = data => store.dispatch(updateSale(data))
export const setPayments = data => store.dispatch(updatePayments(data))
export const resetSale = () => store.dispatch(clearSale())
export const setPackageStepComplete = data => store.dispatch(updatePackageStepComplete(data))

export default saleReducer.reducer
