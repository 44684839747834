import { Listbox } from '@headlessui/react'
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { useFormikContext } from "formik";
import { Fragment } from 'react';
import { IoIosArrowForward } from "react-icons/io";
import { TbUserPlus } from "react-icons/tb";
import { appointmentService } from '~/services/app';
import {useTranslation} from "react-i18next";

export default function SelectStaff({ appointment = {} }) {

  const {t} = useTranslation()
  const { values, setFieldValue } = useFormikContext()

  const { data } = useQuery(['staff'], () => appointmentService.staff())
  const staffOptions = data && data.data.reduce((acc, curr) => [...acc, {
    key: curr.staff_id,
    value: curr.staff_name
  }], [])
  const currentStaff = staffOptions && staffOptions.find(s => s.key === values.staffId)

  return (
    <div className="w-full">

      <Listbox
        disabled={[4, 7].includes(appointment?.appointment_status)}
        className="relative w-full" as="div" value={values.staffId} onChange={id => setFieldValue('staffId', id)}>
        <Listbox.Button
          className={classNames("h-9 w-full px-4 pr-2 outline-none rounded-md border border-zinc-300 hover:border-zinc-500 flex justify-between items-center gap-x-2 text-sm", {
            "pr-4": [4, 7].includes(appointment?.appointment_status)
          })}
        >
          <div className="flex items-center gap-2 whitespace-nowrap">
            <TbUserPlus size={16} />
            {currentStaff ? currentStaff.value : t('Uzman Ata')}
          </div>
          {![4, 7].includes(appointment?.appointment_status) && <IoIosArrowForward size={18} className="ml-2 text-zinc-400"/>}
        </Listbox.Button>
        <Listbox.Options
          as="ul"
          className="absolute bg-white outline-none top-0 py-2 right-0 w-[200px] shadow overflow-hidden z-10"
        >
          {staffOptions?.length > 0 && staffOptions.map((staff) => (
            <Listbox.Option
              key={staff.key}
              value={staff.key}
              as={Fragment}
            >
              {({ active, selected }) => (
                <button
                  type="button"
                  className={classNames("h-9 px-4 rounded-md line-clamp-1 w-full text-left text-sm", {
                    "bg-sky-50 text-sky-500": active,
                  })}
                >
                  {staff.value}
                </button>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </div>
  )
}
