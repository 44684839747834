import {Listbox} from '@headlessui/react'
import classNames from 'classnames';
import {useFormikContext} from "formik";
import {Fragment} from 'react';
import {IoIosArrowForward} from "react-icons/io";
import {statusList} from "~/utils/const";
import {useTranslation} from "react-i18next";

export default function SelectStatus({appointment = {}}) {

	const {t} = useTranslation()
	const {values, setFieldValue} = useFormikContext()

	const currentStatus = statusList?.[values?.status]

	const isDisabled = id => {
		switch (appointment?.appointment_status || values?.status) {
			case 2:
				return ['1', '4', '5'].includes(id)
			case 3:
				return ['1', '2', '5', '6', '7', '8'].includes(id)
			case 8:
				return ['1', '2', '5', '6', '7'].includes(id)
			case 4:
				return ['1', '2','3', '5', '6', '8'].includes(id)
			case 7:
				return ['1', '2','3', '4', '5', '6'].includes(id)
			default:
				return false
		}
	}

	return (
		<div className="w-full">

			<Listbox disabled={[7].includes(appointment?.appointment_status)} className="relative w-full" as="div"
							 value={String(values.status)} onChange={id => setFieldValue('status', +id)}>
				<Listbox.Button
					className={classNames("h-9 px-4 pr-2 w-full outline-none rounded-md border border-zinc-300 hover:border-zinc-500 flex items-center justify-between gap-x-2 text-sm", {
						"pr-4": [7].includes(appointment?.appointment_status)
					})}
				>
					<div className="flex items-center gap-2 whitespace-nowrap">
						<div
							style={{'--color': currentStatus?.color}}
							className="w-3 h-3 rounded-full bg-[color:var(--color)]"
						/>
						{currentStatus ? t(currentStatus.title) : t('Durum')}
					</div>
					{![7].includes(appointment?.appointment_status) && <IoIosArrowForward size={18} className="ml-2 text-zinc-400"/>}
				</Listbox.Button>
				<Listbox.Options
					as="ul"
					className="absolute bg-white outline-none top-0 py-2 right-0 w-[200px] overflow-hidden shadow z-10"
				>
					{Object.entries(statusList).filter(([id]) => Number(id) !== 2).map(([id, status]) => (
						<Listbox.Option
							key={id}
							disabled={isDisabled(id)}
							value={id}
							as={Fragment}
						>
							{({active, selected}) => (
								<button
									disabled={isDisabled(id)}
									type="button"
									className={classNames("h-9 px-4 line-clamp-1 w-full text-left text-sm flex items-center gap-x-2 disabled:grayscale disabled:opacity-50", {
										"bg-sky-50 text-black": active,
										"text-zinc-500": !active
									})}
								>
									<div
										style={{'--color': status.textColor}}
										className="w-3 h-3 rounded-full bg-[color:var(--color)]"
									/>
									{t(status.title)}
								</button>
							)}
						</Listbox.Option>
					))}
				</Listbox.Options>
			</Listbox>
		</div>
	)
}
