import Yup from "./validation"

export const eventValidation = Yup.object().shape({
  title: Yup.string()
    .required(),
  type: Yup.string()
    .required(),
  dataType: Yup.string()
    .required(),
  dataId: Yup.string()
    .required(),
  appointment: Yup.object()
    .required()
});
