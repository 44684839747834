import {IoMdClose} from "react-icons/io"
import {MdOutlineArrowBackIosNew} from "react-icons/md"
import {modal, useModalDisable} from "~/store/modal";
import classNames from "classnames";
import {useTranslation} from "react-i18next";

export default function ModalTitle({ children, back = false }) {

	const {t} = useTranslation()
	const isCloseDisabled = useModalDisable()

	return (
		<header className={classNames("h-16 flex-shrink-0 border-b flex items-center pl-5 pr-2 justify-between sticky top-0 bg-white z-10", {
			"pl-2": back
		})}>
			<h3 className="text-lg font-medium flex items-center gap-x-2.5">
				{back && (
					<button
						type="button"
						onClick={back}
						className="w-9 h-12 rounded-md flex items-center justify-center hover:bg-zinc-50 text-zinc-700">
						<MdOutlineArrowBackIosNew size={14} />
					</button>
				)}
				{children}
			</h3>
			{!isCloseDisabled && (
				<button
					type="button"
					onClick={() => modal.removeLast()}
					className="w-12 h-12 rounded-md flex items-center justify-center hover:bg-zinc-50 text-zinc-700">
					<IoMdClose size={24} />
				</button>
			)}
		</header>
	)
}
