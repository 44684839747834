import SignDocumentModal from "~/modals/tablet/sign";
import ConfirmModal from "~/modals/shared/confirm";

const modals = [
  {
    name: 'tablet.sign',
    element: SignDocumentModal
  },
  {
    name: 'shared.confirm',
    element: ConfirmModal
  }
]

export default modals
