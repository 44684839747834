import {Link, useParams, useSearchParams} from "react-router-dom";
import OpacityContent from "~/components/animated/opacity/index.jsx";
import {useQuery} from "@tanstack/react-query";
import {invoiceService} from "~/services/app/index.js";
import Loader from "~/components/loader/index.jsx";
import {moneyFormat} from "~/utils/format.js";
import {Form, Formik} from "formik";
import Input from "~/components/form/input/index.jsx";
import Button from "~/components/form/button/index.jsx";
import {Helmet} from "react-helmet";
import {useEffect, useState} from "react";
import {useSettings, webState} from "~/store/web.js";
import Switch from "~/components/form/switch/index.jsx";
import {modal} from "~/store/modal.js";

const INVOICE_TYPES = {
    PACKAGE: 'Paket',
    STORAGE: 'Depolama',
    WHATSAPP: 'WhatsApp',
    OTHER: 'Özel'
}

export default function Invoice() {

    const settings = useSettings()
    const {id} = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const {data, isLoading} = useQuery([`invoice/detail/${id}`], () => invoiceService.detail(id))

    const [form, setForm] = useState(false)

    useEffect(() => {
        webState.setHeader(false)
        webState.setFooter(false)
        return () => {
            webState.setHeader(true)
            webState.setFooter(false)
        }
    }, [])

    useEffect(() => {
        if (form) {
            document.getElementById("three_d_form").submit()
        }
    }, [form])

    return (
        <OpacityContent className="w-screen h-[100dvh] bg-[#f6f8f9]">

            <Helmet>
                <title>Fatura #{id} - {import.meta.env.VITE_TITLE}</title>
            </Helmet>

            <div className="container mx-auto py-10 w-full max-w-[1000px]">

                <header className="flex items-center justify-between mb-10">
                    <Link to="/" className="flex items-center gap-3">
                        <img src="/wise-dark.svg" alt="" className="w-8 h-8"/>
                        <div className="text-2xl font-extralight text-[#3f3f3f]">
                            <span className="font-medium">Wise</span>CRM
                        </div>
                    </Link>

                    <div className="font-extralight text-xl flex flex-col items-end">
                        <div className="text-base text-zinc-500">FATURA NO</div>
                        <span>{id}</span>
                    </div>
                </header>

                {searchParams.get('status') === 'error' && (
                    <div className="bg-red-600 text-white p-3 rounded text-sm mb-4">
                        <h3 className="font-medium text-lg mb-1">Ödeme Hatası</h3>
                        {searchParams.get('message')}
                    </div>
                )}

                {isLoading && <Loader/>}

                {data && (
                    <div className="flex gap-4">

                        <div className="w-[60%] grid gap-4">

                            <section>
                                <div
                                    className="bg-white p-6 pt-5 rounded-md shadow-[0_1px_4px_0_rgba(154,154,154,0.5)]">
                                    <h3 className="mb-2.5 font-medium text-zinc-500 tracking-tight pb-2">
                                        Fatura Bilgileri
                                    </h3>
                                    {data?.data?.address && (
                                        <>
                                            <h6 className="mb-1 font-medium flex items-center justify-between">
                                                {data?.data?.address?.company}
                                            </h6>
                                            <div
                                                className="text-[15px]">{data?.data?.address?.name} {data?.data?.address?.surname}</div>
                                            <div className="text-[15px]">{data?.data?.address?.email}</div>
                                            <div className="text-[15px]">{data?.data?.address?.address}</div>
                                            <div className="text-[15px]">{data?.data?.address?.taxNumber}</div>
                                        </>
                                    ) || (
                                        <div className="text-[15px] bg-zinc-100 p-3 rounded">Fatura adresi
                                            bulunamadı.</div>
                                    )}
                                </div>
                            </section>

                            <section>
                                <div
                                    className="bg-white p-6 pt-5 rounded-md shadow-[0_1px_4px_0_rgba(154,154,154,0.5)]">
                                    <h3 className="mb-2.5 font-medium text-zinc-500 tracking-tight pb-2">
                                        {INVOICE_TYPES[data.data.invoice_type]} Bilgileri
                                    </h3>
                                    <div className="flex flex-col mb-3.5 bg-green-50 text-green-700 -mx-6 px-6 py-3">
                                        <h3 className="text-lg font-medium">{data.data.business.name}</h3>
                                        <h3 className="text-base text-green-900/70 font-medium">{data.data.business.slug}.wisecrm.io</h3>
                                    </div>
                                    <div className="grid gap-2.5 text-sm">
                                        {data.data.invoice_type === 'PACKAGE' && (
                                            <>
                                                <div className="flex items-center justify-between">
                                                    <div>Planınız</div>
                                                    <div className="font-medium">{data.data.package.name}</div>
                                                </div>
                                                <div className="flex items-center justify-between">
                                                    <div>Faturalandırma</div>
                                                    <div className="font-medium">Şube başına / Yıl</div>
                                                </div>
                                                <div className="flex items-center justify-between">
                                                    <div>{data.data.package.branchCount} Şube
                                                        × <span
                                                            className="font-mono">{moneyFormat(data.data.package.price)}</span>
                                                    </div>
                                                    <div
                                                        className="font-mono">{moneyFormat(data.data.invoice_amount)}</div>
                                                </div>
                                            </>
                                        )}
                                        {data.data.invoice_type === 'STORAGE' && (
                                            <>
                                                <div className="flex items-center justify-between">
                                                    <div>Alan</div>
                                                    <div className="font-medium">{data.data.storage.size}</div>
                                                </div>
                                                <div className="flex items-center justify-between">
                                                    <div>Faturalandırma</div>
                                                    <div className="font-medium">Tüm Şubeler için / Yıl</div>
                                                </div>
                                                <div className="flex items-center justify-between">
                                                    <div>Fiyat</div>
                                                    <div
                                                        className="font-mono">{moneyFormat(data.data.invoice_amount)}</div>
                                                </div>
                                            </>
                                        )}
                                        {data.data.invoice_discount > 0 && (
                                            <div className="flex items-center justify-between text-green-600">
                                                <div>İndirim</div>
                                                <div
                                                    className="font-mono">{moneyFormat(-data.data.invoice_discount)}</div>
                                            </div>
                                        )}
                                        {settings?.tax && (
                                            <div className="flex items-center justify-between">
                                                <div>KDV (%{settings?.tax})</div>
                                                <div
                                                    className="font-mono">{moneyFormat(
                                                    (data.data.invoice_amount - data.data.invoice_discount) * (settings?.tax * 0.01)
                                                )}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </section>

                        </div>

                        <div className="flex-1">

                            {data.data.invoice_status === 1 && (
                                <div
                                    className="bg-green-50 border border-green-600 p-5 rounded-md text-center text-lg font-medium text-green-600">
                                    Bu fatura ödenmiştir.
                                </div>
                            )}

                            {data.data.invoice_status === 3 && (
                                <div
                                    className="bg-red-50 border border-red-600 p-5 rounded-md text-center text-lg font-medium text-red-600">
                                    Bu fatura iptal edilmiştir.
                                </div>
                            )}

                            {data.data.invoice_status === 2 && (
                                <section className="bg-[#fafafa] border border-[#d9d9d9] rounded-md p-6">

                                    <div className="mb-4 pb-4 border-b">
                                        <div className="text-zinc-600 text-[15px]">
                                            Ödenecek Tutar
                                        </div>
                                        <div className="font-mono text-2xl text-green-800 font-semibold">
                                            {moneyFormat(
                                                (data.data.invoice_amount - data.data.invoice_discount) +
                                                (settings?.tax ? (data.data.invoice_amount - data.data.invoice_discount) * (settings?.tax * 0.01) : 0)
                                            )}
                                        </div>
                                    </div>

                                    <Formik
                                        initialValues={{
                                            card_owner: '',
                                            card_number: '',
                                            card_expire: '',
                                            card_cvv: '',
                                            terms: !['STORAGE', 'PACKAGE'].includes(data.data.invoice_type)
                                        }}
                                        onSubmit={async (values, helper) => {

                                            const response = await invoiceService.pay(id, values)
                                            helper.setSubmitting(true)
                                            setForm(response.data)

                                        }}
                                    >
                                        {({setFieldValue, values, isSubmitting}) => (
                                            <Form className="grid gap-4">
                                                <Input name="card_owner" label="Kart Sahibi"/>
                                                <Input name="card_number" label="Kart Numarası"
                                                       mask="9999 9999 9999 9999"/>
                                                <div className="grid grid-cols-2 gap-4">
                                                    <Input name="card_expire" label="S.K.T." mask="99/99"/>
                                                    <Input name="card_cvv" label="CVV" mask="999"/>
                                                </div>

                                                {data.data.invoice_type === 'PACKAGE' && (
                                                    <Switch
                                                        name="terms"
                                                        label={
                                                            <>
                                                                <button
                                                                    type="button"
                                                                    className="text-green-600 mr-1.5 underline"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        modal.append('web.user-agreement', {
                                                                            ...data.data,
                                                                            setFieldValue
                                                                        })
                                                                    }}>
                                                                    Kullanıcı Sözleşmesini
                                                                </button> okudum, onaylıyorum.
                                                            </>
                                                        }
                                                    />
                                                )}

                                                {data.data.invoice_type === 'STORAGE' && (
                                                    <Switch
                                                        name="terms"
                                                        label={
                                                            <>
                                                                <button
                                                                    type="button"
                                                                    className="text-green-600 inline-flex flex-wrap mr-1.5 underline"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        modal.append('web.storage-agreement', {
                                                                            ...data.data,
                                                                            setFieldValue
                                                                        })
                                                                    }}>
                                                                    Depolama Alanı Kullanım Sözleşmesini
                                                                </button> okudum, onaylıyorum.
                                                            </>
                                                        }
                                                    />
                                                )}

                                                <Button
                                                    variant="green"
                                                    type="submit"
                                                    loading={isSubmitting}
                                                    disabled={isSubmitting || !values.terms}
                                                    size="big"
                                                    className="font-medium !h-12 mt-4"
                                                >
                                                    Ödeme yap
                                                </Button>
                                            </Form>
                                        )}
                                    </Formik>
                                    {form && (
                                        <form action={form.url} method="post" id="three_d_form"
                                              style={{display: 'none'}}>
                                            <input type="text" name="parameters" value={form.parameters}/>
                                            <input type="submit" value="Öde"/>
                                        </form>
                                    )}
                                </section>
                            )}

                        </div>

                    </div>
                )}

            </div>
        </OpacityContent>
    )
}