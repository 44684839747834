import {IoSearchOutline} from "react-icons/io5";

export default function Empty({title, children}) {
	return (
		<div className="flex items-center text-center h-[350px] gap-7 justify-center flex-col">
			<IoSearchOutline size={60} className="text-zinc-300" />
			<div className="grid gap-2">
				<h6 className="text-2xl dark:text-white font-semibold">{title}</h6>
				<p className="text-base text-zinc-700 dark:text-zinc-400">
					{children}
				</p>
			</div>
		</div>
	)
}
