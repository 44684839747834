import ModalTitle from "~/components/modal/modal-title";
import {moneyFormat, phoneFormat} from "~/utils/format";
import SignaturePad from 'react-signature-pad-wrapper'
import {useRef, useState} from "react";
import Button from "~/components/form/button";
import dayjs from "dayjs";
import {tabletService} from "~/services/app";
import toast from "react-hot-toast";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import {dateFormat} from "~/utils/date.js";

export default function SignDocumentModal({data, close}) {

    const {t} = useTranslation()
    const [loader, setLoader] = useState(false)
    const [sign, setSign] = useState(false)
    const [signature, setSignature] = useState(false)

    let content = data?.document?.document_content
        .replace(/\[id\]/g, data?.customer?.customer_id)
        .replace(/\[name\]/g, data?.customer?.customer_name)
        .replace(/\[address\]/g, data?.customer?.customer_address || '____________')
        .replace(/\[birthday\]/g, data?.customer?.customer_birthday || '____________')
        .replace(/\[identityNumber\]/g, data?.customer?.customer_identity || '____________')
        .replace(/\[phone\]/g, phoneFormat('+' + String(data?.customer?.customer_phone_ext) + String(data?.customer?.customer_phone)))
        .replace(/\[phone2\]/g, data?.customer?.customer_phone2_ext ? phoneFormat('+' + String(data?.customer?.customer_phone2_ext) + String(data?.customer?.customer_phone2)) : '____________')

    if (data?.cd_data?.totalSeance) {
        content = content.replace(/\[totalSeance\]/g, data?.cd_data?.totalSeance)
    }
    if (data?.cd_data?.sale_date) {
        content = content.replace(/\[date\]/g, data?.cd_data?.sale_date)
    }

    if (data?.cd_data?.installments) {
        const installments = data?.cd_data?.installments?.length > 0 ? `
		<div>
			<table class="w-full border border-black">
				<tbody>
				${data?.cd_data?.installments?.length > 0 && [{
            no: 'advance',
            date: data?.cd_data?.date,
            amount: data?.cd_data?.advance
        }, ...data?.cd_data?.installments]?.reduce((acc, {date, no, amount}, index) => {
            let html = ''
            if (index === 0) {
                html += '<tr>'
            }
            html += `<td class="text-xs border border-black px-1.5 py-0.5 font-semibold">${no === 'advance' ? t('Peşinat') : t('{{no}}. Taksit', {no})}</td>
						<td class="text-xs border font-normal border-black px-1.5 py-0.5">${date ? dateFormat(date, 'DD/MM/YYYY') : ''}</td>
						<td class="text-xs border font-normal border-black px-1.5 py-0.5">${moneyFormat(amount)}</td>`
            if ((index + 1) % 3 === 0) {
                html += '</tr>'
            }
            return [...acc, html]
        }, []).join('')}
				</tbody>
			</table>
		</div>
	` : ''
        content = content.replace(/\[installments\]/g, installments)
    }

    if (Object.values(data?.cd_data)?.length > 0) {
        Object.entries(data?.cd_data).map(([key, value]) => {
            if (key !== 'installments' || key !== 'totalSeance') {
                content = content.replaceAll(`[${key}]`, value ? (typeof value === 'number' ? moneyFormat(value) : value) : '____________')
            }
        })
    }

    const ref = useRef()

    return (
        <>
            <div className="w-screen h-[calc(100dvh-97px)] max-w-full bg-white overflow-auto">
                <ModalTitle>
                    <div className="flex flex-col">
                        <div className="text-xs font-medium">
                            <span className="text-green-600">{data?.customer?.customer_name}</span>
                        </div>
                        <div className="text-lg">
                            {data?.document?.document_title}
                        </div>
                    </div>
                </ModalTitle>
                <div className="p-5">
                    <div
                        className="editor inline-editor"
                        dangerouslySetInnerHTML={{
                            __html: content
                        }}
                    />
                    <div className="flex justify-center mt-10 pb-6">
                        <div className="w-[500px]">
                            <div className="grid grid-cols-2 gap-4 mb-3">
                                <div>
                                    <div className="text-sm text-zinc-500">{t('Müşteri')}:</div>
                                    {data.customer?.customer_name}
                                </div>
                                <div>
                                    <div className="text-sm text-zinc-500">{t('Tarih')}:</div>
                                    {dayjs().format('DD MMMM YYYY')}
                                </div>
                            </div>

                            {!signature && (
                                <div className="w-[500px]">
                                    <div className="text-sm text-zinc-500 mb-1">{t('İmza')}</div>
                                    <div
                                        className="w-[500px] h-[250px] flex items-center justify-center border border-zinc-500">
                                        <Button variant="green" size="big" onClick={() => setSign(true)}>
                                            {t('İmzala')}
                                        </Button>
                                    </div>
                                </div>
                            )}

                            {signature && (
                                <div className="w-[500px]">
                                    <div className="text-sm text-zinc-500 mb-1">{t('İmza')}</div>
                                    <div className="border border-zinc-500"
                                         dangerouslySetInnerHTML={{__html: signature}}/>
                                    <div className="flex justify-center mt-4">
                                        <Button variant="green" onClick={() => {
                                            ref.current.clear()
                                            setSign(true)
                                            setSignature(false)
                                        }}>
                                            {t('Tekrar İmzala')}
                                        </Button>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>
            <div className="border-t p-5 sticky bottom-0 bg-white justify-end flex items-center gap-4">
                <Button size="big" onClick={close} variant="white">
                    {t('Vazgeç')}
                </Button>
                <Button
                    onClick={async () => {
                        if (ref.current.isEmpty()) {
                            toast.error(t('Lütfen belgeyi imzalayıp tekrar deneyin.'))
                            return
                        }
                        setLoader(true)
                        await tabletService.sign({
                            cd_id: data?.cd_id,
                            cd_signature: ref.current.toSVG()
                        })
                        await data?.onSuccess?.()
                        setLoader(false)
                        close()
                    }}
                    size="big"
                    variant="black"
                    disabled={loader}
                    loading={loader}
                >
                    {t('İşlemi Bitir')}
                </Button>
            </div>
            <div
                className={classNames("flex items-center justify-center flex-col absolute inset-0 bg-white z-[999]", {
                    "visible": sign,
                    "invisible": !sign
                })}>
                <div className="w-[500px]">
                    <div className="text-sm text-zinc-500 mb-1">{t('İmza')}</div>
                    <div className="border border-zinc-600 mb-3">
                        <SignaturePad
                            ref={ref}
                            options={{
                                minWidth: 1,
                                maxWidth: 1,
                                penColor: '#000'
                            }}
                        />
                    </div>
                </div>

                <div className="flex items-center gap-3">
                    <Button variant="black" onClick={() => ref.current.clear()}>
                        {t('Temizle')}
                    </Button>
                    <Button variant="green" onClick={() => {
                        setSign(false)
                        setSignature(ref.current.isEmpty() ? false : ref.current.toSVG())
                    }}>
                        {t('Tamam')}
                    </Button>
                </div>
            </div>
        </>
    )
}
