import Yup from "./validation"

export const staffValidation = Yup.object().shape({
  staff_name: Yup.string()
    .required(),
  staff_job_id: Yup.string()
    .required(),
  // staff_username: Yup.string()
  //   .when('staff_login', {
  //     is: true,
  //     then: Yup.string().required()
  //   }),
  // staff_password: Yup.string()
  //   .when('staff_login', {
  //     is: true,
  //     then: Yup.string().required()
  //   })
});
