import classNames from "classnames";
import {statusList} from "~/utils/const.jsx";
import {useTranslation} from "react-i18next";

export default function Status({ id }) {
	const {t} = useTranslation()
	return (
		<button
			style={{
				backgroundColor: statusList?.[id]?.color,
				color: statusList?.[id]?.textColor
			}}
			className="h-6 flex items-center rounded px-2 text-[13px] font-medium tracking-tight">
			{id === 1 && t('BEKLENİYOR')}
			{id === 2 && t('GELDİ')}
			{id === 3 && t('İŞLEMDE')}
			{id === 4 && t('TAMAMLANDI')}
			{id === 5 && t('GELMEDİ')}
			{id === 6 && t('ERTELENDİ')}
			{id === 7 && t('İPTAL EDİLDİ')}
			{id === 8 && t('ODADA')}
		</button>
	)
}
