import i18n from "i18next";
import {initReactI18next} from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(LanguageDetector)
    .use(Backend)
    .use(initReactI18next)

    .init({
        supportedLngs: ['tr', 'en', 'ar', 'da', 'de', 'es', 'fi', 'fr', 'it', 'ja', 'ko', 'lv', 'nl', 'pl', 'pt', 'ru', 'sv', 'id', 'no', 'cn'],
        fallbackLng: 'tr',
        load: 'languageOnly',
        backend: {
            loadPath: import.meta.env.VITE_API_URL + '/api/v1/language/{{lng}}'
        }
    })

export default i18n