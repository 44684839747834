import Yup from "./validation"

export const productValidation = Yup.object().shape({
	product_name: Yup.string()
		.required(),
	product_brand_id: Yup.number()
		.required(),
	product_group_id: Yup.number()
		.required(),
	product_stock: Yup.number()
		.required(),
	product_purchase_price: Yup.object().shape({
		price: Yup.number().required(),
		currency: Yup.string().required()
	}),
	product_sale_price: Yup.object().shape({
		price: Yup.number().required(),
		currency: Yup.string().required()
	}),
	product_status: Yup.boolean()
		.required()
});
