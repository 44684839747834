import {useEffect, useState} from "react";
import {modal} from "~/store/modal";
import {useTranslation} from "react-i18next";

export default function ConfirmModal({ data, close }) {

	const {t} = useTranslation()
	const [loading, setLoading] = useState(false)
	useEffect(() => {
		modal.setCloseDisable(true)
		return () => {
			modal.setCloseDisable(false)
		}
	}, [])

	return (
		<div className="w-full flex flex-col h-full lg:h-auto items-center justify-center lg:w-[400px]">
			<h3 className="p-5 text-center text-lg">{data?.title || 'Emin misin?'}</h3>
			{data?.description && (
				<p className="text-[15px] text-center pb-4 px-4 text-zinc-500 -mt-4">
					{data?.description}
				</p>
			)}
			<div className="grid grid-cols-2 gap-2 p-1.5 w-full">
				<button onClick={close} className="h-10 flex items-center rounded justify-center text-zinc-500 bg-zinc-50" type="button">{t('Vazgeç')}</button>
				<button disabled={loading} onClick={() => data?.onConfirm({ close, loading, setLoading })} className="h-10 flex items-center rounded justify-center text-white bg-black disabled:opacity-50 disabled:pointer-events-none" type="button">
					{loading ? '...' : t('Devam et')}
				</button>
			</div>
		</div>
	)
}
