import {useTablet} from "~/store/tablet";
import {useParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {tabletService} from "~/services/app";
import Header from "~/pages/tablet/header";
import Loader from "~/components/loader";
import {HiOutlineDocumentText} from "react-icons/hi";
import {dateFormat} from "~/utils/date";
import {BsClock} from "react-icons/bs";
import {modal} from "~/store/modal";
import Empty from "~/components/empty";
import Button from "~/components/form/button";
import {useTabletData} from "~/pages/tablet/context";
import Settings from "~/pages/tablet/hardware/settings";
import {useEffect} from "react";

export default function Signature() {
	const {slug} = useParams()
	const tablet = useTablet(slug)

	const {data, refetch, isFetching, isLoading} = useQuery(['tablet/documents', slug], () => tabletService.documents(), {
		refetchInterval: 10000
	})

	const {theme, showSettings} = useTabletData()

	useEffect(() => {
		if (theme === 'dark') {
			document.body.style.backgroundColor = '#000'
		} else {
			document.body.style.backgroundColor = '#f4f4f5'
		}
	}, [theme]);

	return (
		<>
			{showSettings && <Settings />}
			<Header name={tablet.tablet.name}>
				<Button variant="white" onClick={refetch} loading={isFetching}>
					Yenile
				</Button>
			</Header>
			<div className="h-full p-4 bg-zinc-100 dark:bg-black flex-1">
				{isLoading && <Loader />}
				{(!isLoading && data?.data?.length === 0) && (
					<Empty title="İmzalanacak belge bulunamadı">
						Bir belge imzalanmak için gönderildiğinde imza bekleyen belgelerinizin listesini burada görebilirsiniz.
					</Empty>
				)}
				{data && (
					<div className="grid gap-2.5">
						{data.data.map(document => (
							<button
								onClick={() => modal.append('tablet.sign', {
									...document,
									onSuccess: async () => {
										await refetch()
									}
								})}
								key={document.cd_id}
								type="button"
								className="bg-white p-4 shadow text-left rounded w-full"
							>
								<h6 className="flex mb-2 items-center texs-[15px] font-medium gap-3">
									<HiOutlineDocumentText size={24} />
									{document.document.document_title}
								</h6>
								<div className="flex items-center">
									<span className="mr-1 text-green-600">{document.customer.customer_name}</span>
									adlı müşteri için imza bekleniyor.
								</div>
								<div className="text-sm text-zinc-500 flex items-center gap-2 mt-2">
									<BsClock size={16} />
									Oluşturulma tarihi: {dateFormat(document.cd_created, 'DD MMMM YYYY, HH:mm')}
								</div>
							</button>
						))}
					</div>
				)}
			</div>
		</>
	)
}
