import {AiOutlineInfoCircle} from "react-icons/ai";
import {moneyFormat} from "~/utils/format";
import {modal} from "~/store/modal";
import {useFormikContext} from "formik";
import {useTranslation} from "react-i18next";

export default function Balance({refetch}) {

	const {t} = useTranslation()
	const {values: {data}} = useFormikContext()
	return (
		<div className="mx-4 mt-4 pl-10">
			<div
				className="border bg-white shadow-balance rounded-lg border-[#e6a925] text-[13px] text-[#926f24] p-2 flex items-center justify-between">
				<div className="flex items-center gap-x-2 pl-1">
					<AiOutlineInfoCircle size={20} className="text-[#e6a925]"/>
					{t('{{balance}} bakiyesi bulunmaktadır', {balance: moneyFormat(data.customer.customer_balance)})}
				</div>
				<button
					onClick={() => {
						modal.append('app.customer.installment', {
							...data.customer,
							onSuccess: balance => {
								refetch()
							}
						})
					}}
					type="button"
					className="h-7 px-3 rounded-full text-[13px] tracking-tighter text-white bg-[#e6a925]"
				>
					{t('Ödeme Al')}
				</button>
			</div>
		</div>
	)
}
