import {setStaff, setStaffToken} from "~/store/business.js";
import {Form, Formik} from "formik";
import {staffLoginValidation} from "~/validation/index.js";
import {businessService} from "~/services/app/index.js";
import Input from "~/components/form/input/index.jsx";
import Button from "~/components/form/button/index.jsx";
import {useNavigate} from "react-router-dom";

export default function AppLogin() {

    const navigate = useNavigate()

    return (
        <div className="w-full flex bg-zinc-100 items-center justify-center h-[100vh]">
            <Formik
                validationSchema={staffLoginValidation}
                initialValues={{
                    username: '',
                    password: '',
                }}
                onSubmit={async (values, actions) => {
                    const response = await businessService.globalLogin(values)
                    setStaff(response.data)
                    setStaffToken(response.data.token)
                    navigate(`/${response.data.business.business_slug}`, {replace: true})
                }}
            >
                {({isSubmitting}) => (
                    <Form className="w-[400px] bg-white p-5 rounded-lg shadow-lg grid gap-y-4">
                        {/*<CheckAutoLogin/>*/}
                        <h3 className="text-center border-b pb-5 mb-1 border-zinc-200">
                            <span className="text-lg font-semibold">WiseCRM</span>
                            <div className="text-xs text-zinc-600 mt-0.5">
                                işletmenize giriş yapın
                            </div>
                        </h3>
                        <Input label="Kullanıcı Adı" name="username"/>
                        <Input label="Parola" type="password" name="password"/>
                        <Button loading={isSubmitting} type="submit" variant="black">Giriş Yap</Button>
                    </Form>
                )}
            </Formik>
        </div>
    )
}