import {useQuery, useQueryClient} from "@tanstack/react-query";
import {tabletService} from "~/services/app";
import Loader from "~/components/loader";
import {getNamePrefix} from "~/utils/format";
import classNames from "classnames";
import {dateFormat} from "~/utils/date";
import {AiOutlinePushpin} from "react-icons/ai";
import {useState} from "react";
import {IoPaperPlaneOutline} from "react-icons/io5";

export default function Notes({customerId, appointmentId}) {

	const {data, isLoading} = useQuery(['notes', customerId], () => tabletService.appointmentNotes(customerId))

	return (
		<div className="fixed h-[calc(100%-10rem)] flex flex-col overflow-auto w-[400px] bottom-6 border-zinc-500 rounded border right-[100px] bg-white dark:bg-zinc-700 dark:border-zinc-600 z-[11]">
			{isLoading && <Loader/>}
			<AddNote customerId={customerId} appointmentId={appointmentId}/>
			{data?.pinned?.length > 0 && data?.pinned?.map((note, key) => <Note key={key} note={note}/>)}
			{data?.data?.length > 0 && data?.data?.map((note, key) => <Note key={key} note={note}/>)}
		</div>
	)
}

function AddNote({ customerId, appointmentId }) {
	const queryClient = useQueryClient()
	const [note, setNote] = useState('')
	const [loading, setLoading] = useState(false)

	const saveNote = () => {
		setLoading(true)
		tabletService.newAppointmentNote({
			note,
			appointment_id: appointmentId
		})
			.then(result => {
				queryClient.setQueryData(['notes', customerId], data => {
					const d = {...data}
					d.data = [result.data, ...d.data]
					return d
				})
					.then(() => {
						setLoading(false)
						setNote('')
					})
			})
			.finally(() => {
				setLoading(false)
				setNote('')
			})
	}

	return (
		<div className="bg-white/70 dark:bg-zinc-700/70 backdrop-blur-sm p-4 sticky bottom-0">
			<textarea
				value={note}
				placeholder="Not Ekle"

				onChange={(e) => setNote(e.target.value)}
				onKeyDown={(e) => setNote(e.target.value)}
				onKeyUp={(e) => setNote(e.target.value)}
				className="min-h-[80px] pr-12 p-4 rounded-md outline-none focus:border-zinc-500 border border-zinc-300 dark:bg-zinc-800 dark:border-zinc-600 dark:text-white text-sm w-full"
			/>
			<button
				type="button"
				disabled={!note}
				onPointerDown={saveNote}
				className="absolute disabled:opacity-50 disabled:pointer-events-none top-[1.35rem] right-[1.5rem] h-9 w-9 bg-green-500 text-white flex items-center justify-center rounded-full"
			>
				{loading ? '...' : <IoPaperPlaneOutline size={18}/>}
			</button>
		</div>
	)
}

function Note({note}) {
	return (
		<div className={classNames("py-2 px-4 group last:mb-0 gap-x-2 flex items-end", {
			"sticky top-0 bg-white/20 dark:bg-zinc-700/60 backdrop-blur-md": note.pinned
		})}>
			<div
				className="w-8 h-8 rounded-full flex-shrink-0 bg-blue-100 text-blue-500 text-xs font-medium flex items-center justify-center">
				{getNamePrefix(note.staff.name)}
			</div>
			<div
				className={classNames("bg-white dark:bg-zinc-800 dark:border-zinc-700 dark:shadow-zinc-800 dark:text-white shadow-chat border border-zinc-300 px-4 py-3 rounded-tl-2xl rounded-tr-2xl flex-col rounded-br-2xl w-[100%] inline-flex text-[13px] rounded-bl", {
					"!border-[#e6a925]": note.pinned
				})}>
				{note.note}

				<div className="flex items-center mt-2 justify-between">
					<div className="text-[10px] text-zinc-500">
						{dateFormat(note.date)} - <span className="text-zinc-800 dark:text-zinc-400">{note.staff.name}</span> ekledi
					</div>
					<div className="flex gap-2 items-center">
						{note.pinned && (
							<div className="flex text-[11px] text-orange-600 gap-1 items-center">
								Sabitli
								<AiOutlinePushpin size={12} />
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}
