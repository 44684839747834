import {useParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {dashboardService} from "~/services/app";
import Loader from "~/components/loader";
import ReportBox from "~/pages/dashboard/report-box";
import {useState} from "react";
import {Popover} from "@headlessui/react";
import classNames from "classnames";

const INTERVALS = {
    60000: '1 dakika',
    300000: '5 dakika',
    900000: '15 dakika',
    1800000: '30 dakika',
    3600000: '1 saat',
}

export default function PublicDashboard() {

    const [interval, setInterval] = useState(60000)
    const {token} = useParams()
    const {data, isLoading} = useQuery(['dashboard/public', token], () => dashboardService.public(token))

    return (
        <div className="h-[100dvh] bg-[#f6f8f9] flex flex-col overflow-auto">

            <header className="h-[70px] bg-white flex sticky top-0 z-10 justify-between items-center px-4 border-b flex-shrink-0">

                <div className="h-12 border rounded-full flex items-center gap-2.5 pl-4 pr-5 text-lg">
                    <svg xmlns="http://www.w3.org/2000/svg" height="26" width="26"
                         viewBox="0 -960 960 960"
                         fill="currentColor">
                        <path opacity=".597"
                              d="M540.62-598.77v-195.69h253.84v195.69H540.62ZM165.54-482.46v-312h253.84v312H165.54Zm375.08 316.92v-312h253.84v312H540.62Zm-375.08 0v-195.69h253.84v195.69H165.54Zm36.92-353.84h180v-238.16h-180v238.16Zm375.08 316.92h180v-238.16h-180v238.16Zm0-433.23h180v-121.85h-180v121.85ZM202.46-202.46h180v-121.85h-180v121.85Zm180-316.92Zm195.08-116.31Zm0 195.07ZM382.46-324.31Z"/>
                    </svg>
                    {data?.link?.dashboard_name}
                </div>

                <div className="text-lg font-medium">
                    {data?.link?.business_name}
                </div>

                <Popover>
                    {({ close }) => (
                        <>
                            <Popover.Button
                                className="h-10 flex items-center gap-2.5 border rounded-md font-medium pl-5 pr-2.5 border-[#c5c5c5]"
                            >
                                {INTERVALS[interval]}
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                                     fill="#c5c5c5">
                                    <path
                                        d="M481.54-180q-125.63 0-212.81-87.17-87.19-87.17-87.19-212.77 0-125.6 87.19-212.83Q355.91-780 481.54-780q70.15 0 132.77 31.19 62.61 31.2 104.15 88.04V-780h60v244.61H533.85v-59.99h158q-31.62-57.93-87.7-91.27Q548.08-720 481.54-720q-100 0-170 70t-70 170q0 100 70 170t170 70q77 0 139-44t87-116h63.23q-27.23 97.92-107.27 158.96Q583.46-180 481.54-180Z"/>
                                </svg>
                            </Popover.Button>
                            <Popover.Panel className="w-[200px] bg-white rounded shadow-[0_1px_7px_0_rgba(192,192,192,0.5)] py-2 absolute top-full right-0 translate-y-1">
                                {Object.entries(INTERVALS).map(([key, value]) => (
                                    <div
                                        key={key}
                                        onClick={() => {
                                            setInterval(Number(key))
                                            close()
                                        }}
                                        className={classNames("px-4 py-2 text-base hover:bg-gray-100 cursor-pointer", {
                                            "!bg-[#eeffee] !text-[#4caf50]": interval === Number(key)
                                        })}
                                    >
                                        {value}
                                    </div>
                                ))}
                            </Popover.Panel>
                        </>
                    )}
                </Popover>
            </header>

            {isLoading && <Loader/>}
            {data && (
                <div className="grid grid-cols-4 gap-6 p-6 px-20">
                    {data.dashboard.map(report => <ReportBox interval={interval} id={report.item_id} report={report}
                                                             key={report.item_id}/>)}
                </div>
            )}
        </div>
    )
}