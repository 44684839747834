import {useFormikContext} from "formik";
import {useState} from "react";
import {IoPaperPlaneOutline} from "react-icons/io5";
import {appointmentService} from "~/services/app";
import {useQueryClient} from "@tanstack/react-query";
import {useTranslation} from "react-i18next";

export default function AddNote() {

	const {t} = useTranslation()
	const queryClient = useQueryClient()
	const {values} = useFormikContext()
	const [note, setNote] = useState('')
	const [loading, setLoading] = useState(false)

	const saveNote = async () => {
		const result = await appointmentService.addNote({
			note,
			appointment_id: values.data.appointment_id
		})
		await queryClient.setQueryData(['notes', values.data.appointment_customer_id], data => {
			const d = {...data}
			d.data = [...d.data, result.data]
			return d
		})
		setNote('')
	}
	const handleKeyDown = async (event) => {
		if (event.key === 'Enter') {
			event.preventDefault()
			event.stopPropagation()
			await saveNote()
		}
	};

	return (
		<div className="mt-auto mx-4 sticky bottom-2">
			<input
				type="text"
				value={note}
				placeholder={t("Not Ekle")}
				onChange={e => setNote(e.target.value)}
				onKeyDown={handleKeyDown}
				className="h-12 pr-12 rounded-full outline-none focus:border-zinc-500 border border-zinc-300 px-4 text-sm w-full"
			/>
			<button
				type="button"
				onClick={saveNote}
				disabled={!note}
				className="absolute disabled:opacity-50 disabled:pointer-events-none top-1.5 right-1.5 h-9 w-9 bg-green-500 text-white flex items-center justify-center rounded-full"
			>
				<IoPaperPlaneOutline size={18}/>
			</button>
		</div>
	)

}
