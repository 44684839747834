import { get, post } from "~/services/request";
import {store} from "~/store";
import {setApplications} from "~/store/business";

export const systemService = {
  versionCheck: version => post('version', {version})
}

export const businessService = {
  detail: slug => get(`business/${slug}`, {
    success: false
  }),
  login: data => post('business/login', data),
  globalLogin: data => post('business/global-login', data),
  info: () => post('business/info'),
  update: data => post('business/update', data),
  settings: data => post('business/settings', data),
  usage: data => post('app/business/usage', data)
}

export const invoiceService = {
  list: () => post('app/invoice'),
  info: () => post('app/invoice/info'),
  setAddress: data => post('app/invoice/set-address', data),
  detail: id => post(`app/invoice/${id}`),
  pay: (id, data) => post(`app/invoice/${id}/pay`, data),
}

export const subscriptionService = {
  upgrade: data => post('subscription/upgrade', data, {
    success: true
  })
}

export const customerService = {
  list: data => post('app/customers', data),
  create: data => post('app/customers/create', data),
  update: data => post('app/customers/update', data),
  updateMedical: (data, success = true) => post('app/customers/update-medical', data, {
    success
  }),
  updateLabel: data => post('app/customers/update-label', data),
  detail: (id, data) => post(`app/customers/detail/${id}`, data),
  history: (id, data) => post(`app/customers/history/${id}`, data),
  appointments: (id, data) => post(`app/customers/appointments/${id}`, data),
  sales: (id, data) => post(`app/customers/sales/${id}`, data),
  products: (id, data) => post(`app/customers/products/${id}`, data),
  installments: (id, data) => post(`app/customers/installments/${id}`, data),
  payInstallments: (id, data) => post(`app/customers/installments/pay/${id}`, data),
  payBalance: (id, data) => post(`app/customers/balance/pay/${id}`, data),
  payManuel: (id, data) => post(`app/customers/manuel/pay/${id}`, data),
  payments: (id, data) => post(`app/customers/payments/${id}`, data),
  summary: (id, data) => post(`app/customers/summary/${id}`, data),
  updateBalance: (id, data) => post(`app/customers/update-balance/${id}`, data),
  updateSaleStatus: (saleId, data) => post(`app/customers/sale-status/${saleId}`, data),
  fixPaymentAmount: (data) => post(`app/customers/fix-payment-amount`, data),
}

export const storageService = {
  list: () => post('app/storage/list'),
  get: () => post('app/storage/get'),
  buy: data => post('app/storage/buy', data),
  files: (id, data) => post(`app/storage/files/${id}`, data),
  createFolder: (id, data) => post(`app/storage/create-folder/${id}`, data),
  renameFolder: (id, data) => post(`app/storage/rename-folder/${id}`, data),
  deleteFolder: (id, data) => post(`app/storage/delete-folder/${id}`, data),
  deleteFile: (id, data) => post(`app/storage/delete-file/${id}`, data),
  uploadFiles: (id, data) => post(`app/storage/upload-files/${id}`, data),
  info: id => post(`app/storage/info/${id}`),
}

export const labelService = {
  list: data => post('app/labels', data),
  create: data => post('app/labels/create', data),
  update: data => post('app/labels/update', data),
}

export const customerLabelService = {
  list: data => post('app/customer-labels', data),
  create: data => post('app/customer-labels/create', data),
  update: data => post('app/customer-labels/update', data),
}

export const potentialCustomerService = {
  list: data => post('app/potential-customers', data),
  create: data => post('app/potential-customers/create', data),
  update: data => post('app/potential-customers/update', data),
  updateKanban: data => post('app/potential-customers/update-kanban', data),
  events: id => post(`app/potential-customers/events/${id}`),
  logs: id => post(`app/potential-customers/logs/${id}`),
  search: data => post('app/potential-customers/search', data),
  updateStatus: data => post('app/potential-customers/update-status', data),
  // detail: (id, data) => post(`app/customers/detail/${id}`, data)
}

export const saleService = {
  packages: data => post('app/sale/packages', data),
  products: data => post('app/sale/products', data),
  services: data => post('app/sale/services', data),
  servicesWithoutGrouped: data => post('app/sale/services-without-grouped', data),
  customerSearch: data => post('app/sale/customer-search', data),
  potentialCustomerSearch: data => post('app/sale/potential-customer-search', data),
  finish: data => post('app/sale/finish', data),
  staff: data => post('app/sale/staff', data),
}

export const appointmentService = {
  list: data => post('app/appointments', data),
  dailyReport: data => post('app/appointments/report/daily', data),
  rooms: () => post('app/appointments/rooms'),
  detail: id => post(`app/appointments/detail/${id}`),
  notes: customerId => post(`app/appointments/notes/${customerId}`),
  noteDelete: noteId => post(`app/appointments/note-delete/${noteId}`),
  staff: () => post('app/appointments/staff'),
  services: data => post('app/appointments/services', data),
  create: data => post('app/appointments/create', data),
  addNote: data => post('app/appointments/add-note', data),
  pinNote: (id, data) => post(`app/appointments/pin-note/${id}`, data),
  update: data => post('app/appointments/update', data),
  cancel: data => post('app/appointments/cancel', data),
  postpone: data => post('app/appointments/postpone', data),
  changeDate: data => post('app/appointments/change-date', data),
  cancelAppointmentService: data => post('app/appointments/cancel-appointment-service', data),
  serviceReceipt: data => post('app/appointments/service-receipt', data),
  addAppointmentService: data => post('app/appointments/add-appointment-service', data),
}

export const educationService = {
  list: () => post('app/education')
}

export const eventService = {
  list: data => post('app/events', data),
  create: data => post('app/events/create', data),
  update: (id, data) => post(`app/events/update/${id}`, data),
  detail: id => post(`app/events/detail/${id}`),
  setStatus: (id, status) => post(`app/events/set-status/${id}`, {
    status
  }),
}

export const financeService = {
  list: data => post('app/finance', data),
  notes: data => post('app/finance/notes', data),
  addNote: data => post('app/finance/notes/add', data),
  deleteNote: data => post('app/finance/notes/delete', data),
  update: (id, data) => post(`app/finance/update/${id}`, data),
  addExpense: data => post('app/finance/add-expense', data),
  cancel: id => post(`app/finance/cancel/${id}`),
}

export const automationService = {
  list: data => post('app/automation', data),
  jobs: data => post('app/automation/jobs', data),
  create: data => post('app/automation/create', data),
  delete: id => post(`app/automation/delete/${id}`),
}

export const tabletService = {
  list: data => post('app/tablets', data),
  create: data => post('app/tablets/create', data),
  update: (id, data) => post(`app/tablets/update/${id}`, data),
  delete: id => post(`app/tablets/delete/${id}`),
  detail: id => post(`app/tablets/detail/${id}`),
  login: data => post('app/tablet/login', data),
  documents: data => post('app/tablet/documents', data),
  sign: data => post('app/tablet/documents/sign', data),
  appointments: data => post('app/tablet/appointments', data),
  appointment: id => post(`app/tablet/appointments/${id}`),
  appointmentUpdate: data => post(`app/tablet/appointment-update`, data),
  appointmentNotes: id => post(`app/tablet/appointment-notes/${id}`),
  newAppointmentNote: data => post(`app/tablet/appointment-notes/new`, data),
  cancelAppointmentService: data => post(`app/tablet/cancel-appointment-service`, data),
}

export const reportService = {
  info: data => post('reports/info', data),
  customersWithoutAppointments: data => post('reports/customers-without-appointments', data),
  appointments: data => post('reports/appointments', data),
  create: data => post('reports/create', data),
  new: data => post('reports/new', data),
  list: data => post('reports', data),
  detail: (id, data) => post(`reports/detail/${id}`, data),
  delete: (id) => post(`reports/delete/${id}`),
  public: (token, id) => post(`dashboard/public/report/${token}/${id}`),
}

export const dashboardService = {
  list: data => post('dashboard', data),
  create: data => post('dashboard/create', data),
  reports: dashboardId => post(`dashboard/reports/${dashboardId}`),
  addReport: data => post('dashboard/report/add', data),
  deleteReport: id => post(`dashboard/report/delete/${id}`),
  delete: id => post(`dashboard/delete/${id}`),
  updateReportOrders: data => post('dashboard/reports/update-order', data),
  links: id => post(`dashboard/links/${id}`),
  createLink: data => post('dashboard/links/create', data),
  deleteLink: id => post(`dashboard/links/delete/${id}`),
  public: token => post(`dashboard/public/${token}`),
}

export const applicationService = {
  list: data => post('applications', data),
  installed: async () => {
    const data = await post('applications/installed')
    store.dispatch(setApplications(data?.data?.apps?.reduce((acc, curr) => ({...acc, [curr.application_slug]: curr}), [])))
  },
  install: data => post('applications/install', data),
  zeta: data => post('applications/zeta', data),
  zetaStartCall: data => post('applications/zeta/start-call', data),
  uninstall: id => post(`applications/uninstall/${id}`),
}


export const jobService = {
  list: () => post('app/job'),
  create: data => post('app/job/create', data),
  update: data => post('app/job/update', data),
}

export const staffService = {
  list: () => post('app/staff'),
  create: data => post('app/staff/create', data),
  update: data => post('app/staff/update', data),
}

export const roomService = {
  list: data => post('app/rooms', data),
  create: data => post('app/rooms/create', data),
  update: data => post('app/rooms/update', data),
}

export const serviceGroupService = {
  list: () => post('app/service-group'),
  create: data => post('app/service-group/create', data),
  update: data => post('app/service-group/update', data),
}

export const serviceService = {
  list: data => post('app/service', data),
  create: data => post('app/service/create', data),
  update: data => post('app/service/update', data),
}

export const servicePackageService = {
  list: () => post('app/service-package'),
  create: data => post('app/service-package/create', data),
  update: data => post('app/service-package/update', data),
}

export const branchService = {
  list: () => post('app/branch'),
  create: data => post('app/branch/create', data),
  update: data => post('app/branch/update', data),
}

export const deviceServices = {
  list: () => post('app/devices'),
  logout: token => post(`app/device/logout/${token}`),
}

export const brandService = {
  list: data => post('app/brand', data),
  create: data => post('app/brand/create', data),
  update: data => post('app/brand/update', data),
}

export const productGroupService = {
  list: data => post('app/product-group', data),
  create: data => post('app/product-group/create', data),
  update: data => post('app/product-group/update', data),
}

export const productService = {
  list: data => post('app/product', data),
  create: data => post('app/product/create', data),
  update: data => post('app/product/update', data),
  updateStock: data => post('app/product/update-stock', data),
}

export const documentService = {
  list: () => post('app/documents'),
  create: data => post('app/documents/create', data),
  update: (id, data) => post(`app/documents/update/${id}`, data),
  detail: id => post(`app/documents/detail/${id}`),
  view: id => post(`app/documents/view/${id}`)
}

export const customerDocumentService = {
  create: data => post('app/customer-documents/create', data),
}

export const websiteService = {
  list: data => post('app/website', data),
  create: data => post('app/website/create', data),
  update: (id, data) => post(`app/website/update/${id}`, data, {
    success: false
  }),
  detail: id => post(`app/website/detail/${id}`),
}

export const chatBotService = {
  list: data => post('app/chatbot', data),
  create: data => post('app/chatbot/create', data),
  update: (id, data) => post(`app/chatbot/update/${id}`, data),
  detail: id => post(`app/chatbot/detail/${id}`),
}

export const uploadService = {
  websiteAsset: data => post('app/upload/website', data)
}

export const kanbanService = {
  list(data) {
    return post('app/kanban', data)
  },
  create(data) {
    return post('app/kanban/create', data)
  },
  updateOrder(data) {
    return post('app/kanban/update-order', data)
  },
  update(id, data) {
    return post(`app/kanban/update/${id}`, data)
  },
  delete(id) {
    return post(`app/kanban/delete/${id}`)
  }
}