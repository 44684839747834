import {IoMdPlay} from "react-icons/io";
import {useSettings} from "~/store/web.js";
import {modal} from "~/store/modal.js";

export default function OurCustomers() {

    const settings = useSettings()

    return (
        <section className="bg-[#fafafa] py-16">
            <div className="container mx-auto">

                <h3 className="text-xl lg:text-4xl font-semibold text-center text-[#4b4b4b] mb-4">
                    Bize Güvenen Müşterilerimiz
                </h3>
                <p className="text-[#727272] w-[85%] lg:w-[65%] mx-auto font-light text-base md:text-lg text-center">
                    Alanında lider Estetic Clinic, Queenzz, Capital gibi 500'in üzerinde şirket, müşteri desteği ve
                    satış operasyonları için WiseCRM’i kullanıyor. Siz de WiseCRM ile işinizi büyütün.
                </p>

                <div
                    className="flex justify-center whitespace-nowrap overflow-auto px-4 md:px-0 py-8 items-center gap-8">
                    <img src="/img/customers/1.webp" alt=""
                         className="h-16 grayscale transition-all hover:grayscale-0"/>
                    <img src="/img/customers/2.webp" alt=""
                         className="h-16 grayscale transition-all hover:grayscale-0"/>
                    <img src="/img/customers/3.webp" alt=""
                         className="h-16 grayscale transition-all hover:grayscale-0"/>
                    <img src="/img/customers/4.webp" alt=""
                         className="h-16 grayscale transition-all hover:grayscale-0"/>
                    <img src="/img/customers/5.webp" alt=""
                         className="h-16 grayscale transition-all hover:grayscale-0"/>
                    <img src="/img/customers/6.webp" alt=""
                         className="h-16 grayscale transition-all hover:grayscale-0"/>
                    <img src="/img/customers/7.webp" alt="" className="h-8 grayscale transition-all hover:grayscale-0"/>
                </div>

                {settings?.video_url && <VideoPlayer url={settings.video_url}/>}

            </div>
        </section>
    )
}

function VideoPlayer({ url }) {

    const u = new URL(url)

    return (
        <button
            type="button"
            onClick={() => {
                modal.append('web.player', {
                    url: `https://www.youtube.com/embed/${u.searchParams.get('v')}`
                })
            }}
            className="aspect-video block bg-zinc-800 relative w-full md:w-2/3 mx-auto mt-4"
        >
            <img src={`https://i.ytimg.com/vi/${u.searchParams.get('v')}/maxresdefault.jpg`} alt="" className="opacity-50"/>
            <button
                className="w-12 h-12 rounded-full bg-black/70 text-white flex items-center justify-center absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
                <IoMdPlay size={24} className="relative left-0.5"/>
            </button>
        </button>
    )
}