import useIsMobile from "~/hooks/use-is-mobile.js";

export default function Integrations() {

    const isMobile = useIsMobile()

    return (
        <section className="py-16">
            <div className="container mx-auto">

                <h3 className="text-xl md:text-4xl font-semibold text-center text-[#4b4b4b] mb-4">
                    Güçlü entegrasyonlar
                </h3>
                <p className="text-[#727272] w-[85%] md:w-[65%] mx-auto font-light text-base md:text-lg text-center">
                    İşletmenizin her alanını tek bir platformda yöneterek verimliliği artırın ve işletmenizi daha da
                    büyütün!
                </p>

                <div className="flex px-4 md:px-0 justify-center flex-wrap py-8 items-center gap-8">
                    <img src="/img/integrations/whatsapp.svg" alt=""
                         className="h-11"/>
                    <img src="/img/integrations/instagram.png" alt=""
                         className="h-10"/>
                    <img src="/img/integrations/messenger.png" alt=""
                         className="h-10"/>
                    <img src="/img/integrations/facebook.png" alt=""
                         className="h-10"/>
                    <img src="/img/integrations/meta.png" alt=""
                         className="h-10"/>
                    <img src="/img/integrations/drive.png" alt=""
                         className="h-10"/>
                    <img src="/img/integrations/google-store.png" alt=""
                         className="h-10"/>
                    <img src="/img/integrations/e.png" alt=""
                         className="h-10"/>
                    <img src="/img/integrations/user.png" alt=""
                         className="h-10"/>
                    <img src="/img/integrations/sms1.png" alt=""
                         className="h-10"/>
                    <img src="/img/integrations/sms2.png" alt=""
                         className="h-10"/>
                    <img src="/img/integrations/zapier.png" alt=""
                         className="h-10"/>
                    <img src="/img/integrations/chatgpt.png" alt=""
                         className="h-10"/>
                    <img src="/img/integrations/calendar.png" alt=""
                         className="h-10"/>
                    <img src="/img/integrations/maps.png" alt=""
                         className="h-10"/>
                </div>

            </div>
        </section>
    )
}