import { useEffect, useState } from 'react';

export default function useIsMobile() {

  const [isMobile, setIsMobile] = useState(window.matchMedia(`(max-width: 600px)`).matches)

  useEffect(() => {

    const matchMedia = window.matchMedia(`(max-width: 600px)`)

    const onChangeHandle = e => {
      setIsMobile(e.matches)
    }

    matchMedia.addEventListener('change', onChangeHandle)

    // cleanup
    return () => {
      matchMedia.removeEventListener('change', onChangeHandle)
    }

  }, [])

  return isMobile

}
