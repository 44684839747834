import {useParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {webService} from "~/services/web/index.js";
import Loader from "~/components/loader/index.jsx";
import {dateFormat} from "~/utils/date.js";
import {Helmet} from "react-helmet";

export default function Page() {

    const {slug} = useParams()
    const {data, isLoading} = useQuery(['page', slug], () => webService.cms.pageDetail(slug))

    return (
        <div className="container mx-auto md:mt-16 pb-16 border-b">

            <Helmet>
                <title>{data?.data?.page_title || ''} - {import.meta.env.VITE_TITLE}</title>
            </Helmet>

            {isLoading && <Loader/>}
            {data && (
                <div className="px-4 md:px-16">
                    <header className="mb-10">
                        <h2 className="text-2xl md:text-4xl font-semibold mb-2">
                            {data.data.page_title}
                        </h2>
                        <time className="italic text-[15px] md:text-lg font-medium">{dateFormat(data.data.page_update || data.data.page_date)}</time>
                    </header>
                    <div dangerouslySetInnerHTML={{__html: data.data.page_content}}
                         className="inline-editor editor text-sm md:text-base"/>
                </div>
            )}
        </div>
    )
}