import {motion} from "framer-motion";
import PropTypes from "prop-types";

export default function OpacityContent({ delay, exitDelay, children, ...props }) {
	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1, transition: { delay }}}
			exit={{ opacity: 0, transition: {delay: exitDelay} }}
			{...props}
		>
			{children}
		</motion.div>
	)
}

OpacityContent.propTypes = {
	delay: PropTypes.number,
	exitDelay: PropTypes.number,
	children: PropTypes.any,
	props: PropTypes.object
}

OpacityContent.defaultProps = {
	delay: 0,
	exitDelay: 0
}
