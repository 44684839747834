import {modal} from "~/store/modal.js";
import {useQuery} from "@tanstack/react-query";
import {webService} from "~/services/web/index.js";
import Feature from "~/components/web/feature/index.jsx";

export default function HighlightedFeatures() {

    const {data, isLoading} = useQuery(['web/cms/features/highlighted'], () => webService.cms.features({
        highlighted: 1
    }))

    if (isLoading) {
        return null
    }

    return (
        <section className="container mx-auto mt-16 py-16 border-t border-t-[#ebebeb]">

            <h3 className="text-2xl px-4 md:px-0 lg:text-4xl font-semibold text-center text-[#4b4b4b] mb-4">
                WiseCRM’in en beğenilen özellikleri
            </h3>
            <p className="text-[#727272] w-[85%] lg:w-[55%] mx-auto font-light text-base md:text-lg text-center">
                Randevu yönetimi, müşteri yönetimi, hizmet ve ürün satışı, potansiyel müşteriler, etkinlikler, otomasyon, belgeler, websitesi yönetimi, medikal bilgiler ve chatbot gibi pek çok modülü içeren kapsamlı bir çözümdür.
            </p>

            <div className="divide-y divide-[#ebebeb]">
                {data.data.map((feature, index) => <Feature feature={feature} key={index}/>)}
            </div>

            <nav className="flex justify-center px-4 md:px-0 gap-4 items-center">
                <button
                    onClick={() => {
                        modal.append('web.auth.register')
                    }}
                    className="h-11 flex items-center md:min-w-[220px] justify-center px-4 bg-[#22c55f] text-white font-medium"
                >
                    Ücretsiz Dene
                    <span className="hidden md:inline">yin</span>
                </button>
                <span className="text-zinc-600">veya</span>
                <button
                    className="h-11 flex items-center md:min-w-[220px] justify-center px-4 bg-black text-white font-medium"
                >
                    Tüm özellikler
                    <span className="hidden md:inline">i gör</span>
                </button>
            </nav>
        </section>
    )
}