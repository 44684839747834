import HeroSection from "~/pages/web/home/hero-section/index.jsx";
import ImageFeature from "~/pages/web/home/image-feature/index.jsx";
import HighlightedFeatures from "~/pages/web/home/highlighted-features/index.jsx";
import OurCustomers from "~/pages/web/home/our-customers/index.jsx";
import Integrations from "~/pages/web/home/integrations/index.jsx";
import SmartBusiness from "~/pages/web/home/smart-business/index.jsx";
import {modal} from "~/store/modal.js";

export default function Home() {
	return (
		<>
			<HeroSection/>
			<ImageFeature/>
			<HighlightedFeatures/>
			<SmartBusiness/>
			<Integrations/>
			<OurCustomers/>
			<section className="py-7 md:py-16 bg-gradient-to-b from-[#f7fffa] to-[#edfff4]">
				<div className="container mx-auto text-center">

					<h3 className="text-2xl md:text-4xl font-semibold text-[#0f4a25] mb-4">WiseCRM’i ücretsiz deneyin.</h3>

					<p className="px-4 md:px-0 text-[#727272] text-sm md:font-light">
						Kredi kartına gerek yok, yazılım yüklemeye gerek yok.
					</p>

					<nav className="flex justify-center items-center mt-6">
						<button
							type="button"
							onClick={() => modal.append('web.auth.register')}
							className="h-10 md:h-11 flex items-center min-w-[180px] md:min-w-[220px] justify-center px-4 bg-[#22c55f] text-white text-sm md:text-base font-medium"
						>
							Ücretsiz Deneyin
						</button>
					</nav>

				</div>
			</section>
		</>
	)
}
