import ScrollToBottom from "react-scroll-to-bottom";
import Notes from "~/modals/app/appointment/detail/sidebar/notes";
import Balance from "~/modals/app/appointment/detail/sidebar/balance";
import {useFormikContext} from "formik";
import AddNote from "~/modals/app/appointment/detail/sidebar/notes/add-note";
import {BsClockHistory} from "react-icons/bs";
import {dateFormat} from "~/utils/date";
import {BsClock} from "react-icons/bs";
import {HiArrowLongRight} from "react-icons/hi2";
import classNames from "classnames";
import {permission} from "~/utils";
import {dayjs} from "~/utils/date.js";
import {useTranslation} from "react-i18next";

export default function Sidebar({refetch}) {

	const {t, i18n} = useTranslation()

	const {values: {data}} = useFormikContext()
	return (
		<aside className="w-full lg:w-[440px] bg-[#fbfcfd] flex flex-col flex-shrink-0 border-l pb-4">
			<ScrollToBottom className={classNames("notes", {
				"h-[calc(100%-48px)]": permission('appointments', 'note'),
				"h-full": !permission('appointments', 'note'),
			})}>
				<div className="mt-auto"/>
				<div className="py-4">
					<Notes/>

					{data.history.length > 0 && (
						<div className="grid gap-2.5">
							{data.history.map((history, key) => (
								<div
									className={classNames("border mx-4 ml-[56px] bg-white shadow-balance rounded-lg border-[#e6a925] text-[13px] text-[#926f24] p-2 flex flex-col gap-3", {
										"!border-green-500 !text-green-600": history.type === "change-date"
									})}>
									<div className="flex items-center gap-2.5">
										{history.type === 'postpone' && <BsClockHistory size={20}/>}
										{history.type === 'change-date' && <BsClock size={20}/>}
										<p>
											{history.type === 'postpone' && (
												<p
													dangerouslySetInnerHTML={{
														__html: t('{{- staff}} tarafından {{- date}} tarihinde ertelendi', {
															staff: '<span class="font-semibold">' + history.staff.name + '</span>',
															date: '<span class="font-medium underline">' + dateFormat(history.date, 'DD MMMM YYYY, HH:mm') + '</span>'
														})
													}}
												/>
											)}
											{history.type !== 'postpone' && (
												<p
													dangerouslySetInnerHTML={{
														__html: t('{{- staff}} tarafından {{- date}} tarihinde güncellendi', {
															staff: '<span class="font-semibold">' + history.staff.name + '</span>',
															date: '<span class="font-medium underline">' + dateFormat(history.date, 'DD MMMM YYYY, HH:mm') + '</span>'
														})
													}}
												/>
											)}
										</p>
									</div>
									<div className="flex justify-between items-center px-8">
										<div>
											{dayjs(history.oldDates[0]).locale(i18n.language).format('DD MMMM YYYY')} <br/>
											{dayjs(history.oldDates[0]).format('HH:mm')} - {dayjs(history.oldDates[1]).format('HH:mm')}
										</div>
										<HiArrowLongRight size={20}/>
										<div className="text-right">
											{dayjs(history.newDates[0]).locale(i18n.language).format('DD MMMM YYYY')} <br/>
											{dayjs(history.newDates[0]).format('HH:mm')} - {dayjs(history.newDates[1]).format('HH:mm')}
										</div>
									</div>
								</div>
							))}
						</div>
					)}

					{data.customer.customer_balance > 0 && (
						<Balance refetch={refetch}/>
					)}
				</div>
			</ScrollToBottom>
			{permission('appointments', 'note') && (
				<AddNote/>
			)}
		</aside>
	)
}
