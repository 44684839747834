import { createSlice } from '@reduxjs/toolkit'
import {useSelector} from "react-redux";
import {store} from "~/store/index";

const initialState = {
	modals: [],
	closeDisable: false
}

export const modalSlice = createSlice({
	name: 'modal',
	initialState,
	reducers: {
		append: (state, action) => {
			state.modals = [...state.modals, action.payload]
		},
		removeLast: state => {
			const modals = [...state.modals]
			modals.pop()
			state.modals = modals
		},
		removeAll: state => {
			state.modals = []
		},
		setCloseDisable: (state, action) => {
			state.closeDisable = action.payload
		}
	},
})

export const { append, removeLast, removeAll, setCloseDisable } = modalSlice.actions

export const useModal = () => useSelector(state => state.modal.modals)
export const useModalDisable = () => useSelector(state => state.modal.closeDisable)
export const modal = {
	append: (name, data = {}) => store.dispatch(append({
		name,
		data
	})),
	removeLast: () => store.dispatch(removeLast()),
	removeAll: () => store.dispatch(removeAll()),
	setCloseDisable: data => store.dispatch(setCloseDisable(data)),
}

export default modalSlice.reducer
