import {useQuery} from "@tanstack/react-query";
import {tabletService} from "~/services/app";
import {Link, useParams} from "react-router-dom";
import Button from "~/components/form/button";
import Header from "~/pages/tablet/header";
import {useTablet} from "~/store/tablet";
import {dateFormat} from "~/utils/date";
import Status from "~/components/status";
import classNames from "classnames";
import Light from "~/pages/tablet/hardware/devices/light";
import Vacuum from "~/pages/tablet/hardware/devices/vacuum";
import Fan from "~/pages/tablet/hardware/devices/fan";

export default function Home() {

	const {slug} = useParams()
	const tablet = useTablet(slug)

	const {data, isFetching, refetch} = useQuery(['tablet/appointments', slug], () => tabletService.appointments(), {
		refetchInterval: 15000
	})

	return (
		<>

			<Header device={!!tablet?.sonoff_url} name={tablet.tablet.name}>
				<Button variant="white" onClick={refetch} loading={isFetching}>
					Yenile
				</Button>
			</Header>

			<div className="p-4 grid gap-2 pb-[192px]">
				{data?.data?.length === 0 && (
					<div className="p-6 text-center text-lg font-light text-zinc-500">
						Bugün için bir randevu bulunmuyor.
					</div>
				)}
				{data && data.data.map(appointment => (
					<Link
						to={`/tablet/${slug}/appointment/${appointment.appointment_id}`}
						className={classNames("p-4 pl-0 border gap-3 border-zinc-300 dark:border-zinc-700 rounded-md w-full flex dark:text-zinc-200", {
							"!border-[#4caf50] dark:!border-green-800": appointment.appointment_status === 4,
							"!border-[#f80404]": appointment.appointment_status === 7,
						})}
					>
						<div
							className="text-xl font-bold w-[100px] flex items-center flex-col justify-center text-center border-r border-zinc-200 dark:border-zinc-700 mr-2">
							{dateFormat(appointment.appointment_date_start, 'HH:mm')}
						</div>
						<div className="flex-1">
							<div className="font-medium">
								{appointment.customer.customer_name}
							</div>
							<div className="text-zinc-500 text-[15px]">
								{appointment.services.reduce((acc, curr) => [...acc, curr.service_name], []).join(', ')}
							</div>
							<div className="mt-1.5">
								<Status id={appointment.appointment_status}/>
							</div>
						</div>
					</Link>
				))}
			</div>

			{tablet?.sonoff_url && (
				<div
					className="p-5 rounded-xl backdrop-blur-sm pb-3 left-1/2 bg-zinc-300/10 dark:bg-zinc-900/50 -translate-x-1/2 fixed bottom-10">
					<div className="grid grid-cols-3 gap-4 h-[120px] w-[300px]">
						{tablet?.tablet?.sonoff?.light && (
							<Light
								size="small"
								deviceId={tablet?.tablet?.sonoff?.light.split('_')[0]}
								channel={tablet?.tablet?.sonoff?.light.split('_')[1]}
							/>
						)}

						{tablet?.tablet?.sonoff?.vacuum && (
							<Vacuum
								size="small"
								deviceId={tablet?.tablet?.sonoff?.vacuum.split('_')[0]}
								channel={tablet?.tablet?.sonoff?.vacuum.split('_')[1]}
							/>
						)}

						{tablet?.tablet?.sonoff?.fan && (
							<Fan
								size="small"
								deviceId={tablet?.tablet?.sonoff?.fan.split('_')[0]}
								channel={tablet?.tablet?.sonoff?.fan.split('_')[1]}
							/>
						)}
					</div>
				</div>
			)}
		</>
	)
}
