import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { GoLightBulb } from 'react-icons/go';
import PropTypes from 'prop-types';
import {TailSpin} from "react-loader-spinner";
import {useParams} from "react-router-dom";
import {useTablet} from "~/store/tablet";
import {RiWindyLine} from "react-icons/ri";

export default function Vacuum({ deviceId, size, channel }) {

	const {slug} = useParams()
	const tablet = useTablet(slug)

	const [light, setLight] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		const interval = setInterval(() => {
			fetch(`${tablet.sonoff_url}device-status/${deviceId}`)
				.then((res) => res.json())
				.then((res) => {
					if (res[channel || 0].switch === 'on') {
						setLight(true);
					} else if (res[channel || 0].switch === 'off') {
						setLight(false);
					} else {
						setLight(null);
					}
				});
		}, 1000);
		fetch(`${tablet.sonoff_url}device-status/${deviceId}`)
			.then((res) => res.json())
			.then((res) => {
				if (res[channel || 0].switch === 'off') {
					setLight(false);
				} else if (res[channel || 0].switch === 'on') {
					setLight(true);
				} else {
					setLight(null);
				}
				setLoading(false);
			});
		return () => {
			clearInterval(interval);
		};
	}, []);

	if (!deviceId) return '';
	return (
		<>
			<button
				onClick={() => {
					setLoading(true);
					fetch(
						channel
							? `${tablet.sonoff_url}toggle-status/${deviceId}/${channel}`
							: `${tablet.sonoff_url}toggle-status/${deviceId}`
					)
						.then((res) => res.json())
						.then((res) => {
							if (res?.error) {
								setLight(null);
							} else {
								setLight((l) => !l);
							}
							setLoading(false);
						});
				}}
				className={classNames(
					'relative flex items-center justify-center text-[80px] border font-light gap-x-14 aspect-square rounded-xl transition-all',
					{
						'p-4': size === 'small',
						'border-zinc-400 text-zinc-500': light === false,
						'bg-red-600 text-text': light === null,
						'bg-green-600 border-green-600 text-white shadow-lg shadow-green-700/40': light
					}
				)}>
				{loading && (
					<div className="absolute top-0 left-0 w-full h-[calc(100%+10px)] flex bg-body/70 items-center justify-center">
						<TailSpin
							height={size === 'normal' ? 60 : 50}
							width={size === 'normal' ? 60 : 50}
							color="#111"
							radius="1"
							visible={true}
						/>
					</div>
				)}
				<RiWindyLine size={size === 'normal' ? 80 : 30} />
				<h6
					className={classNames('text-center font-bold tracking-widest transition-all absolute left-0 w-full', {
						'text-4xl mt-6 -bottom-[55px]': size === 'normal',
						'text-base mt-3 -bottom-[35px]': size === 'small',
						'text-zinc-500': !light,
						'text-black dark:text-white': light
					})}>
					VAKUM
				</h6>
			</button>
		</>
	);
}

Vacuum.propTypes = {
	deviceId: PropTypes.string.isRequired,
	channel: PropTypes.string,
	size: PropTypes.oneOf(['small', 'normal'])
};

Vacuum.defaultProps = {
	channel: false,
	size: 'normal'
};
