import {ErrorMessage, Field, useField} from "formik";
import {useId, useState} from "react";
import {AiOutlineEye, AiOutlineEyeInvisible} from "react-icons/ai"
import classNames from "classnames";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import PropTypes from "prop-types";
import InputMask from 'react-input-mask';
import {useTranslation} from "react-i18next";

export default function Input({label, mask, required, size, type, className, suffix, ...props}) {

    const {t} = useTranslation()
    const [inputType, setInputType] = useState(type)
    const id = useId()
    const [field, , actions] = useField({
        ...props
    })

    return (
        <label className="block w-full">
            {label && <h3 className={classNames({
                "mb-2 text-sm tracking-tighter font-medium text-[#8b8b8b]": size === 'normal',
                "mb-2 text-lg font-light text-[#3e3e3e]": size === 'big',
                "mb-3 text-base font-light text-[#3e3e3e]": size === 'large'
            })}>
                {t(label)}
                {required && <span className="text-red-500 ml-1">*</span>}
            </h3>}
            <div className="w-full relative">
                {type !== 'phone' && (
                    <>
                        {mask ? (
                            <InputMask
                                {...props}
                                {...field}
                                mask={mask}
                                maskChar=" "
                                className={classNames("rounded-md border font-medium w-full border-zinc-200 focus:border-zinc-400 outline-none", {
                                    "h-[50px] text-base px-4": size === 'big',
                                    "h-10 px-4 text-[15px]": size === 'normal',
                                    "h-[60px] !rounded-none !border-black px-4 text-[22px] font-medium": size === 'large',
                                    [className]: true
                                })}
                            />
                        ) : (
                            <Field id={id} {...props} type={inputType}
                                   className={classNames("rounded-md border font-medium w-full border-zinc-200 focus:border-zinc-400 outline-none", {
                                       "h-[50px] text-base px-4": size === 'big',
                                       "h-10 px-4 text-[15px]": size === 'normal',
                                       "h-[60px] !rounded-none !border-black px-4 text-[22px] font-medium": size === 'large',
                                       [className]: true
                                   })}/>
                        )}
                    </>
                )}
                {suffix && (
                    <div
                        className="absolute top-0 right-0 h-full flex items-center pr-4 text-[#9d9d9d] text-base md:text-lg font-light">
                        {suffix}
                    </div>
                )}
                {type === 'phone' && (
                    <div>
                        <PhoneInput
                            defaultCountry="TR"
                            value={field.value}
                            onChange={phone => {
                                actions.setValue(phone)
                            }}
                        />
                    </div>
                )}
                {type === 'password' && (
                    <button
                        onClick={() => {
                            setInputType(type => type === 'password' ? 'text' : 'password')
                        }}
                        type="button"
                        className={classNames("w-10 h-10 rounded-md flex items-center justify-center text-zinc-500 absolute cursor-pointer hover:text-black top-0 right-0", {
                            "!h-full !w-14": size === 'big'
                        })}>
                        {inputType === 'password' ? <AiOutlineEye size={20}/> : <AiOutlineEyeInvisible size={20}/>}
                    </button>
                )}
            </div>
            <ErrorMessage component="small" name={props.name} className="text-xs text-red-500 text-right block mt-2"/>
        </label>
    )
}

Input.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    size: PropTypes.oneOf(['normal', 'big', 'large']),
    type: PropTypes.string,
    suffix: PropTypes.string,
    mask: PropTypes.string,
    className: PropTypes.string
}

Input.defaultProps = {
    required: false,
    mask: false,
    size: 'normal',
    type: 'text',
    suffix: '',
    className: ''
}