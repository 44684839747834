import {useRouteError} from "react-router-dom"

export default function Page404() {

	const error = useRouteError();

	return (
		<div className="flex items-center justify-center flex-col h-[100vh] bg-zinc-900 text-white">
			<h1 className="text-[12rem] font-bold text-orange-300">{error?.status || 'Unknown'}</h1>
			<p className="text-2xl">
				{error.data}
			</p>
		</div>
	)
}
