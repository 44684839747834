import Yup from "./validation"

export const roomValidation = Yup.object().shape({
  room_name: Yup.string()
    .required(),
  room_daily_limit: Yup.string()
    .required(),
  // staff_username: Yup.string()
  //   .when('staff_login', {
  //     is: true,
  //     then: Yup.string().required()
  //   }),
  // staff_password: Yup.string()
  //   .when('staff_login', {
  //     is: true,
  //     then: Yup.string().required()
  //   })
});
