import {useSettings} from "~/store/web.js";
import {NavLink, useParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {webService} from "~/services/web/index.js";
import {Helmet} from "react-helmet";
import classNames from "classnames";
import Loader from "~/components/loader/index.jsx";
import Feature from "~/components/web/feature/index.jsx";

export default function SmartControlSystem() {

    const settings = useSettings()

    const {data: categories} = useQuery(['web/cms/categories'], () => webService.cms.categories())

    const category = categories && categories.data.find(c => c.category_id === Number(settings?.smart_control_system_category_id))

    const {data, isLoading} = useQuery(['web/cms/features/smart'], () => webService.cms.features({
        category_id: settings?.smart_control_system_category_id
    }), {
        enabled: !!settings?.smart_control_system_category_id
    })

    return (
        <>
            <Helmet>
                <title>{category ? category.category_title : '...'} - {import.meta.env.VITE_TITLE}</title>
            </Helmet>

            <section className="container mx-auto mt-8 md:mt-16 pb-8 md:pb-16 border-b border-[#ebebeb]">

                <h2 className="text-2xl md:text-[55px] md:leading-[65px] text-center font-semibold">
                    {category ? category.category_title : '...'}
                </h2>

                <div className="mt-4 md:mt-8">
                    {isLoading && <Loader/>}

                    {data && (
                        <div className="divide-y divide-[#ebebeb]">
                            {data.data.map((feature, index) => <Feature slug={true} feature={feature} key={index}/>)}
                        </div>
                    )}
                </div>

            </section>
        </>
    )
}