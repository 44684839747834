import {Helmet} from "react-helmet";
import {useState} from "react";
import classNames from "classnames";
import {useQuery} from "@tanstack/react-query";
import {webService} from "~/services/web/index.js";
import Loader from "~/components/loader/index.jsx";
import {moneyFormat} from "~/utils/format.js";
import {Disclosure} from "@headlessui/react";
import {AiOutlineCheckCircle, AiOutlineMinusCircle} from "react-icons/ai";
import {IoIosArrowDown} from "react-icons/io";
import {PACKAGE_FEATURES} from "~/utils/const.jsx";

export default function Prices() {

    const {data, isLoading} = useQuery(['web/packages'], () => webService.packages())
    const [type, setType] = useState('annually')

    return (
        <>

            <Helmet>
                <title>Fiyatlar - {import.meta.env.VITE_TITLE}</title>
            </Helmet>

            <section className="container mx-auto mt-7 md:mt-16 pb-7 md:pb-16 border-b border-[#ebebeb]">

                <h2 className="text-3xl md:text-[55px] md:leading-[65px] px-4 md:px-0 text-center font-semibold mb-10">
                    İşletmen için en iyi planı seç
                </h2>

                <div className="flex flex-col items-center justify-center mb-10">
                    <div className="flex justify-center items-center gap-5">
                        <div className="text-lg">Ay</div>
                        <button
                            type="button"
                            onClick={() => {
                                setType(type === 'monthly' ? 'annually' : 'monthly')
                            }}
                            className={classNames("h-11 rounded-full transition-all w-[90px] relative shadow-lg shadow-zinc-50", {
                                "bg-[#8ae68e]": type === 'annually',
                                "bg-zinc-100": type === 'monthly',
                            })}
                        >
                            <div
                                className={classNames("w-9 h-9 bg-white rounded-full shadow-[0_1px_4px_0_rgba(0,0,0,0.22] absolute top-1/2 -translate-y-1/2 transition-all", {
                                    "left-1.5": type === 'monthly',
                                    "left-[50px]": type === 'annually',
                                })}/>
                        </button>
                        <div className="text-lg">Yıl</div>
                    </div>
                    {type === 'annually' && (
                        <small className="font-mono mt-3 text-zinc-500">Yıllık seçimde 2 ay HEDİYE!</small>
                    )}
                </div>

                {isLoading && <Loader/>}

                {data && (
                    <>
                        <div className="flex whitespace-nowrap overflow-auto px-4 snap-x snap-mandatory md:px-0 md:grid grid-cols-4 gap-4">
                            {data.data.map(pkg => (
                                <section className={classNames("border w-[calc(100vw-60px)] md:w-auto snap-center flex-shrink-0 border-[#e3e3e3] rounded p-4", {
                                    "!border-[#4caf50] ring-4 ring-[#4caf50]/20": pkg.package_popular === 1
                                })}>
                                    <h3 className="text-lg font-semibold mb-1.5">
                                        {pkg.package_name}
                                        {pkg.package_popular === 1 && (
                                            <span className="bg-[#4caf50] text-white px-2 text-sm py-1 rounded-md ml-2">Popüler</span>
                                        )}
                                    </h3>
                                    <p className="text-xs text-zinc-600">
                                        {pkg.package_description}
                                    </p>
                                    <div className="text-4xl font-semibold py-4">
                                        {pkg.package_price === 0 && 'Ücretsiz'}
                                        {pkg.package_price > 0 && (
                                            <>
                                                {type === 'annually' && (
                                                    <>
                                                        {moneyFormat(pkg.package_price, 'TRY', 'tr-TR', 0)}
                                                        <sup
                                                            className="ml-3 text-sm line-through text-zinc-500">{moneyFormat((pkg.package_price / 10) * 12, 'TRY', 'tr-TR', 0)}</sup>
                                                    </>
                                                )}
                                                {type === 'monthly' && moneyFormat(pkg.package_price / 10, 'TRY', 'tr-TR', 0)}
                                            </>
                                        )}
                                    </div>

                                    {pkg.package_price === 0 && (
                                        <div
                                            className="h-8 flex items-center justify-center text-sm font-medium bg-[#f0f0f0] rounded">
                                            Ücretsiz kullan
                                        </div>
                                    )}

                                    {pkg.package_price > 0 && (
                                        <div
                                            className="h-8 flex items-center justify-center text-sm font-medium bg-[#eefff4] text-[#4caf50] rounded">
                                            Ücretsiz deneyin
                                        </div>
                                    )}

                                    <div className="mt-6 text-[15px] grid gap-1">
                                        <div className={pkg.package_limits.user === -1 && 'line-through text-zinc-300'}>
                                            {pkg.package_limits.user > 0 ? pkg.package_limits.user : (pkg.package_limits.user === 0 ? 'Sınırsız' : '')}{' '}
                                            Kullanıcı
                                        </div>
                                        <div
                                            className={pkg.package_limits.staff === -1 && 'line-through text-zinc-300'}>
                                            {pkg.package_limits.staff > 0 ? pkg.package_limits.staff : (pkg.package_limits.staff === 0 ? 'Sınırsız' : '')}{' '}
                                            Personel
                                        </div>
                                        <div
                                            className={pkg.package_limits.appointment === -1 && 'line-through text-zinc-300'}>
                                            {pkg.package_limits.appointment > 0 ? pkg.package_limits.appointment : (pkg.package_limits.appointment === 0 ? 'Sınırsız' : '')}{' '}
                                            Randevu
                                        </div>
                                        <div
                                            className={pkg.package_limits.customer === -1 && 'line-through text-zinc-300'}>
                                            {pkg.package_limits.customer > 0 ? pkg.package_limits.customer : (pkg.package_limits.customer === 0 ? 'Sınırsız' : '')}{' '}
                                            Müşteri
                                        </div>
                                        <div
                                            className={pkg.package_limits['potential-customer'] === -1 && 'line-through text-zinc-300'}>
                                            {pkg.package_limits['potential-customer'] > 0 ? pkg.package_limits['potential-customer'] : (pkg.package_limits['potential-customer'] === 0 ? 'Sınırsız' : '')}{' '}
                                            Potansiyel müşteri
                                        </div>
                                        <div
                                            className={pkg.package_limits.label === -1 && 'line-through text-zinc-300'}>
                                            {pkg.package_limits.label > 0 ? pkg.package_limits.label : (pkg.package_limits.label === 0 ? 'Sınırsız' : '')}{' '}
                                            Etiket
                                        </div>
                                        <div
                                            className={pkg.package_limits.automation === -1 && 'line-through text-zinc-300'}>
                                            {pkg.package_limits.automation > 0 ? pkg.package_limits.automation : (pkg.package_limits.automation === 0 ? 'Sınırsız' : '')}{' '}
                                            Otomasyon
                                        </div>
                                        <div
                                            className={pkg.package_limits.document === -1 && 'line-through text-zinc-300'}>
                                            {pkg.package_limits.document > 0 ? pkg.package_limits.document : (pkg.package_limits.document === 0 ? 'Sınırsız' : '')}{' '}
                                            Belge
                                        </div>
                                    </div>

                                </section>
                            ))}
                            <section className="hidden border border-[#e3e3e3] rounded p-4">
                                <h3 className="text-lg font-semibold mb-1.5">Unlimited</h3>
                                <p className="text-xs text-zinc-600">
                                    Küçük ölçekli işletmeleri destekleme amaçlı
                                </p>
                                <div className="text-4xl font-semibold py-4">
                                    Bize ulaşın
                                </div>

                                <div
                                    className="h-8 flex items-center justify-center text-sm font-medium bg-[#eefff4] text-[#4caf50] rounded">
                                    Bize ulaşın
                                </div>

                                <div className="mt-6 text-[15px] grid gap-1">
                                    <div>
                                        Sınırsız Kullanıcı
                                    </div>
                                    <div>
                                        Sınırsız Personel
                                    </div>
                                    <div>
                                        Sınırsız Randevu
                                    </div>
                                    <div>
                                        Sınırsız Müşteri
                                    </div>
                                    <div>
                                        Sınırsız Potansiyel müşteri
                                    </div>
                                    <div>
                                        Sınırsız Etiket
                                    </div>
                                    <div>
                                        Sınırsız Otomasyon
                                    </div>
                                    <div>
                                        Sınırsız Belge
                                    </div>
                                </div>

                            </section>
                        </div>

                        <div className="hidden md:block mt-16">
                            <div className="flex sticky top-0">
                                <div className="flex-1"/>
                                <nav className="grid grid-cols-4">
                                    {data.data.map((pkg, index) => (
                                        <div
                                            className={classNames("h-14 text-white w-[200px] flex-shrink-0 flex items-center justify-center", {
                                                "bg-[#a7a7a7]": index === 0,
                                                "bg-[#7a7a7a]": index === 1,
                                                "bg-[#5e5e5e]": index === 2,
                                                "bg-[#424242]": index === 3,
                                            })}>
                                            {pkg.package_name}
                                        </div>
                                    ))}
                                    {/*<div*/}
                                    {/*    className="h-14 flex items-center justify-center text-white w-[200px] bg-zinc-900">*/}
                                    {/*    Unlimited*/}
                                    {/*</div>*/}
                                </nav>
                            </div>

                            <div className="grid gap-14">

                                <Disclosure as="section" className="w-full" defaultOpen={true}>
                                    {({open}) => (
                                        <>
                                            <Disclosure.Button
                                                className="h-12 flex items-center px-4 bg-[#f9f9f9] w-full text-[15px] font-semibold gap-2.5">
                                                LİMİTLER
                                                <IoIosArrowDown size={18} className={open && 'rotate-180'}/>
                                            </Disclosure.Button>
                                            <Disclosure.Panel className="grid">
                                                <section className="flex items-center text-[15px] hover:bg-zinc-50">
                                                    <div className="flex-1 px-4">
                                                        Kullanıcı Limiti
                                                    </div>
                                                    <nav className="grid grid-cols-4">
                                                        {data.data.map((pkg, index) => (
                                                            <div
                                                                className="h-10 flex items-center justify-center w-[200px]">
                                                                {pkg.package_limits.user}
                                                            </div>
                                                        ))}
                                                        {/*<div*/}
                                                        {/*    className="h-10 flex items-center justify-center w-[200px]">*/}
                                                        {/*    Sınırsız*/}
                                                        {/*</div>*/}
                                                    </nav>
                                                </section>
                                                <section className="flex items-center text-[15px] hover:bg-zinc-50">
                                                    <div className="flex-1 px-4">
                                                        Personel Limiti
                                                    </div>
                                                    <nav className="grid grid-cols-4">
                                                        {data.data.map((pkg, index) => (
                                                            <div
                                                                className="h-10 flex items-center justify-center w-[200px]">
                                                                {pkg.package_limits.staff}
                                                            </div>
                                                        ))}
                                                        {/*<div*/}
                                                        {/*    className="h-10 flex items-center justify-center w-[200px]">*/}
                                                        {/*    Sınırsız*/}
                                                        {/*</div>*/}
                                                    </nav>
                                                </section>
                                                <section className="flex items-center text-[15px] hover:bg-zinc-50">
                                                    <div className="flex-1 px-4">
                                                        Randevu Limiti
                                                    </div>
                                                    <nav className="grid grid-cols-4">
                                                        {data.data.map((pkg, index) => (
                                                            <div
                                                                className="h-10 flex items-center justify-center w-[200px]">
                                                                {pkg.package_limits.appointment === 0 && 'Sınırsız'}
                                                                {pkg.package_limits.appointment > 0 && pkg.package_limits.appointment}
                                                            </div>
                                                        ))}
                                                        {/*<div*/}
                                                        {/*    className="h-10 flex items-center justify-center w-[200px]">*/}
                                                        {/*    Sınırsız*/}
                                                        {/*</div>*/}
                                                    </nav>
                                                </section>
                                                <section className="flex items-center text-[15px] hover:bg-zinc-50">
                                                    <div className="flex-1 px-4">
                                                        Müşteri Limiti
                                                    </div>
                                                    <nav className="grid grid-cols-4">
                                                        {data.data.map((pkg, index) => (
                                                            <div
                                                                className="h-10 flex items-center justify-center w-[200px]">
                                                                {pkg.package_limits.customer === 0 && 'Sınırsız'}
                                                                {pkg.package_limits.customer > 0 && pkg.package_limits.customer}
                                                            </div>
                                                        ))}
                                                        {/*<div*/}
                                                        {/*    className="h-10 flex items-center justify-center w-[200px]">*/}
                                                        {/*    Sınırsız*/}
                                                        {/*</div>*/}
                                                    </nav>
                                                </section>
                                                <section className="flex items-center text-[15px] hover:bg-zinc-50">
                                                    <div className="flex-1 px-4">
                                                        Potansiyel Müşteri Limiti
                                                    </div>
                                                    <nav className="grid grid-cols-4">
                                                        {data.data.map((pkg, index) => (
                                                            <div
                                                                className="h-10 flex items-center justify-center w-[200px]">
                                                                {pkg.package_limits['potential-customer'] === 0 && 'Sınırsız'}
                                                                {pkg.package_limits['potential-customer'] > 0 && pkg.package_limits['potential-customer']}
                                                                {pkg.package_limits['potential-customer'] === -1 && (
                                                                    <AiOutlineMinusCircle size={20}
                                                                                          className="text-red-500"/>
                                                                )}
                                                            </div>
                                                        ))}
                                                        {/*<div*/}
                                                        {/*    className="h-10 flex items-center justify-center w-[200px]">*/}
                                                        {/*    Sınırsız*/}
                                                        {/*</div>*/}
                                                    </nav>
                                                </section>
                                                <section className="flex items-center text-[15px] hover:bg-zinc-50">
                                                    <div className="flex-1 px-4">
                                                        Etiket Limiti
                                                    </div>
                                                    <nav className="grid grid-cols-4">
                                                        {data.data.map((pkg, index) => (
                                                            <div
                                                                className="h-10 flex items-center justify-center w-[200px]">
                                                                {pkg.package_limits.label === 0 && 'Sınırsız'}
                                                                {pkg.package_limits.label > 0 && pkg.package_limits.label}
                                                                {pkg.package_limits.label === -1 && (
                                                                    <AiOutlineMinusCircle size={20}
                                                                                          className="text-red-500"/>
                                                                )}
                                                            </div>
                                                        ))}
                                                        {/*<div*/}
                                                        {/*    className="h-10 flex items-center justify-center w-[200px]">*/}
                                                        {/*    Sınırsız*/}
                                                        {/*</div>*/}
                                                    </nav>
                                                </section>
                                                <section className="flex items-center text-[15px] hover:bg-zinc-50">
                                                    <div className="flex-1 px-4">
                                                        Otomasyon Limiti
                                                    </div>
                                                    <nav className="grid grid-cols-4">
                                                        {data.data.map((pkg, index) => (
                                                            <div
                                                                className="h-10 flex items-center justify-center w-[200px]">
                                                                {pkg.package_limits.automation === 0 && 'Sınırsız'}
                                                                {pkg.package_limits.automation > 0 && pkg.package_limits.automation}
                                                                {pkg.package_limits.automation === -1 && (
                                                                    <AiOutlineMinusCircle size={20}
                                                                                          className="text-red-500"/>
                                                                )}
                                                            </div>
                                                        ))}
                                                        {/*<div*/}
                                                        {/*    className="h-10 flex items-center justify-center w-[200px]">*/}
                                                        {/*    Sınırsız*/}
                                                        {/*</div>*/}
                                                    </nav>
                                                </section>
                                                <section className="flex items-center text-[15px] hover:bg-zinc-50">
                                                    <div className="flex-1 px-4">
                                                        Belge Limiti
                                                    </div>
                                                    <nav className="grid grid-cols-4">
                                                        {data.data.map((pkg, index) => (
                                                            <div
                                                                className="h-10 flex items-center justify-center w-[200px]">
                                                                {pkg.package_limits.document === 0 && 'Sınırsız'}
                                                                {pkg.package_limits.document > 0 && pkg.package_limits.document}
                                                                {pkg.package_limits.document === -1 && (
                                                                    <AiOutlineMinusCircle size={20}
                                                                                          className="text-red-500"/>
                                                                )}
                                                            </div>
                                                        ))}
                                                        {/*<div*/}
                                                        {/*    className="h-10 flex items-center justify-center w-[200px]">*/}
                                                        {/*    Sınırsız*/}
                                                        {/*</div>*/}
                                                    </nav>
                                                </section>
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>

                                {Object.entries(PACKAGE_FEATURES).map(([groupKey, group]) => (
                                    <Disclosure as="section" className="w-full" defaultOpen={true}>
                                        {({open}) => (
                                            <>
                                                <Disclosure.Button
                                                    className="h-12 flex items-center px-4 bg-[#f9f9f9] w-full text-[15px] font-semibold gap-2.5">
                                                    {group.name}
                                                    <IoIosArrowDown size={18} className={open && 'rotate-180'}/>
                                                </Disclosure.Button>
                                                <Disclosure.Panel className="grid">
                                                    {Object.entries(group.options).map(([featureKey, feature]) => (
                                                        <section
                                                            className="flex items-center text-[15px] hover:bg-zinc-50">
                                                            <div className="flex-1 px-4">
                                                                {feature.name}
                                                            </div>
                                                            <nav className="grid grid-cols-4">
                                                                {data.data.map((pkg, index) => (
                                                                    <div
                                                                        className="h-10 flex items-center justify-center w-[200px]">
                                                                        {feature.type === 'option' && (
                                                                            <>
                                                                                {pkg.package_features?.[groupKey]?.[featureKey] === 'paid' && 'Ücretli'}
                                                                                {pkg.package_features?.[groupKey]?.[featureKey] === false && (
                                                                                    <AiOutlineMinusCircle size={20}
                                                                                                          className="text-red-500"/>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                        {feature.type === 'yes-no' && (
                                                                            <>
                                                                                {pkg.package_features?.[groupKey]?.[featureKey] === true && (
                                                                                    <AiOutlineCheckCircle size={20}
                                                                                                          className="text-green-500"/>
                                                                                )}
                                                                                {pkg.package_features?.[groupKey]?.[featureKey] === false && (
                                                                                    <AiOutlineMinusCircle size={20}
                                                                                                          className="text-red-500"/>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                ))}
                                                                {/*<div*/}
                                                                {/*    className="h-10 flex items-center justify-center w-[200px]">*/}
                                                                {/*    Sınırsız*/}
                                                                {/*</div>*/}
                                                            </nav>
                                                        </section>
                                                    ))}
                                                </Disclosure.Panel>
                                            </>
                                        )}
                                    </Disclosure>
                                ))}

                            </div>

                        </div>

                    </>
                )}

            </section>
        </>
    )
}
