import {createSlice} from "@reduxjs/toolkit";
import {useSelector} from "react-redux";
import {store} from "~/store";

const initialState = {
	menu: false,
	settings: false,
	header: true,
	footer: true
}

const webSlice = createSlice({
	name: 'web',
	initialState,
	reducers: {
		updateMenu: (state, action) => {
			state.menu = action.payload
		},
		updateSettings: (state, action) => {
			state.settings = action.payload
		},
		updateHeader: (state, action) => {
			state.header = action.payload
		},
		updateFooter: (state, action) => {
			state.footer = action.payload
		}
	}
})

export const { updateMenu, updateFooter, updateHeader, updateSettings } = webSlice.actions

export const useMenu = () => useSelector(state => state.web.menu)
export const useSettings = () => useSelector(state => state.web.settings)
export const useHeader = () => useSelector(state => state.web.header)
export const useFooter = () => useSelector(state => state.web.footer)

export const webState = {
	setMenu: data => store.dispatch(updateMenu(data)),
	setSettings: data => store.dispatch(updateSettings(data)),
	setHeader: data => store.dispatch(updateHeader(data)),
	setFooter: data => store.dispatch(updateFooter(data))
}

export default webSlice.reducer
