import {modal} from "~/store/modal.js";

export default function HeroSection() {
    return (
        <section className="container mx-auto min-h-[calc(100dvh-220px)] flex flex-col justify-center">
            <div className="px-4 md:px-[170px]">

                <h2 className="text-3xl md:text-[55px] md:leading-[65px] text-center font-semibold mb-8">
                    Klinik, Güzellik Merkezi ve Spa için En İyi CRM Stratejileri
                </h2>

                <p className="text-lg md:text-xl text-center font-light mb-10">
                    WiseCRM, güzellik merkezleri ve klinikler için özel olarak tasarlanmış bir uygulamadır.
                </p>

                <div className="flex justify-center items-center flex-col gap-4">
                    <button
                        onClick={() => modal.append('web.auth.register')}
                        className="h-10 md:h-12 text-white text-base md:text-lg font-medium flex items-center px-8 md:px-12 bg-[#22c55f]"
                    >
                        Ücretsiz Deneyin
                    </button>
                    <small className="font-mono text-zinc-500 text-center text-xs md:text-base px-4 md:px-0">
                        14 günlük ücretsiz deneme. Kredi kartına gerek yok.
                    </small>
                </div>

            </div>
        </section>
    )
}