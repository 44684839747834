import {useState} from "react";
import classNames from "classnames";

const features = [
    {
        title: 'Entegrasyonlar',
        icon: (
            <svg className="w-10 h-10" viewBox="0 0 57 57" xmlns="http://www.w3.org/2000/svg">
                <g stroke="currentColor" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinejoin="round">
                    <path
                        d="M1.176 5.681C1.31 3.44 2.77 1.701 4.998 1.413 6.718 1.186 9.204 1 12.713 1c3.509 0 5.994.187 7.715.411 2.228.292 3.687 2.028 3.822 4.27.098 1.605.176 3.873.176 7.032s-.078 5.427-.176 7.032c-.135 2.242-1.594 3.98-3.822 4.268-1.72.226-4.206.413-7.715.413-3.509 0-5.994-.187-7.715-.411-2.227-.292-3.687-2.028-3.822-4.27C1.078 18.14 1 15.872 1 12.713s.078-5.427.176-7.032zM1.176 51.319c.135 2.242 1.595 3.98 3.822 4.268 1.72.227 4.206.413 7.715.413 3.509 0 5.994-.187 7.715-.411 2.228-.29 3.687-2.028 3.822-4.27.098-1.605.176-3.873.176-7.032s-.078-5.427-.176-7.032c-.135-2.242-1.594-3.98-3.822-4.268-1.72-.227-4.206-.413-7.715-.413-3.509 0-5.994.187-7.715.411-2.227.292-3.687 2.028-3.822 4.27C1.078 38.86 1 41.128 1 44.287s.078 5.427.176 7.032zM51.319 1.176c2.242.135 3.98 1.595 4.268 3.822.227 1.72.413 4.206.413 7.715 0 3.509-.187 5.994-.411 7.715-.29 2.228-2.028 3.687-4.27 3.822-1.605.098-3.873.176-7.032.176s-5.427-.078-7.032-.176c-2.242-.135-3.98-1.594-4.268-3.822-.227-1.72-.413-4.206-.413-7.715 0-3.509.187-5.994.411-7.715.292-2.227 2.028-3.687 4.27-3.822C38.86 1.078 41.128 1 44.287 1s5.427.078 7.032.176zM42.707 55.926c-1.323-.133-2.143-1.257-2.194-2.587a192.585 192.585 0 0 1-.112-5.167c-1.722-.014-3.444-.05-5.165-.11-1.33-.053-2.455-.871-2.588-2.195a15.864 15.864 0 0 1-.074-1.58c0-.609.029-1.134.074-1.58.133-1.323 1.258-2.143 2.588-2.194 1.137-.043 2.803-.087 5.166-.112.024-2.362.068-4.028.11-5.165.053-1.33.872-2.455 2.195-2.588.447-.045.971-.074 1.58-.074.61 0 1.134.029 1.58.074 1.324.133 2.143 1.257 2.194 2.588.043 1.137.087 2.803.112 5.166 2.362.024 4.028.068 5.166.11 1.33.053 2.454.872 2.587 2.195.045.446.074.971.074 1.58 0 .61-.029 1.134-.074 1.58-.133 1.324-1.257 2.143-2.587 2.194-1.138.043-2.804.087-5.167.112-.013 1.722-.05 3.444-.11 5.166-.053 1.33-.871 2.454-2.195 2.587-.446.045-.97.074-1.58.074-.609 0-1.133-.029-1.58-.074z"/>
                </g>
            </svg>
        ),
        image: '/img/features/appointment.webp'
    },
    {
        title: 'Raporlar',
        icon: (
            <svg className="h-10" viewBox="0 0 61 56" xmlns="http://www.w3.org/2000/svg">
                <g stroke="currentColor" strokeWidth="2" fill="none" fillRule="evenodd">
                    <path d="M4 48V38h18v10M22 48V20h18v28M40 48V1h17v47M0 55h61"/>
                </g>
            </svg>
        ),
        image: '/img/features/appointment.webp'
    },
    {
        title: 'Potansiyel',
        icon: (
            <svg className="h-10" viewBox="0 0 51 56" xmlns="http://www.w3.org/2000/svg">
                <g stroke="currentColor" fill="none" fillRule="evenodd">
                    <path strokeWidth="1.5" d="M25.5 0v13M25.5 24v13M31 18.5h13M20 18.5H7"/>
                    <path
                        d="M17.763 40A50.986 50.986 0 0 0 1 45.642V55h49v-9.358A50.988 50.988 0 0 0 33.237 40M12 18c0 7.18 5.82 13 13 13s13-5.82 13-13S32.18 5 25 5s-13 5.82-13 13"
                        strokeWidth="2"/>
                </g>
            </svg>
        ),
        image: '/img/features/appointment.webp'
    },
    {
        title: 'Finans',
        icon: (
            <svg className="h-10" viewBox="0 0 57 56" xmlns="http://www.w3.org/2000/svg">
                <g stroke="currentColor" strokeWidth="2" fill="none" fillRule="evenodd">
                    <path d="m19 55 12.95-13.3 9.25 9.5L56 36"/>
                    <path
                        d="M41 36h15v15M35.233 31.355A18.999 18.999 0 0 0 9.956 3.873a18.999 18.999 0 0 0 13.521 34.804M19.5 12V7M19.5 33v-5"/>
                    <path
                        d="M26 16v-1.333A2.667 2.667 0 0 0 23.333 12h-6.666A2.667 2.667 0 0 0 14 14.667v1.192a2.667 2.667 0 0 0 2.088 2.606l7.821 1.739A2.667 2.667 0 0 1 26 22.807v2.526A2.667 2.667 0 0 1 23.333 28h-6.666A2.667 2.667 0 0 1 14 25.333v-1.334"/>
                </g>
            </svg>
        ),
        image: '/img/features/appointment.webp'
    },
    {
        title: 'Randevular',
        icon: (
            <svg className="h-10" viewBox="0 0 56 56" xmlns="http://www.w3.org/2000/svg">
                <g stroke="currentColor" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round"
                   strokeLinejoin="round">
                    <path
                        d="M26 29.5h5M38 29.5h5M18 29.5h-5M26 41.5h5M38 41.5h5M18 41.5h-5M11.29 8c-1.462.142-2.726.29-3.796.433-2.827.378-4.985 2.513-5.39 5.346C1.58 17.447 1 23.28 1 31.137c0 7.855.58 13.69 1.104 17.36.405 2.832 2.563 4.965 5.39 5.345C11.518 54.38 18.268 55 28 55c9.73 0 16.48-.62 20.506-1.158 2.827-.378 4.985-2.513 5.39-5.346C54.42 44.828 55 38.992 55 31.136c0-7.853-.58-13.689-1.104-17.357-.405-2.833-2.563-4.968-5.39-5.346A105.322 105.322 0 0 0 44.71 8M34 7.537c-2-.05-4-.075-6-.074-2.155 0-4.154.027-6 .074"/>
                    <path
                        d="M34.016 9.151c.054 2.704 1.762 4.803 4.362 4.844.414.007.83.007 1.244 0 2.6-.041 4.308-2.14 4.362-4.844.021-1.1.021-2.201 0-3.302-.054-2.704-1.762-4.802-4.362-4.844a38.99 38.99 0 0 0-1.244 0c-2.6.042-4.308 2.14-4.362 4.844a82.836 82.836 0 0 0 0 3.302h0zM12.016 9.151c.054 2.704 1.762 4.803 4.362 4.844.414.007.83.007 1.244 0 2.6-.041 4.308-2.14 4.362-4.844.021-1.1.021-2.201 0-3.302-.054-2.704-1.762-4.802-4.362-4.844a38.99 38.99 0 0 0-1.244 0c-2.6.042-4.308 2.14-4.362 4.844a82.836 82.836 0 0 0 0 3.302h0z"/>
                </g>
            </svg>
        ),
        image: '/img/features/appointment.webp'
    },
    {
        title: 'Müşteriler',
        icon: (
            <svg className="h-10" viewBox="0 0 60 56" xmlns="http://www.w3.org/2000/svg">
                <g stroke="currentColor" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round"
                   strokeLinejoin="round">
                    <path
                        d="M21 23c6.075 0 11-4.925 11-11S27.075 1 21 1 10 5.925 10 12s4.925 11 11 11zM1 55h40v-2.491a21.27 21.27 0 0 0-2.597-9.988 20.434 20.434 0 0 0-6.941-7.447A19.578 19.578 0 0 0 21 32a19.578 19.578 0 0 0-10.462 3.074 20.434 20.434 0 0 0-6.941 7.447A21.27 21.27 0 0 0 1 52.51V55zM39 23c6.075 0 11-4.925 11-11S45.075 1 39 1M49.857 55H59v-2.439a20.04 20.04 0 0 0-2.671-9.777 20.436 20.436 0 0 0-7.14-7.29A20.804 20.804 0 0 0 43 33"/>
                </g>
            </svg>
        ),
        image: '/img/features/appointment.webp'
    },
    {
        title: 'Etkinlikler',
        icon: (
            <svg className="h-10" viewBox="0 0 53 55" xmlns="http://www.w3.org/2000/svg">
                <g stroke="currentColor" fill="none" fillRule="evenodd">
                    <g strokeWidth="2">
                        <path d="M1 8h51v46H1zM8 44.5h15M29 44.5h15M8.5 0v8M44.5 0v8"/>
                    </g>
                    <path
                        d="M31.148 25.377a15.173 15.173 0 0 0 1.949-6.651l-4.071-.488-1.434 5.017 3.556 2.122zm0 0a15.217 15.217 0 0 1-2.48 3.253 15.217 15.217 0 0 1-3.254 2.48m0 0a15.171 15.171 0 0 1-6.65 1.949l-.488-4.071 5.016-1.433 2.122 3.555z"
                        strokeWidth="1.5"/>
                    <path
                        d="M31.148 25.377a15.173 15.173 0 0 0 1.949-6.651l-4.071-.488-1.434 5.017 3.556 2.122zm0 0a15.217 15.217 0 0 1-2.48 3.253 15.217 15.217 0 0 1-3.254 2.48m0 0a15.171 15.171 0 0 1-6.65 1.949l-.488-4.071 5.016-1.433 2.122 3.555z"
                        strokeWidth="2"/>
                </g>
            </svg>
        ),
        image: '/img/features/appointment.webp'
    },
    {
        title: 'Otomasyon',
        icon: (
            <svg className="h-10" viewBox="0 0 46 56" xmlns="http://www.w3.org/2000/svg">
                <g stroke="currentColor" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round"
                   strokeLinejoin="round">
                    <path
                        d="M15 47.538a6.817 6.817 0 0 0-3.224 3.179C10.717 52.905 8.962 55 6.527 55h-1.05c-1.984 0-3.762-1.32-4.08-3.28C-.422 40.67 4.288 31.677 9.916 29M31 47.55c1.637.818 2.63 1.948 3.22 3.174C35.28 52.908 37.035 55 39.47 55h1.05c1.985 0 3.762-1.318 4.084-3.274C46.431 40.633 41.658 31.615 35.99 29"/>
                    <path
                        d="M22.998 1c-19.953 7.833-12.15 35.903-7.674 47h15.347c4.485-11.097 12.284-39.167-7.673-47zM23 33v22"/>
                    <path d="M35 17H10c1.277-6.936 4.871-12.96 12.5-16 7.625 3.04 11.223 9.068 12.5 16z"/>
                </g>
            </svg>
        ),
        image: '/img/features/appointment.webp'
    },
    {
        title: 'Belgeler',
        icon: (
            <svg className="h-10" viewBox="0 0 52 56" xmlns="http://www.w3.org/2000/svg">
                <g stroke="currentColor" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round"
                   strokeLinejoin="round">
                    <path
                        d="M50.423 46.378c.545-8.64.704-17.298.477-25.951a8.54 8.54 0 0 0-1.668-4.874c-4-5.39-7.181-8.743-12.43-12.753A8.59 8.59 0 0 0 31.77 1.06 266.56 266.56 0 0 0 25.998 1c-6.168 0-11.145.18-16.29.536-4.377.291-7.858 3.75-8.135 8.086A291 291 0 0 0 1 28c0 6.3.195 12.447.573 18.378.272 4.338 3.754 7.79 8.135 8.087 5.145.355 10.122.535 16.29.535 6.167 0 11.144-.18 16.29-.535 4.377-.292 7.857-3.752 8.135-8.087zM17 28h17M17 40.76h17M17 14.24h10.29"/>
                </g>
            </svg>
        ),
        image: '/img/features/appointment.webp'
    }
]

export default function ImageFeature() {

    const [active, setActive] = useState(4)

    const activeFeature = features[active]

    return (
        <section className="container mx-auto">
            <nav className="px-4 md:px-0 flex overflow-auto whitespace-nowrap md:grid grid-cols-9 gap-4">
                {features.map((feature, index) => (
                    <button
                        type="button"
                        onClick={() => {
                            setActive(index)
                        }}
                        className={classNames("w-[120px] flex-shrink-0 md:w-auto border rounded-lg py-5 flex flex-col text-center items-center", {
                            "text-black border-[#d1d1d1]": active !== index,
                            "text-[#22c55f] border-[#22c55f]": active === index
                        })}
                    >
                        <div className="h-10 w-10 mx-auto flex items-center justify-center">
                            {feature.icon}
                        </div>
                        <div className="text-[13px] text-black mt-3.5 text-center px-3">{feature.title}</div>
                    </button>
                ))}
            </nav>

            {activeFeature && (
                <div className="mt-10 relative">
                    <img src={activeFeature.image} alt={`${activeFeature.name} Görseli`} className="w-full" />
                    <div className="w-full h-1/2 bg-gradient-to-b from-transparent to-white absolute bottom-0 left-0 z-[1]" />
                </div>
            )}

        </section>
    )
}