import {modal, useModal, useModalDisable} from "~/store/modal";
import modals from "~/routes/tablet/modals";
import {AnimatePresence, motion} from "framer-motion"
import OpacityContent from "~/components/animated/opacity";
import AnimatedContent from "~/components/animated";

export default function Modals() {

	const allModals = useModal()
	const isCloseDisabled = useModalDisable()

	return (
		<OpacityContent
			exitDelay={0.1}
			onClick={() => {
				if (!isCloseDisabled) {
					modal.removeLast()
				}
			}}
			className="fixed top-0 left-0 z-[999] w-full h-full flex items-center justify-center flex-wrap overflow-auto"
		>
			<div className="bg-black/40 w-full h-full absolute inset-0 z-[1]"/>
			<AnimatePresence>
				{allModals.map((modalData, key) => {
					const currentModal = modals.find(modal => modal.name === modalData.name)
					if (currentModal) {
						if (currentModal?.view === 'side') {
							return (
								<motion.div
									initial={{translateX: '100%', opacity: 0}}
									animate={{opacity: 1, translateX: 0}}
									exit={{translateX: '100%', opacity: 0, transition: {delay: 0}}}
									transition={{duration: 0.3, ease: 'easeInOut', delay: 0.1}}
									key={key}
									onClick={e => e.stopPropagation()}
									className="bg-white shadow-lg h-full ml-auto absolute top-0 right-0 z-[-1] last:z-[1]"
								>
									<currentModal.element data={modalData?.data} close={modal.removeLast}/>
								</motion.div>
							)
						}
						return (
							<AnimatedContent
								delay={0.1}
								key={key}
								onClick={e => {
									// e.preventDefault()
									e.stopPropagation()
								}}
								className="bg-white shadow-lg rounded-md absolute top-1/2 left-1/2 -translate-y-1/2 max-h-full overflow-auto -translate-x-1/2 z-[-1] last:z-[1]">
								<currentModal.element data={modalData?.data} close={modal.removeLast}/>
							</AnimatedContent>
						)
					} else {
						return (
							<div className="p-4 bg-white rounded-md shadow-lg">
								<b>{modalData.name}</b> modalı bulunmuyor. Kontrol edin!
							</div>
						)
					}
				})}
			</AnimatePresence>
		</OpacityContent>
	)
}
