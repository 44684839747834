import {Field, Form, Formik} from "formik";
import Button from "~/components/form/button";
import {BsTabletLandscape} from "react-icons/bs";
import {tabletService} from "~/services/app";
import {setTablet} from "~/store/tablet";

export default function TabletLogin({ data }) {
	return (
		<div className="h-screen flex flex-col">
			<div className="flex-1 flex flex-col items-center justify-center">

				<h6 className="text-2xl font-medium text-zinc-800 flex flex-col justify-center items-center gap-1 mb-6">
					<BsTabletLandscape size={50} className="text-zinc-500" />
					{data?.name}
				</h6>

				<Formik
					initialValues={{
						username: data?.username,
						password: ''
					}}
					onSubmit={async values => {
						const result = await tabletService.login(values)
						setTablet(result?.data)
					}}
				>
					{({ values, isSubmitting }) => (
						<Form className="w-[400px]">
							<label className="w-full border border-zinc-300 rounded-t-xl block bg-zinc-50 opacity-70">
								<h6 className="px-4 text-[11px] text-zinc-400 pt-2 flex items-center">Tablet ID</h6>
								<Field
									className="pt-2 pb-4 h-10 text-xl font-semibold w-full bg-transparent px-4 outline-none"
									name="username"
									disabled={true}
								/>
							</label>
							<label className="w-full border border-zinc-300 rounded-b-xl block border-t-0">
								<h6 className="px-4 text-[11px] text-zinc-400 pt-2 flex items-center">Parola</h6>
								<Field
									className="pt-2 pb-4 h-10 text-xl font-semibold w-full bg-transparent px-4 outline-none"
									name="password"
									type="password"
									placeholder="*****"
								/>
							</label>
							{values.password && (
								<Button
									type="submit"
									variant="black"
									size="big"
									loading={isSubmitting}
									disabled={isSubmitting}
									className="!rounded-xl w-full mt-3"
								>
									Giriş yap
								</Button>
							)}
						</Form>
					)}
				</Formik>
			</div>
		</div>
	)
}
