import {setStaff, setStaffToken, useStaff, useStaffToken} from "~/store/business.js";
import AppLogin from "~/pages/web/app/login/index.jsx";
import {useEffect, useState} from "react";
import {businessService} from "~/services/app/index.js";
import {useNavigate} from "react-router-dom";
import FullScreenLoader from "~/components/full-screen-loader/index.jsx";

export default function App() {

    const navigate = useNavigate()
    const staffToken = useStaffToken()
    const staff = useStaff()

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        localStorage.setItem('download_app', true)
        localStorage.setItem('wisecrm_in_app', true)
    }, []);

    useEffect(() => {
        if (staffToken) {
            setLoading(true)
            businessService.info()
                .then(res => {
                    navigate(`/${res.data.business.business_slug}`, {replace: true})
                    setStaff(res.data)
                })
                .catch(() => {
                    setStaffToken(null)
                })
                .finally(() => {
                    setLoading(false)
                })
        } else {
            setLoading(false)
        }
    }, [staffToken])

    if (loading) {
        return <FullScreenLoader text="Kontrol ediliyor.." />
    }

    if (!staff) {
        return <AppLogin/>
    }

    return (
        <div>
            app
        </div>
    )
}