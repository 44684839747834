import {TailSpin} from "react-loader-spinner";

export default function Loader() {
	return (
		<div className="h-[150px] w-full flex items-center justify-center">
			<TailSpin
				height="30"
				width="30"
				color="#333"
				radius="1"
				visible={true}
			/>
		</div>
	)
}
