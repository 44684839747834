import {Menu} from "@headlessui/react";
import {IoIosArrowDown} from "react-icons/io";
import {confirmationTypes, getConfirmationType} from "~/utils/const";
import classNames from "classnames";
import {useState} from "react";
import {appointmentService} from "~/services/app";
import {useTranslation} from "react-i18next";

export default function AppointmentConfirmation({ type, hashId, onSuccess }) {

	const {t} = useTranslation()
	const [confirmation, setConfirmation] = useState(type)
	const activeConfirmation = getConfirmationType(confirmation)

	return (
		<Menu as="nav" className="relative">
			<Menu.Button className="flex text-left items-center gap-1.5">
				{t(activeConfirmation?.value)}
				<IoIosArrowDown size={16} className="print:hidden" />
			</Menu.Button>
			<Menu.Items
				className="absolute bg-white outline-none top-full p-2 left-0 w-[220px] border overflow-hidden border-zinc-300 rounded-xl translate-y-2  z-10"
			>
				{confirmationTypes.map(c => (
					<Menu.Item>
						{({ active }) => (
							<button
								onClick={() => {
									setConfirmation(c.key)
									appointmentService.update({
										appointment_confirmation: c.key,
										hash_id: hashId
									})
										.then(() => {
											onSuccess?.()
										})
								}}
								type="button"
								className={classNames("h-9 border px-4 rounded-md line-clamp-1 w-full text-left text-sm", {
									"!bg-sky-50 !text-sky-500 !border-sky-300": confirmation === c.key,
									"border-transparent": !confirmation === c.key || !active,
									"bg-zinc-50 text-zinc-500": active
								})}
							>
								{t(c.value)}
							</button>
						)}
					</Menu.Item>
				))}
			</Menu.Items>
		</Menu>
	)
}
