import {
    BsCalendar,
    BsCalendar2Minus,
    BsCalendar3Week,
    BsCalendarCheck, BsCalendarMinus,
    BsCalendarPlus,
    BsCalendarX
} from "react-icons/bs";
import {FaRestroom} from "react-icons/fa";
import {IoAirplaneOutline} from "react-icons/io5";
import {dayjs} from "~/utils/date";

export const currencies = [
    {
        key: 'TRY',
        value: 'TL'
    },
    {
        key: 'EUR',
        value: 'Euro'
    },
    {
        key: 'USD',
        value: 'Dolar'
    },
    {
        key: 'GBP',
        value: 'Pound'
    }
]

export const genders = [
    {
        key: 1,
        value: 'Erkek'
    },
    {
        key: 2,
        value: 'Kadın'
    },
    {
        key: 3,
        value: 'Kadın/Erkek'
    }
]

export const paymentTypes = [
    {
        key: 'cash',
        value: 'Nakit'
    },
    {
        key: 'card',
        value: 'Kredi Kartı'
    },
    {
        key: 'bank',
        value: 'EFT / Havale'
    },
    {
        key: 'installment',
        value: 'Taksit'
    }
]

export const eventStatusList = [
    {
        key: '1',
        value: 'Tamamlanmış',
        color: 'bg-green-500'
    },
    {
        key: '2',
        value: 'Tamamlanmamış',
        color: 'bg-zinc-700'
    }
]

export const eventTypes = [
    {
        key: 'MEETING',
        value: 'Görüşme',
        icon: (size = 18) => <svg width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.5 12.5c-2.301 0-4.167-1.903-4.167-4.25S6.2 4 8.5 4c2.301 0 4.167 1.903 4.167 4.25S10.8 12.5 8.5 12.5zm9.5 2c-1.534 0-2.778-1.231-2.778-2.75S16.466 9 18 9s2.778 1.231 2.778 2.75S19.534 14.5 18 14.5zM1 21c1.002-3.449 3.98-5.95 7.5-5.95 2.533 0 4.786 1.296 6.223 3.308M16 21a8.736 8.736 0 0 0-1.277-2.642M23 21c-.668-2.231-2.654-3.85-5-3.85-1.24 0-2.38.452-3.277 1.208"
                stroke="currentColor" strokeWidth="1.2" fill="none" fillRule="evenodd" strokeDasharray="0,0"/>
        </svg>
    },
    {
        key: 'CALL',
        value: 'Arama',
        icon: (size = 18) => <svg width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g stroke="currentColor" strokeWidth="1.2" fill="none" fillRule="evenodd"
               strokeDasharray="0,0">
                <path
                    d="m15.869 6 3.277.065a9.827 9.827 0 0 1 .154 7.65l-3.52-3.518a.13.13 0 0 1-.039-.08l.028-4c0-.018.005-.034.015-.048.026-.047.054-.07.085-.069zM6 15.869l.065 3.277a9.827 9.827 0 0 0 7.65.154l-3.518-3.52a.13.13 0 0 0-.08-.039l-4 .028a.086.086 0 0 0-.048.015c-.047.026-.07.054-.069.085zm13.3-2.153a9.955 9.955 0 0 1-2.22 3.365 9.955 9.955 0 0 1-3.364 2.219M12 12 4.117 4.107M4 3.401V10m0-6h6"/>
            </g>
        </svg>
    },
    {
        key: 'MESSAGE',
        value: 'Mesaj',
        icon: (size = 18) => <svg width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6 8.5h12m-2 4H6M20 4H4a1 1 0 0 0-1 1v14.792a.1.1 0 0 0 .162.078L6.75 17H20a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1z"
                stroke="currentColor" strokeWidth="1.2" fill="none" fillRule="evenodd" strokeDasharray="0,0"/>
        </svg>
    },
    {
        key: 'TASK',
        value: 'Görev',
        icon: (size = 18) => <svg width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g stroke="currentColor" strokeWidth="1.2" fill="none" fillRule="evenodd">
                <path
                    d="M22.4 12c0 5.744-4.656 10.4-10.4 10.4-5.744 0-10.4-4.656-10.4-10.4C1.6 6.256 6.256 1.6 12 1.6c5.744 0 10.4 4.656 10.4 10.4z"/>
                <path d="M12 5v7l4 4"/>
            </g>
        </svg>
    },
    {
        key: 'FLY',
        value: 'Gidiş-Geliş',
        icon: (size = 18) => <IoAirplaneOutline size={size}/>
    }
]

export const sources = [
    {
        key: 'Instagram',
        value: 'Instagram'
    },
    {
        key: 'Facebook',
        value: 'Facebook'
    },
    {
        key: 'Web Form',
        value: 'Web Form'
    },
    {
        key: 'Chatbot',
        value: 'Chatbot'
    },
    {
        key: 'Diğer',
        value: 'Diğer'
    }
]

export const statusList = {
    1: {
        title: 'Bekliyor',
        color: '#DFE5FF',
        textColor: '#3850C4',
        icon: BsCalendarPlus
    },
    2: {
        title: 'Geldi',
        color: '#D4EBFF',
        textColor: '#1C507E',
        icon: BsCalendar
    },
    8: {
        title: 'Odaya Al',
        color: '#F5EFFF',
        textColor: '#c732d0',
        icon: FaRestroom
    },
    3: {
        title: 'İşlemde',
        color: '#E5FFFE',
        textColor: '#4CAFAC',
        icon: BsCalendar3Week
    },
    4: {
        title: 'Tamamlandı',
        color: '#E5FFE6',
        textColor: '#4CAF50',
        icon: BsCalendarCheck
    },
    5: {
        title: 'Gelmedi',
        color: '#EBEBEB',
        textColor: '#000',
        icon: BsCalendarMinus
    },
    6: {
        title: 'Ertelendi',
        color: '#FFFAE5',
        textColor: '#887016',
        icon: BsCalendar2Minus
    },
    7: {
        title: 'İptal Edildi',
        color: '#FFE5E5',
        textColor: '#f80404',
        icon: BsCalendarX
    }
}

export const confirmationTypes = [
    {
        key: 1,
        value: 'Whatsapp\'tan teyit edildi'
    },
    {
        key: 2,
        value: 'Telefon ile Teyit Edildi'
    },
    {
        key: 3,
        value: 'Teyit edilmedi'
    },
    {
        key: 4,
        value: 'Cevap yok'
    }
]

export const eventDataTypes = [
    {
        key: 'customer',
        value: 'Müşteri'
    },
    {
        key: 'potential-customer',
        value: 'Potansiyel Müşteri'
    }
]

export const pageTypes = [
    {key: 'index', value: 'Index'},
    {key: 'service', value: 'Hizmet'},
    {key: 'category', value: 'Kategori'},
    {key: 'chatbot', value: 'Chatbot'},
    {key: 'page', value: 'Sayfa'},
    {key: 'lead', value: 'Lead Form'},
]

export const EDUCATION_SETTINGS = [
    {
        key: 'APPOINTMENTS',
        value: 'Randevular'
    },
    {
        key: 'EVENTS',
        value: 'Etkinlikler'
    },
    {
        key: 'FINANCE',
        value: 'Finans'
    },
    {
        key: 'CUSTOMERS',
        value: 'Müşteriler'
    },
    {
        key: 'PRODUCTS',
        value: 'Ürünler'
    },
    {
        key: 'POTENTIAL_CUSTOMERS',
        value: 'Potansiyel Müşteriler'
    },
    {
        key: 'REPORTS',
        value: 'Raporlar'
    },
    {
        key: 'SALE',
        value: 'Hizmet & Ürün Satışı'
    },
    {
        key: 'SETTINGS:AUTOMATION',
        value: 'Ayarlar > Otomasyon'
    },
    {
        key: 'SETTINGS:CHATBOT',
        value: 'Ayarlar > Chatbot'
    },
    {
        key: 'SETTINGS:WEBSITE',
        value: 'Ayarlar > Website'
    },
    {
        key: 'SETTINGS:DOCUMENTS',
        value: 'Ayarlar > Belgeler'
    },
    {
        key: 'SETTINGS:PRODUCTS',
        value: 'Ayarlar > Ürün Yönetimi'
    },
    {
        key: 'SETTINGS:SERVICES',
        value: 'Ayarlar > Hizmetler'
    },
    {
        key: 'SETTINGS:ROOMS',
        value: 'Ayarlar > Hizmet Odaları'
    },
    {
        key: 'SETTINGS:TABLETS',
        value: 'Ayarlar > Tabletler'
    },
    {
        key: 'SETTINGS:KANBAN',
        value: 'Ayarlar > Kanban'
    },
    {
        key: 'SETTINGS:INTEGRATIONS',
        value: 'Ayarlar > Entegrasyonlar'
    },
    {
        key: 'SETTINGS:BUSINESS',
        value: 'Ayarlar > Şirket Ayarları'
    },
    {
        key: 'SETTINGS:BUSINESS_USERS',
        value: 'Ayarlar > Şirket > Kullanıcılar'
    },
    {
        key: 'SETTINGS:BUSINESS_INVOICES',
        value: 'Ayarlar > Şirket > Faturalar'
    }
]

export const PACKAGE_FEATURES = {
    message: {
        name: 'MESAJLAŞMA',
        options: {
            instagram: {
                name: 'Instagram DM',
                type: 'option'
            },
            whatsapp: {
                name: 'WhatsApp',
                type: 'option'
            },
            googleMap: {
                name: 'Google harita mesajları',
                type: 'option'
            }
        }
    },
    appointment: {
        name: 'RANDEVU YÖNETİMİ',
        options: {
            kanban: {
                name: 'Randevu kanban görünümü',
                type: 'yes-no'
            },
            list: {
                name: 'Randevu liste görünümü',
                type: 'yes-no'
            },
            monthlyIntensity: {
                name: 'Aylık oda yoğunlukları',
                type: 'yes-no'
            },
            dailyIntensity: {
                name: 'Günlük oda yoğunlukları',
                type: 'yes-no'
            },
            advanceFilter: {
                name: 'Gelişmiş filtreler',
                type: 'yes-no'
            },
            notes: {
                name: 'Randevu notları',
                type: 'yes-no'
            },
            assign: {
                name: 'Randevuya uzman atama',
                type: 'yes-no'
            },
            seanceInfo: {
                name: 'Randevu hizmet seans bilgileri',
                type: 'yes-no'
            },
            serviceReceipt: {
                name: 'Hizmet makbuzu',
                type: 'yes-no'
            },
            tablet: {
                name: 'Hizmet odaları seans bilgisi',
                type: 'yes-no'
            },
            cancelReason: {
                name: 'Randevu iptal sebebi',
                type: 'yes-no'
            },
            performance: {
                name: 'Müşteri randevu performans ekranı',
                type: 'yes-no'
            },
            smsConfirmation: {
                name: 'Randevu SMS ile teyit',
                type: 'yes-no'
            },
            whatsappConfirmation: {
                name: 'Randevu WhatsApp ile teyit',
                type: 'option'
            },
            callConfirmation: {
                name: 'Randevu arama teyiti',
                type: 'yes-no'
            },
            serviceTimeCalculation: {
                name: 'Randevu hizmet süresi hesaplama (başla-bitir)',
                type: 'yes-no'
            },
            serviceRoomLights: {
                name: 'Randevu başlat-bitir butonunun kapı ikaz ışıklarını çalıştırması (Dolu, Boş)',
                type: 'option'
            },
            serviceRoomLocks: {
                name: 'Randevu başlat-bitir butonunun kapı kilidini çalıştırması (Aç, Kapat)',
                type: 'option'
            },
            automationTrigger: {
                name: 'Tüm randevu durumları otomasyon tetikleyicisi',
                type: 'yes-no'
            },
            serviceRoomAirConditioning: {
                name: 'Hizmet odaları otomasyon klima kontrolü',
                type: 'option'
            }
        }
    },
    customer: {
        name: 'MÜŞTERİ YÖNETİMİ',
        options: {
            detail: {
                name: 'Müşteri detayları',
                type: 'yes-no'
            },
            serviceLogs: {
                name: 'Müşteri geçmiş işlem kayıtları',
                type: 'yes-no'
            },
            dynamicFields: {
                name: 'Müşteri bilgileri dinamik alanlar',
                type: 'option'
            },
            packageCloseOptions: {
                name: 'Müşteri paket sonlandırma, erken kapama ve dondurma',
                type: 'yes-no'
            },
            services: {
                name: 'Müşteri hizmet detayları',
                type: 'yes-no'
            },
            products: {
                name: 'Müşteri ürün detayları',
                type: 'yes-no'
            },
            installment: {
                name: 'Müşteri taksit detayları',
                type: 'yes-no'
            },
            paymentHistory: {
                name: 'Müşteri ödeme geçmişi',
                type: 'yes-no'
            },
            casting: {
                name: 'Müşteri cari döküm',
                type: 'yes-no'
            },
            notes: {
                name: 'Müşteri notlar ve not sabitleme',
                type: 'yes-no'
            },
            filter: {
                name: 'Müşteri listesi gelişmiş filtreleme',
                type: 'yes-no'
            },
            docs: {
                name: 'Müşteri dosyaları Google Drive (Dijital sözleşme, fotoğraflar, dökümanlar)',
                type: 'option'
            },
            appointmentDocuments: {
                name: 'Müşteri hizmet sözleşmeleri',
                type: 'yes-no'
            },
            logs: {
                name: 'Müşteri log kayıtları',
                type: 'yes-no'
            }
        }
    },
    service: {
        name: 'HİZMETLER',
        options: {
            create: {
                name: 'Paket oluşturma',
                type: 'yes-no'
            },
            campaign: {
                name: 'Kampanya oluşturma',
                type: 'yes-no'
            },
            seance: {
                name: 'Seanslı hizmetler',
                type: 'yes-no'
            },
            duration: {
                name: 'Hizmet süreleri',
                type: 'yes-no'
            },
            roomSpecific: {
                name: 'Odaya göre hizmet belirleme',
                type: 'yes-no'
            }
        }
    },
    product: {
        name: 'ÜRÜNLER',
        options: {
            stock: {
                name: 'Detaylı stok kontrolü',
                type: 'yes-no'
            },
            automation: {
                name: 'Ürün azaldığında otomasyon tetikleyici ',
                type: 'yes-no'
            },
            barcode: {
                name: 'Barkod entegrasyonu',
                type: 'option'
            }
        }
    },
    potentialCustomer: {
        name: 'POTANSİYEL MÜŞTERİLER',
        options: {
            source: {
                name: 'Potansiyel müşteri kaynak',
                type: 'yes-no'
            },
            assigning: {
                name: 'Potansiyel müşteri sahip atama',
                type: 'yes-no'
            },
            bid: {
                name: 'Potansiyel müşteri teklif',
                type: 'yes-no'
            },
            note: {
                name: 'Potansiyel müşteri notlar',
                type: 'yes-no'
            },
            lead: {
                name: 'Müşteriye dönüştürme',
                type: 'yes-no'
            },
            leadLossReason: {
                name: 'Potansiyel müşteri kayıp sebebi',
                type: 'yes-no'
            },
            event: {
                name: 'Potansiyel müşteri etkinlik oluşturma',
                type: 'yes-no'
            },
            webForm: {
                name: 'Webformları kullanıcılara atama',
                type: 'yes-no'
            },
            filter: {
                name: 'Detaylı filtreleme',
                type: 'yes-no'
            }
        }
    },
    event: {
        name: 'ETKİNLİKLER',
        options: {
            list: {
                name: 'Günlük etkinlik listesi',
                type: 'yes-no'
            },
            dateFilter: {
                name: 'Tarihler arası filtreleme',
                type: 'yes-no'
            },
            dailyTasks: {
                name: 'Günlük görevler',
                type: 'yes-no'
            },
            search: {
                name: 'Arama etkinliği',
                type: 'yes-no'
            },
            message: {
                name: 'Mesaj etkinliği',
                type: 'yes-no'
            },
            meet: {
                name: 'Görüşme etkinliği',
                type: 'yes-no'
            },
            mission: {
                name: 'Görevler',
                type: 'yes-no'
            },
            fly: {
                name: 'Uçuş etkinliği',
                type: 'yes-no'
            },
            trigger: {
                name: 'Etkinlikler otomasyon tetikleyicisi',
                type: 'yes-no'
            },
            note: {
                name: 'Etkinlik notları',
                type: 'yes-no'
            },
            history: {
                name: 'Etkinlik geçmişi',
                type: 'yes-no'
            },
            log: {
                name: 'Geçmiş etkinlikleri hatırlatma',
                type: 'yes-no'
            }
        }
    },
    finance: {
        name: 'FİNANS',
        options: {
            incomeExpense: {
                name: 'Gelir gider takibi',
                type: 'yes-no'
            },
            installment: {
                name: 'Taksitli satış planı',
                type: 'yes-no'
            },
            discount: {
                name: 'İndirimli satış',
                type: 'yes-no'
            },
            cancel: {
                name: 'Satış iptali',
                type: 'yes-no'
            },
            currency: {
                name: 'Tüm para birimleri',
                type: 'yes-no'
            },
            detail: {
                name: 'Finans detayları',
                type: 'yes-no'
            },
            salary: {
                name: 'Maaş',
                type: 'yes-no'
            },
            advance: {
                name: 'Avans',
                type: 'yes-no'
            },
            bonus: {
                name: 'Prim',
                type: 'yes-no'
            },
            expense: {
                name: 'Gider ekleme',
                type: 'yes-no'
            },
            filter: {
                name: 'Detaylı filtreleme',
                type: 'yes-no'
            }
        }
    },
    automation: {
        name: 'OTOMASYON',
        options: {
            appointmentTrigger: {
                name: 'Randevu durum tetikleyicileri',
                type: 'yes-no'
            },
            eventTrigger: {
                name: 'Etkinlik durum tetikleyicileri',
                type: 'yes-no'
            },
            customerTrigger: {
                name: 'Müşteri durum tetikleyicileri',
                type: 'yes-no'
            },
            financeTrigger: {
                name: 'Finans tetikleyicileri',
                type: 'yes-no'
            },
            pollWhatsapp: {
                name: 'Memnuniyet anket otomasyonu (WhatsApp)',
                type: 'yes-no'
            },
            reminder: {
                name: 'Hatırlatma servisleri',
                type: 'yes-no'
            },
            marketing: {
                name: 'Pazarlama otomasyonu',
                type: 'yes-no'
            },
            running: {
                name: 'Tüm yürütmeleri görme',
                type: 'yes-no'
            },
            timers: {
                name: 'Zamanlayıcılar',
                type: 'yes-no'
            },
            hooks: {
                name: 'İşletmeye özel kancalar oluşturma',
                type: 'option'
            },
            whatsapp: {
                name: 'WhatsApp bildirim mesajları ( Metin, Fotoğraf, Anket)',
                type: 'option'
            },
            sms: {
                name: 'SMS bildirimleri (Entegrasyon)',
                type: 'yes-no'
            }
        }
    },
    document: {
        name: 'BELGELER',
        options: {
            form: {
                name: 'Onan formu oluşturma',
                type: 'yes-no'
            },
            create: {
                name: 'Hizmet ve hizmet gruplarına sözleşme oluşturma',
                type: 'yes-no'
            },
            tabletSignature: {
                name: 'Sözleşmeleri tabletten imzalatma',
                type: 'yes-no'
            },
            copy: {
                name: 'Belge kopyasını gönderme (WhatsApp)',
                type: 'option'
            },
            storage: {
                name: 'Belge depolama',
                type: 'option'
            },
            editor: {
                name: 'Belge oluşturma editörü',
                type: 'yes-no'
            }
        }
    },
    security: {
        name: 'GÜVENLİK',
        options: {
            roles: {
                name: 'Rol yetkilendirmeleri',
                type: 'yes-no'
            },
            clock: {
                name: 'Sistem saat kısıtlaması',
                type: 'yes-no'
            },
            ip: {
                name: 'IP kısıtlaması',
                type: 'yes-no'
            },
            device: {
                name: 'Bağlı cihazların kontrolü',
                type: 'yes-no'
            },
            ddos: {
                name: 'DDOS',
                type: 'yes-no'
            },
            uptime: {
                name: 'Uptime',
                type: 'yes-no'
            },
            backup: {
                name: 'Yedek',
                type: 'yes-no'
            }
        }
    }
}

export const USAGE_TYPES = {
    customer: 'Müşteriler',
    appointment: 'Randevular',
    staff: 'Personeller',
    user: 'Kullanıcılar',
    label: 'Etiketler',
    document: 'Belgeler',
    automation: 'Otomasyon',
    'potential-customer': 'Potansiyel Müşteriler'
}

export const REPORT_TYPES = {
    appointments: {
        icon: (
            <svg width="24" height="24" className="flex-shrink-0" viewBox="0 0 30 30"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M26.25 11.187V6a1 1 0 0 0-1-1h-4m5 6.187H3.75m22.5 0V25.25a1 1 0 0 1-1 1H4.75a1 1 0 0 1-1-1V11.187m0 0V6a1 1 0 0 1 1-1h4m0 0V1.25m0 3.75h12.5m0 0V1.25"
                    stroke="currentColor" strokeWidth="1.2" fill="none"
                    fillRule="evenodd"
                    strokeDasharray="0,0"/>
            </svg>
        ),
        name: 'Randevular'
    },
    // events: {
    //     icon: (
    //         <svg xmlns="http://www.w3.org/2000/svg" className="flex-shrink-0" height="27" viewBox="0 -960 960 960"
    //              width="27"
    //              fill="currentColor">
    //             <path
    //                 d="M200-575.39h560v-119.99q0-9.24-7.69-16.93-7.69-7.69-16.93-7.69H224.62q-9.24 0-16.93 7.69-7.69 7.69-7.69 16.93v119.99Zm0 0V-720-575.39ZM224.62-120q-27.62 0-46.12-18.5Q160-157 160-184.62v-510.76q0-27.62 18.5-46.12Q197-760 224.62-760h70.76v-89.23h43.08V-760h286.16v-89.23h40V-760h70.76q27.62 0 46.12 18.5Q800-723 800-695.38v210.07q-9.77-3.61-19.77-5.38T760-493.92v-41.46H200v350.76q0 9.24 7.69 16.93 7.69 7.69 16.93 7.69h268.15q3.15 11.23 8.04 20.85 4.88 9.61 10.19 19.15H224.62Zm510.76 40q-66.84 0-113.42-46.58-46.58-46.57-46.58-113.42t46.58-113.42Q668.54-400 735.38-400q66.85 0 113.43 46.58 46.57 46.57 46.57 113.42t-46.57 113.42Q802.23-80 735.38-80Zm66.24-71.92 21.84-21.85-72.69-72.69v-108.92H720v121.84l81.62 81.62Z"/>
    //         </svg>
    //     ),
    //     name: 'Etkinlikler'
    // },
    finance: {
        icon: (
            <svg width="25" height="25" className="flex-shrink-0" viewBox="0 0 30 30"
                 xmlns="http://www.w3.org/2000/svg">
                <g stroke="currentColor" strokeWidth="1.2" fill="none" fillRule="evenodd"
                   strokeDasharray="0,0">
                    <path
                        d="M15 27.5c6.904 0 12.5-5.596 12.5-12.5S21.904 2.5 15 2.5 2.5 8.096 2.5 15 8.096 27.5 15 27.5z"/>
                    <path
                        d="M18.75 11.938c0-1.692-1.679-3.063-3.75-3.063m0 0c-2.071 0-3.75 1.371-3.75 3.063 0 1.69 1.791 2.605 3.75 3.062 1.875.438 3.75 1.371 3.75 3.063 0 1.69-1.679 3.062-3.75 3.062m0-12.25V6.25m0 14.875c-2.071 0-3.75-1.371-3.75-3.063M15 21.125v2.625"/>
                </g>
            </svg>
        ),
        name: 'Finans'
    },
    // customers: {
    //     icon: (
    //         <svg width="23" height="23" className="flex-shrink-0" viewBox="0 0 30 30"
    //              xmlns="http://www.w3.org/2000/svg">
    //             <path
    //                 d="M15 15a6.25 6.25 0 1 1 0-12.5A6.25 6.25 0 0 1 15 15zM3.75 27.5h22.5c-1.502-5.071-5.971-8.75-11.25-8.75S5.252 22.429 3.75 27.5z"
    //                 stroke="currentColor" strokeWidth="1.2" fill="none" fillRule="evenodd"
    //                 strokeDasharray="0,0"/>
    //         </svg>
    //     ),
    //     name: 'Müşteriler'
    // },
    // potentialCustomers: {
    //     icon: (
    //         <svg xmlns="http://www.w3.org/2000/svg" className="flex-shrink-0" height="28" viewBox="0 -960 960 960"
    //              width="28"
    //              fill="currentColor">
    //             <path
    //                 d="M480.13-120q-74.67 0-140.41-28.34-65.73-28.34-114.36-76.92-48.63-48.58-76.99-114.26Q120-405.19 120-479.87q0-74.67 28.34-140.41 28.34-65.73 76.92-114.36 48.58-48.63 114.26-76.99Q405.19-840 479.87-840q74.67 0 140.41 28.34 65.73 28.34 114.36 76.92 48.63 48.58 76.99 114.26Q840-554.81 840-480.13q0 74.67-28.34 140.41-28.34 65.73-76.92 114.36-48.58 48.63-114.26 76.99Q554.81-120 480.13-120Zm-.13-40q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm.18-120q-83.26 0-141.72-58.28Q280-396.56 280-479.82q0-83.26 58.28-141.72Q396.56-680 479.82-680q83.26 0 141.72 58.28Q680-563.44 680-480.18q0 83.26-58.28 141.72Q563.44-280 480.18-280Zm-.18-40q66 0 113-47t47-113q0-66-47-113t-113-47q-66 0-113 47t-47 113q0 66 47 113t113 47Zm0-120q-16.08 0-28.04-11.96T440-480q0-16.08 11.96-28.04T480-520q16.08 0 28.04 11.96T520-480q0 16.08-11.96 28.04T480-440Z"/>
    //         </svg>
    //     ),
    //     name: 'Potansiyel Müşteriler'
    // },
    // products: {
    //     icon: (
    //         <svg width="25" height="25" className="flex-shrink-0" viewBox="0 0 30 30"
    //              xmlns="http://www.w3.org/2000/svg">
    //             <g stroke="currentColor" strokeWidth="1.2" fill="none" fillRule="evenodd"
    //                strokeLinejoin="round">
    //                 <path
    //                     d="M5 10h20v15H5zM8.75 5 5 10h3.75zM25 10l-3.75-5v5zM21.25 5H8.75v5h12.5z"/>
    //             </g>
    //         </svg>
    //     ),
    //     name: 'Ürünler'
    // },
    // staff: {
    //     icon: (
    //         <svg width="24" height="24" className="flex-shrink-0" viewBox="0 0 24 24"
    //              xmlns="http://www.w3.org/2000/svg">
    //             <path
    //                 d="M8.5 12.5c-2.301 0-4.167-1.903-4.167-4.25S6.2 4 8.5 4c2.301 0 4.167 1.903 4.167 4.25S10.8 12.5 8.5 12.5zm9.5 2c-1.534 0-2.778-1.231-2.778-2.75S16.466 9 18 9s2.778 1.231 2.778 2.75S19.534 14.5 18 14.5zM1 21c1.002-3.449 3.98-5.95 7.5-5.95 2.533 0 4.786 1.296 6.223 3.308M16 21a8.736 8.736 0 0 0-1.277-2.642M23 21c-.668-2.231-2.654-3.85-5-3.85-1.24 0-2.38.452-3.277 1.208"
    //                 stroke="currentColor" strokeWidth="1.2" fill="none" fillRule="evenodd"
    //                 strokeDasharray="0,0"/>
    //         </svg>
    //     ),
    //     name: 'Personel'
    // },
    // branch: {
    //     icon: (
    //         <svg width="22" height="22" className="flex-shrink-0" viewBox="0 0 24 24"
    //              xmlns="http://www.w3.org/2000/svg">
    //             <path
    //                 d="M6 2H2v4m4-4v20H2v-4M6 2h4m0 0h4v4m-4-4v20h4v-4M2 6h12M2 6v4m12-4v8M2 10v4m0-4h16M2 14v4m0-4h12M2 18h12m4-8h4v12h-4v-4h-4m4-8V7m-4 7v4m4-5v2"
    //                 stroke="currentColor" strokeWidth="1.2" fill="none" fillRule="evenodd"
    //                 strokeDasharray="0,0"/>
    //         </svg>
    //     ),
    //     name: 'Şube'
    // }
}

export const REPORT_DATE_RANGE = [
    {label: 'Bugün', value: 'today'},
    {label: 'Yarın', value: 'tomorrow'},
    {label: 'Bu hafta', value: 'this_week'},
    {label: 'Bu ay', value: 'this_month'},
    {label: 'Bu yıl', value: 'this_year'},
    {label: 'Gelecek ay', value: 'next_month'},
    {
        label: 'Gelecek 3 ay',
        value: 'next_3_month'
    },
    {
        label: 'Gelecek 6 ay',
        value: 'next_6_month'
    },
    {label: 'Gelecek yıl', value: 'next_year'},
    {label: 'Dün', value: 'yesterday'},
    {label: 'Geçen hafta', value: 'past_week'},
    {label: 'Geçen ay', value: 'past_month'},
    {
        label: 'Geçen 3 ay',
        value: 'past_3_month'
    },
    {
        label: 'Geçen 6 ay',
        value: 'past_6_month'
    },
    {label: 'Geçen yıl', value: 'past_year'},
]

export const REPORT_DATE_RANGE_TEXT = [
    {label: 'Bugün', value: 'today'},
    {label: dayjs().add(1, 'days').format('DD MMMM YYYY') + ' (Yarın)', value: 'tomorrow'},
    {label: dayjs().startOf('isoWeek').format('DD MMMM YYYY') + ' - ' + dayjs().endOf('isoWeek').format('DD MMMM YYYY') + ' (Bu hafta)', value: 'this_week'},
    {label: dayjs().startOf('month').format('DD') + ' - ' + dayjs().endOf('month').format('DD MMMM YYYY') + ' (Bu ay)', value: 'this_month'},
    {label: dayjs().startOf('year').format('DD MMMM') + ' - ' + dayjs().endOf('year').format('DD MMMM YYYY') + ' (Bu yıl)', value: 'this_year'},
    {label: dayjs().add(1, 'month').startOf('month').format('DD') + ' - ' + dayjs().add(1, 'month').endOf('month').format('DD MMMM YYYY') + ' (Gelecek ay)', value: 'next_month'},
    {
        label: dayjs().add(1, 'month').startOf('month').format('DD MMMM YYYY') + ' - ' + dayjs().add(3, 'month').endOf('month').format('DD MMMM YYYY') + ' (Gelecek 3 ay)',
        value: 'next_3_month'
    },
    {
        label: dayjs().add(1, 'month').startOf('month').format('DD MMMM YYYY') + ' - ' + dayjs().add(6, 'month').endOf('month').format('DD MMMM YYYY') +  ' (Gelecek 6 ay)',
        value: 'next_6_month'
    },
    {label: dayjs().add(1, 'year').startOf('year').format('DD MMMM YYYY') + ' - ' + dayjs().add(1, 'year').endOf('year').format('DD MMMM YYYY') + ' (Gelecek yıl)', value: 'next_year'},
    {label: dayjs().add(-1, 'days').format('DD MMMM YYYY') + ' (Dün)', value: 'yesterday'},
    {label: dayjs().subtract(1, 'week').startOf('isoWeek').format('DD MMMM YYYY') + ' - ' + dayjs().subtract(1, 'week').endOf('isoWeek').format('DD MMMM YYYY') + ' (Geçen hafta)', value: 'past_week'},
    {label: dayjs().subtract(1, 'month').startOf('month').format('DD') + ' - ' + dayjs().subtract(1, 'month').endOf('month').format('DD MMMM YYYY') + ' (Geçen ay)', value: 'past_month'},
    {
        label: dayjs().subtract(3, 'month').startOf('month').format('DD MMMM YYYY') + ' - ' + dayjs().subtract(1, 'month').endOf('month').format('DD MMMM YYYY') + ' (Geçen 3 ay)',
        value: 'past_3_month'
    },
    {
        label: dayjs().subtract(6, 'month').startOf('month').format('DD MMMM YYYY') + ' - ' + dayjs().subtract(1, 'month').endOf('month').format('DD MMMM YYYY') +  ' (Geçen 6 ay)',
        value: 'past_6_month'
    },
    {label: dayjs().subtract(1, 'year').startOf('year').format('DD MMMM') + ' - ' + dayjs().subtract(1, 'year').endOf('year').format('DD MMMM YYYY') +  ' (Geçen yıl)', value: 'past_year'},
]

export const REPORT_TOTAL_TYPES = {
    income_total_amount: 'Toplam Gelir',
    income_amount_cash: 'Nakit Geliri',
    income_amount_bank: 'Banka/EFT Geliri',
    income_amount_card: 'Kart Geliri',
    total_appointments: 'Randevu Sayısı',
    expense_amount_package_refund: 'Paket İade',
    expense_amount_staff_bonus: 'Prim',
    expense_amount_staff_salary: 'Maaş',
    expense_amount_staff_advance: 'Avans',
    expense_amount_expense: 'Masraf',
    expense_total_amount: 'Toplam Gider',
}

export const findReportRangeDate = date => {
    return REPORT_DATE_RANGE_TEXT.find(r => r.value === date)?.label || date
}

export const getEducationSetting = key => EDUCATION_SETTINGS.find(g => g.key === key)?.value
export const getConfirmationType = key => confirmationTypes.find(g => g.key === key)
export const getEventDataType = key => eventDataTypes.find(g => g.key === key)?.value
export const getGenderName = key => genders.find(g => g.key === key)?.value
export const getEventTypeName = key => eventTypes.find(g => g.key === key)?.value
export const getEventTypeIcon = (key, size = 18) => eventTypes.find(g => g.key === key)?.icon?.(size)
export const getEventType = key => eventTypes.find(g => g.key === key)
export const getEventStatus = key => eventStatusList.find(g => g.key === key)
export const getPaymentTypeName = key => paymentTypes.find(g => g.key === key)?.value
export const getPageType = key => pageTypes.find(g => g.key === key)?.value

export function isSubdomain() {
    const [, , subdomain] = window.location.hostname.split(".").reverse()
    if (import.meta.env.MODE === 'development') {
        return window.location.hostname.split('.').length > 1
    }
    return !!subdomain
}

export function getSubdomain() {
    const [, , subdomain] = window.location.hostname.split(".").reverse()
    if (import.meta.env.MODE === 'development') {
        return window.location.hostname.split('.')[0]
    }
    return subdomain
}