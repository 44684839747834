import {Helmet} from "react-helmet";
import Feature from "~/components/web/feature/index.jsx";
import {useQuery} from "@tanstack/react-query";
import {webService} from "~/services/web/index.js";
import Loader from "~/components/loader/index.jsx";
import {Link, NavLink} from "react-router-dom";
import classNames from "classnames";
import {useSettings} from "~/store/web.js";

export default function Features() {

    const settings = useSettings()
    const {data, isLoading} = useQuery(['web/cms/features'], () => webService.cms.features())
    const {data: categories} = useQuery(['web/cms/categories'], () => webService.cms.categories())

    return (
        <>
            <Helmet>
                <title>Özellikler - {import.meta.env.VITE_TITLE}</title>
            </Helmet>

            <section className="container mx-auto mt-8 md:mt-16 pb-8 md:pb-16 border-b border-[#ebebeb]">

                <h2 className="text-2xl md:text-[55px] md:leading-[65px] text-center font-semibold">
                    Tüm Özellikler
                </h2>

                {categories && (
                    <nav className="flex sticky whitespace-nowrap overflow-auto top-4 z-10 px-4 md:px-0 md:flex-wrap md:justify-center gap-2.5 items-center mt-6">
                        <NavLink
                            end={true}
                            className={({isActive}) => classNames("h-9 rounded-full px-4 flex items-center border border-zinc-300 text-[15px]", {
                                "!border-[#22c55f] !text-white !bg-[#22c55f]": isActive,
                                "bg-white": !isActive
                            })}
                            to={`/features`}
                        >
                            Tüm
                        </NavLink>
                        {categories.data.filter(c => c.category_id !== settings?.smart_control_system_category_id).map(category => (
                            <NavLink
                                className={({isActive}) => classNames("h-9 rounded-full px-4 flex items-center border border-zinc-300 text-[15px]", {
                                    "!border-[#22c55f] !text-white !bg-[#22c55f]": isActive,
                                    "bg-white": !isActive
                                })}
                                to={`/features/${category.category_slug}`}
                            >
                                {category.category_title}
                            </NavLink>
                        ))}
                    </nav>
                )}

                <div className="mt-4 md:mt-8">
                    {isLoading && <Loader/>}

                    {data && (
                        <div className="divide-y divide-[#ebebeb]">
                            {data.data.map((feature, index) => <Feature feature={feature} key={index}/>)}
                        </div>
                    )}
                </div>

            </section>
        </>
    )
}
