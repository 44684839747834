import {useFormikContext} from "formik";
import {getNamePrefix} from "~/utils/format";
import {dateFormat} from "~/utils/date";
import {AiOutlineDelete, AiOutlinePushpin} from "react-icons/ai";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {appointmentService} from "~/services/app";
import Loader from "~/components/loader";
import {useState} from "react";
import {TailSpin} from "react-loader-spinner";
import classNames from "classnames";
import {permission} from "~/utils/index.js";
import {useTranslation} from "react-i18next";

export default function Notes() {

	const {values, setFieldValue} = useFormikContext()

	const {
		data,
		isLoading
	} = useQuery(['notes', values.data.appointment_customer_id], () => appointmentService.notes(values.data.appointment_customer_id))

	return (
		<>
			{isLoading && <Loader/>}
			{data?.data?.length > 0 && data?.data?.map((note, key) => <Note key={key} note={note}/>)}
			{data?.pinned?.length > 0 && data?.pinned?.map((note, key) => <Note key={key} note={note}/>)}
		</>
	)
}

function Note({note}) {

	const {t} = useTranslation()
	const {values, setFieldValue} = useFormikContext()
	const [loading, setLoading] = useState(false)
	const queryClient = useQueryClient()

	return (
		<div className="mb-4 mx-4 group last:mb-0 gap-x-2 flex items-end">
			<div
				className="w-8 h-8 rounded-full flex-shrink-0 bg-blue-100 text-blue-500 text-xs font-medium flex items-center justify-center">
				{getNamePrefix(note.staff.name)}
			</div>
			<div
				className={classNames("bg-white shadow-chat border border-zinc-300 px-4 py-3 rounded-tl-2xl rounded-tr-2xl flex-col rounded-br-2xl w-[100%] inline-flex text-[13px] rounded-bl", {
					"!border-[#e6a925]": note.pinned
				})}>
				{note.note}

				<div className="flex items-center mt-2 justify-between">
					<div className="text-[10px] text-zinc-500">
						{dateFormat(note.date)} -{' '}
						<span
							dangerouslySetInnerHTML={{
								__html: t('{{- staff}} ekledi', {staff: (
									'<span class="text-zinc-800">' + note.staff.name + '</span>'
									)})
							}}
						/>
					</div>
					<div className="flex gap-2 items-center">
						{note.pinned && (
							<div className="flex text-[11px] text-orange-600 gap-1 items-center">
								{t('Sabitli')}
								<AiOutlinePushpin size={12} />
							</div>
						)}

						{permission('delete', 'note') && (
							<button
								type="button"
								onClick={async () => {
									setLoading(true)
									await appointmentService.noteDelete(note.id)
									await queryClient.setQueryData(['notes', values.data.appointment_customer_id], data => {
										const d = {...data}
										d.data = d.data.filter(n => n.id !== note.id)
										return d
									})
									setLoading(false)
								}}
								className="invisible opacity-0 group-hover:opacity-100 group-hover:visible transition-all rounded text-red-600 flex items-center justify-center"
							>
								{loading ? (
									<TailSpin
										height="16"
										width="16"
										color="#111"
										radius="1"
										visible={true}
									/>
								) : <AiOutlineDelete size={16}/>}
							</button>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}
