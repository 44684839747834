import Yup from "./validation"

export const customerValidation = Yup.object().shape({
  customer_name: Yup.string()
    .required(),
  customer_phone: Yup.string()
    .required(),
  customer_gender: Yup.string()
    .required()
});
