import {IoCloseOutline} from "react-icons/io5";
import {appointmentServices, getNamePrefix, moneyFormat, phoneFormat} from "~/utils/format";
import dayjs from "dayjs";
import {Form, Formik, useFormikContext} from "formik";
import SelectStaff from "~/modals/app/appointment/create/schedule-appointment/select-staff";
import SelectStatus from "~/modals/app/appointment/create/schedule-appointment/select-status";
import Button from "~/components/form/button";
import {appointmentService} from "~/services/app";
import {useEffect, useState} from "react";
import {TailSpin} from "react-loader-spinner";
import {
    AiOutlineCalendar,
    AiOutlineInfoCircle,
    AiOutlinePlus,
    AiOutlinePlusCircle, AiOutlinePrinter
} from "react-icons/ai";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {motion} from "framer-motion";
import {calculateAppointmentTime, calculateTimeDifference, dateFormat} from "~/utils/date";
import {Link, useParams, useSearchParams} from "react-router-dom";
import {BsCheck2Circle} from "react-icons/bs";
import {modal} from "~/store/modal";
import AppointmentConfirmation from "~/components/appointment-confirmatin";
import {FiEdit2} from "react-icons/fi";
import {MdCancel, MdPhoneInTalk} from "react-icons/md";
import Sidebar from "~/modals/app/appointment/detail/sidebar";
import classNames from "classnames";
import {Popover} from "@headlessui/react";
import useIsMobile from "~/hooks/use-is-mobile.js";
import {useTranslation} from "react-i18next";
import {useApplications} from "~/store/business";

export default function AppointmentDetailModal({close, data: modalData}) {

    const [searchParams] = useSearchParams()
    const {
        data: appointment,
        isLoading,
        isFetching,
        refetch
    } = useQuery(['appointment', modalData.appointment_id], () => appointmentService.detail(modalData.appointment_id), {
        refetchOnWindowFocus: false,
        keepPreviousData: true
    })
    const queryClient = useQueryClient()

    const data = appointment && appointment.data

    if (isLoading || isFetching) {
        return (
            <div className="w-full lg:w-[80vw] flex h-full items-center justify-center">
                <TailSpin
                    height="40"
                    width="40"
                    color="#555"
                    radius="1"
                    visible={true}
                />
            </div>
        )
    }

    const callback = async () => {
        modalData?.onSuccess?.()
        await refetch()
        await queryClient.invalidateQueries(['appointments', searchParams.get('date')])
    }

    return (
        <Formik
            initialValues={{
                data: data,
                newServices: [],
                newServicesData: [],
                services: data?.appointment_services,
                currentServices: data?.services,
                loading: false,
                appointmentId: data?.appointment_id,
                customerId: data?.appointment_customer_id,
                roomId: data?.appointment_room_id,
                staffId: data?.appointment_staff_id,
                status: data?.appointment_status,
                notes: data?.appointment_notes,
                serviceAdd: false
            }}
            onSubmit={async values => {

                const formData = {
                    hash_id: data.hash_id,
                }

                if (values.staffId) {
                    formData.appointment_staff_id = values.staffId
                }

                if (values.status !== data?.appointment_status) {
                    formData.appointment_status = values.status
                }

                formData.service_name = appointmentServices(values?.currentServices)

                const response = await appointmentService.update(formData)

                modalData?.onSuccess?.()

                await queryClient.invalidateQueries(['appointments', searchParams.get('date')])
                await queryClient.setQueryData(['appointment', modalData.appointment_id], d => {
                    return response
                })

            }}
        >
            <Form className="w-full lg:w-[80vw] flex h-full">
                <AppointmentDetail close={close} data={data} refetch={refetch} modalData={modalData}
                                   callback={callback}/>
            </Form>
        </Formik>
    )
}

function AppointmentDetail({data, close, callback, refetch, modalData}) {

    const applications = useApplications()
    const {t, i18n} = useTranslation()
    const [tab, setTab] = useState('info')
    const isMobile = useIsMobile()
    const activeRoom = data && data.rooms.find(r => r.room_id === data.appointment_room_id)
    const {slug} = useParams()
    const queryClient = useQueryClient()
    const [searchParams] = useSearchParams()
    const [loading, setLoading] = useState(false)
    const {values, setFieldValue, isSubmitting, isValid, dirty} = useFormikContext()

    useEffect(() => {
        if (data?.appointment_status !== values.status) {
            switch (values.status) {
                case 7:
                    modal.append('app.appointment.cancel', {
                        setFieldValue,
                        appointmentId: data?.appointment_id,
                        currentStatus: data?.appointment_status,
                        service_name: appointmentServices(values?.currentServices),
                        callback
                    })
                    break
                case 6:
                    modal.append('app.appointment.postpone', {
                        setFieldValue,
                        appointment: data,
                        appointmentId: data?.appointment_id,
                        currentStatus: data?.appointment_status,
                        roomId: data?.appointment_room_id,
                        customer: data?.customer,
                        customerId: data?.appointment_customer_id,
                        service_name: appointmentServices(values?.currentServices),
                        callback
                    })
                    break;
            }
        }
    }, [values.status])

    return (
        <>
            <div className="flex-1 flex-col flex overflow-auto">

                {isMobile && (
                    <div className="m-4 bg-zinc-100 rounded-md grid grid-cols-2 p-1 sticky top-2 z-10">
                        <button
                            type="button"
                            onClick={() => {
                                setTab('info')
                            }}
                            className={classNames("h-10 text-sm font-medium rounded-md", {
                                "bg-white shadow-lg shadow-black/5": tab === 'info'
                            })}
                        >
                            {t('Bilgiler')}
                        </button>
                        <button
                            type="button"
                            onClick={() => {
                                setTab('notes')
                            }}
                            className={classNames("h-10 text-sm font-medium rounded-md", {
                                "bg-white shadow-lg shadow-black/5": tab === 'notes'
                            })}
                        >
                            {t('Notlar')}
                        </button>
                    </div>
                )}

                {!isMobile && (
                    <header className="h-[60px] flex items-center border-b">
                        <h1 className="text-xl font-semibold px-5">
                            {t('Randevu detayı')}
                        </h1>
                        <button
                            type="button"
                            onClick={close}
                            className="w-16 h-16 flex items-center justify-center ml-auto text-zinc-500 hover:text-black">
                            <IoCloseOutline size={30}/>
                        </button>
                    </header>
                )}

                {((isMobile && tab === 'info') || !isMobile) && (
                    <div className="flex-1 flex flex-col">

                        <div className="border-b group">
                            <div
                                className="p-4 w-full flex self-start items-center justify-between hover:bg-blue-50/20"
                            >
                                <Link
                                    onClick={() => close()}
                                    to={`/${slug}/customers/detail/${data?.customer?.customer_id}`}
                                    className="flex items-center gap-x-4">
                                    <div
                                        className="w-14 h-14 text-lg font-semibold rounded-full bg-blue-50 text-blue-700 flex items-center justify-center">
                                        {getNamePrefix(data?.customer?.customer_name)}
                                    </div>
                                    <div className="text-left">
                                        <h6 className="font-medium">{data?.customer?.customer_name}</h6>
                                        <p className="text-zinc-500 flex items-center gap-4">
                                            {phoneFormat('+' + data?.customer?.customer_phone_ext + data?.customer?.customer_phone)}
                                            {(applications?.zeta) && (
                                                <button
                                                    type="button"
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        e.preventDefault()
                                                        modal.append('app.application.zeta.start-call', {
                                                            phone: Number(String(data?.customer?.customer_phone_ext) + String(data?.customer?.customer_phone))
                                                        })
                                                    }}
                                                >
                                                    <MdPhoneInTalk size={20} className="text-green-600" />
                                                </button>
                                            )}
                                        </p>
                                    </div>
                                </Link>

                                {!isMobile && (
                                    <>
                                        {data?.appointment_confirmation && (
                                            <AppointmentConfirmation type={data?.appointment_confirmation}
                                                                     hashId={data?.hash_id}
                                                                     onSuccess={modalData?.refetch}/>
                                        )}

                                        <div className="flex flex-col items-end gap-1">
                                            <h6 className="text-zinc-600"><span
                                                className="font-medium text-black">{t('Bakiye')}:</span> {moneyFormat(data?.customer?.customer_balance)}
                                            </h6>
                                            {data?.customer?.customer_balance > 0 && (
                                                <Button
                                                    variant="success"
                                                    size="small"
                                                    onClick={() => {
                                                        modal.append('app.customer.installment', {
                                                            ...data.customer,
                                                            onSuccess: async balance => {
                                                                await queryClient.invalidateQueries(['appointments', searchParams.get('date')])
                                                                await refetch()
                                                            }
                                                        })
                                                    }}
                                                >
                                                    {t('Ödeme al')}
                                                </Button>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>

                            {isMobile && (
                                <div className="grid grid-cols-2 px-4 pb-4">
                                    {data?.appointment_confirmation && (
                                        <AppointmentConfirmation type={data?.appointment_confirmation}
                                                                 hashId={data?.hash_id}
                                                                 onSuccess={modalData?.refetch}/>
                                    )}

                                    <div className="flex flex-col items-end gap-1">
                                        <h6 className="text-zinc-600"><span
                                            className="font-medium text-black">{t('Bakiye')}:</span> {moneyFormat(data?.customer?.customer_balance)}
                                        </h6>
                                        {data?.customer?.customer_balance > 0 && (
                                            <Button
                                                variant="success"
                                                size="small"
                                                onClick={() => {
                                                    modal.append('app.customer.installment', {
                                                        ...data.customer,
                                                        onSuccess: async balance => {
                                                            await queryClient.invalidateQueries(['appointments', searchParams.get('date')])
                                                            await refetch()
                                                        }
                                                    })
                                                }}
                                            >
                                                {t('Ödeme al')}
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="p-4">

                            <div
                                className="bg-zinc-100 flex items-center justify-between rounded py-3 lg:py-5 px-3 lg:px-6">
                                <div className="hidden lg:block">
                                    <h6 className="text-xs text-zinc-500 mb-1">{t('HİZMET')}</h6>
                                    <p>
                                        {appointmentServices(values?.currentServices)}
                                    </p>
                                </div>

                                <div
                                    className="lg:ml-auto w-full lg:w-auto grid grid-cols-3 lg:flex items-center text-center gap-x-6">
                                    <div>
                                        <h6 className="text-xs text-zinc-500 mb-1">{t('İPTAL EDİLEN')}</h6>
                                        <p>{data.customer.customer_appointment_stats.cancel}</p>
                                    </div>
                                    <div>
                                        <h6 className="text-xs text-zinc-500 mb-1">{t('ERTELEME')}</h6>
                                        <p>{data.customer.customer_appointment_stats.postpone}</p>
                                    </div>
                                    <div>
                                        <h6 className="text-xs text-zinc-500 mb-1">{t('TAMAMLANAN')}</h6>
                                        <p>{data.customer.customer_appointment_stats.complete}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="my-6 flex items-center justify-between">

                                <div
                                    className="hover:bg-zinc-100 transition-colors group p-3 flex items-center rounded-md w-full lg:w-auto justify-between lg:justify-start">
                                    <div className="flex items-center gap-x-3 text-15 text-zinc-500">
                                        <div className="relative flex-shrink-0">
                                            <img src="/img/calendar.svg" className="h-12"/>
                                            <span
                                                className="absolute top-0 left-0 w-full h-full flex items-center justify-center text-white text-2xl font-semibold pt-2">
										{dayjs(data.appointment_date_start).locale(i18n.language).format('DD')}
									</span>
                                        </div>
                                        <div className="flex flex-col">
                                            <div
                                                className="font-medium text-zinc-700">{dayjs(data.appointment_date_start).locale(i18n.language).format('MMMM')}</div>
                                            <div className="text-sm">
                                                {dayjs(data.appointment_date_start).format('HH:mm')} -{' '}
                                                {dayjs(data.appointment_date_end).format('HH:mm')}
                                            </div>
                                        </div>
                                    </div>
                                    {(values.status === 1 && !isMobile) && (
                                        <button
                                            type="button"
                                            onClick={() => {
                                                modal.append('app.appointment.change-date', {
                                                    setFieldValue,
                                                    appointment: data,
                                                    appointmentId: data?.appointment_id,
                                                    currentStatus: data?.appointment_status,
                                                    roomId: data?.appointment_room_id,
                                                    customer: data?.customer,
                                                    customerId: data?.appointment_customer_id,
                                                    callback
                                                })
                                            }}
                                            className="w-8 h-8 ml-3 transition-all hover:border-zinc-300 flex-shrink-0 bg-white border rounded-md flex items-center justify-center text-zinc-600 opacity-0 group-hover:opacity-100">
                                            <FiEdit2 size={14}/>
                                        </button>
                                    )}

                                    <div className="flex items-center gap-2.5">
                                        {(values.status === 1 && isMobile) && (
                                            <Button
                                                variant="white"
                                                onClick={() => {
                                                    modal.append('app.appointment.change-date', {
                                                        setFieldValue,
                                                        appointment: data,
                                                        appointmentId: data?.appointment_id,
                                                        currentStatus: data?.appointment_status,
                                                        roomId: data?.appointment_room_id,
                                                        customer: data?.customer,
                                                        customerId: data?.appointment_customer_id,
                                                        callback
                                                    })
                                                }}
                                            >
                                                <FiEdit2 size={14}/>
                                            </Button>
                                        )}
                                        <Button
                                            variant="white"
                                            className="lg:ml-4"
                                            type="button"
                                            onClick={() => {
                                                modal.append('app.appointment.create', {
                                                    roomId: data.appointment_room_id,
                                                    customerId: data?.customer?.customer_id,
                                                    customer: data?.customer
                                                })
                                            }}
                                        >
                                            <AiOutlineCalendar size={17}/>
                                            <span className="hidden lg:inline">{t('Randevu')}</span>
                                        </Button>
                                    </div>
                                </div>

                                {!isMobile && (
                                    <div className="flex items-center gap-x-2">
                                        <SelectStaff appointment={data}/>
                                        <SelectStatus appointment={data}/>
                                    </div>
                                )}

                            </div>

                            {isMobile && (
                                <div className="grid grid-cols-2 gap-x-2">
                                    <SelectStaff appointment={data}/>
                                    <SelectStatus appointment={data}/>
                                </div>
                            )}

                            <div className="py-6 my-6 border-t border-b">

                                {!values.serviceAdd && (
                                    <motion.div
                                        initial={{opacity: 0}}
                                        animate={{opacity: 1}}
                                        exit={{opacity: 0}}
                                    >
                                        <header className="mb-3 flex items-center justify-between">

                                            <h6 className="text-xl">{t('Hizmetler')} ({values?.services?.length || 0})</h6>
                                            {![4, 7].includes(data?.appointment_status) && (
                                                <button
                                                    type="button"
                                                    onClick={() => setFieldValue('serviceAdd', !values.serviceAdd)}
                                                    className="flex items-center text-sm gap-x-2 border h-9 rounded-md px-4 border-zinc-300 hover:border-zinc-500"
                                                >
                                                    {t('Hizmet Ekle')}
                                                    <AiOutlinePlusCircle size={20}/>
                                                </button>
                                            )}

                                        </header>

                                        {values?.currentServices?.length === 0 && (
                                            <div className="border border-zinc-300 rounded p-4 text-sm text-zinc-600">
                                                {t('Bu randevunun aktif hizmeti bulunmuyor.')}
                                            </div>
                                        )}

                                        {values?.currentServices?.length > 0 && (
                                            <div className="grid grid-cols-2 gap-x-4 gap-y-2">
                                                {values.currentServices.map(service => (
                                                    <button
                                                        type="button"
                                                        disabled={loading || [4, 7].includes(data?.appointment_status)}
                                                        onClick={() => {
                                                            setLoading(service.history_id)
                                                            appointmentService.cancelAppointmentService({
                                                                history_id: service.history_id,
                                                                appointment_id: data.appointment_id
                                                            })
                                                                .then(() => {
                                                                    setFieldValue('services', values.services.filter(id => id !== service.history_id))
                                                                    setFieldValue('currentServices', values.currentServices.filter(s => s.history_id !== service.history_id))
                                                                    setLoading(false)
                                                                })
                                                        }}
                                                        className="h-10 shadow-service border border-[#e1e1e1] hover:border-zinc-500 group flex gap-4 items-center justify-between px-3"
                                                    >
                                                        <div
                                                            className="text-[13px] text-zinc-500 whitespace-nowrap">
                                                            {t('{{seance}} Seans', {seance: service.history_seance})}
                                                        </div>
                                                        <div
                                                            className="text-sm whitespace-nowrap overflow-hidden text-ellipsis">{service.service_name}</div>
                                                        {![4, 7].includes(data?.appointment_status) && (
                                                            <div
                                                                className="ml-auto flex text-red-500 items-center justify-center w-5 h-5"
                                                            >
                                                                {loading === service.history_id && (
                                                                    <TailSpin
                                                                        height="18"
                                                                        width="18"
                                                                        color="#333"
                                                                        radius="1"
                                                                        visible={true}
                                                                    />
                                                                ) || <IoCloseOutline size={18}/>}
                                                            </div>
                                                        )}
                                                    </button>
                                                ))}
                                            </div>
                                        )}

                                    </motion.div>
                                )}

                                {values.serviceAdd && <AddNewService/>}

                            </div>

                            {data.appointment_status === 1 && (
                                <div
                                    className="p-4 rounded-full bg-zinc-100 gap-x-4 border text-[15px] border-zinc-400 text-zinc-600 flex items-center">
                                    <AiOutlineInfoCircle size={24}/>
                                    {t('Bu randevu için henüz işleme başlanmamış.')}
                                </div>
                            )}

                            {data.appointment_status === 3 && (
                                <div
                                    className="p-4 rounded-full bg-zinc-100 gap-x-4 border text-[15px] border-zinc-400 text-zinc-600 flex items-center">
                                    <AiOutlineInfoCircle size={24}/>
                                    {t('Randevu işlemde, geçen süre')}: {' '}
                                    <AppointmentTime data={data}/>
                                </div>
                            )}

                            {data.appointment_status === 4 && (
                                <div
                                    className="p-4 rounded-full bg-[#4caf50] gap-x-4 text-[15px] text-white flex items-center">
                                    <BsCheck2Circle size={24}/>
                                    {t('Randevu {{time}} içinde tamamlandı.', {time: calculateAppointmentTime(data.appointment_start_date, data.appointment_end_date)})}
                                </div>
                            )}

                            {data.appointment_status === 7 && (
                                <div
                                    className="p-4 rounded-full bg-red-500 gap-x-4 text-[15px] text-white flex items-center">
                                    <MdCancel size={24}/>
                                    <div className="flex items-center gap-4">
                                        <h6 className="font-bold">{t('İptal Sebebi')}: </h6>
                                        {data.appointment_cancel_reason}
                                    </div>
                                </div>
                            )}

                        </div>

                        <section
                            className="bg-white flex items-center justify-end gap-x-2.5 py-3 px-4 mt-auto sticky bottom-0 border-t">

                            <div className="mr-auto flex gap-2.5">
                                <Popover className="relative">
                                    {({close}) => (
                                        <>
                                            <Popover.Button type="button"
                                                            className="whitespace-nowrap hover:border-zinc-300 h-12 border rounded text-base font-semibold gap-4 flex items-center px-4">

                                                <span className="hidden lg:inline">{activeRoom?.room_name}</span>

                                                <svg width="24" height="24" viewBox="0 0 24 24"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <g fill="none" fillRule="evenodd">
                                                        <path d="M12 20.5V13l7.5-7.5m-15 0 5.379 5.379" stroke="#000"
                                                              strokeWidth="1.2" strokeDasharray="0,0"/>
                                                        <path d="M21 4v5.5L15.5 4H21zM3 4v5.5L8.5 4H3z" fill="#000"/>
                                                    </g>
                                                </svg>

                                            </Popover.Button>
                                            <Popover.Panel as="div"
                                                           className="absolute grid bottom-0 min-w-[200px] bg-white rounded shadow py-2">
                                                {data.rooms.map(room => (
                                                    <button
                                                        onClick={() => {
                                                            // setTimeRange(1)
                                                            appointmentService.update({
                                                                hash_id: data.hash_id,
                                                                appointment_room_id: room.room_id
                                                            })
                                                                .then(async () => {
                                                                    await callback()
                                                                    close()
                                                                })
                                                        }}
                                                        className={classNames("text-left whitespace-nowrap h-9 text-[15px] font-medium px-3 hover:bg-zinc-50", {
                                                            "!bg-blue-500 !text-white": room.room_id === data.appointment_room_id
                                                        })} type="button">
                                                        {room.room_name}
                                                    </button>
                                                ))}
                                            </Popover.Panel>
                                        </>
                                    )}
                                </Popover>
                                <Button
                                    type="button"
                                    variant="white"
                                    size="medium"
                                    onClick={() => modal.append('app.appointment.service-receipt', {
                                        appointment_id: data.appointment_id,
                                        appointment_date_start: data.appointment_date_start,
                                        services: values.currentServices,
                                        customer: data?.customer
                                    })}
                                >
                                    <AiOutlinePrinter size={24}/>
                                </Button>
                            </div>

                            <Button onClick={close} type="button" variant="default" size="medium">
                                {t('Kapat')}
                            </Button>
                            <Button variant="black" type="submit" loading={isSubmitting} disabled={!isValid || !dirty}
                                    size="medium">
                                {t('Kaydet')}
                            </Button>
                        </section>

                    </div>
                )}

                {(isMobile && tab === 'notes') && (
                    <div className="h-full flex flex-col [&>aside]:flex-1">
                        <Sidebar refetch={refetch}/>
                    </div>
                )}

            </div>

            {!isMobile && <Sidebar refetch={refetch}/>}
        </>
    )

}

export function AppointmentTime({data}) {
    const [count, setCount] = useState(0)
    useEffect(() => {
        const interval = setInterval(() => setCount(c => c + 1), 1000)
        return () => {
            clearInterval(interval)
        }
    }, [])
    return calculateTimeDifference(data.appointment_start_date)
}

function AddNewService() {

    const {t} = useTranslation()
    const {values, setFieldValue} = useFormikContext()
    const {data} = useQuery(['services', values?.customerId, values?.roomId, values?.services], () => appointmentService.services({
        customer_id: values?.customerId,
        room_id: values?.roomId,
        currentServices: JSON.stringify(values?.services)
    }), {
        enabled: !!values?.customerId && !!values?.roomId
    })
    return (
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
        >

            <header className="mb-3 flex items-center justify-between">

                <h6 className="text-xl">{t('Hizmet Ekle')}</h6>

                <div className="flex gap-2.5">
                    {data?.data?.length > 0 && (
                        <button
                            type="button"
                            onClick={() => {
                                if (values.newServices.length === data?.data?.length) {
                                    setFieldValue('newServices', [])
                                    setFieldValue('newServicesData', [])
                                } else {
                                    setFieldValue('newServices', data.data.map(h => h.history_id))
                                    setFieldValue('newServicesData', data.data)
                                }
                            }}
                            className={classNames("text-[13px] border py-1.5 px-4 rounded-md", {
                                "border-blue-500 text-blue-500 hover:bg-blue-50": values.newServices.length !== data?.data?.length,
                                "border-red-500 text-red-500 hover:bg-red-50": values.newServices.length === data?.data?.length
                            })}
                        >
                            {values.newServices.length === data?.data?.length ? t('Tümünü Kaldır') : t('Tümünü Ekle')}
                        </button>
                    )}

                    <button
                        type="button"
                        onClick={() => setFieldValue('serviceAdd', false)}
                        className="flex items-center text-sm text-red-600 gap-x-2 border h-9 rounded-md px-4 border-red-500 hover:bg-red-50"
                    >
                        {t('Vazgeç')}
                    </button>
                </div>

            </header>

            {data?.data?.length === 0 && (
                <div className="border border-zinc-300 rounded p-4 text-sm text-zinc-600">
                    {t('Bu oda için müşterinin hizmeti bulunmuyor.')}
                </div>
            )}

            {data?.data && (
                <div className="grid grid-cols-2 gap-x-4 gap-y-2">
                    {data.data.map(service => (
                        <button
                            type="button"
                            onClick={() => {
                                if (values.newServices.includes(service.history_id)) {
                                    setFieldValue('newServices', values.newServices.filter(id => id !== service.history_id))
                                    setFieldValue('newServicesData', values.newServicesData.filter(s => s.history_id !== service.history_id))
                                } else {
                                    setFieldValue('newServices', [...values.newServices, service.history_id])
                                    setFieldValue('newServicesData', [...values.newServicesData, service])
                                }
                            }}
                            className="h-10 shadow-service border border-[#e1e1e1] hover:border-zinc-500 group flex gap-4 items-center justify-between px-3"
                        >
                            <div className="text-[13px] text-zinc-500 whitespace-nowrap">
                                {t('{{seance}}. Seans', {seance: service.history_seance})}
                            </div>
                            <div
                                className="text-sm whitespace-nowrap overflow-hidden text-ellipsis">{service.service_name}</div>
                            {!values?.newServices?.includes(service.history_id) && (
                                <div
                                    className="ml-auto flex items-center justify-center w-5 h-5 rounded-full border border-zinc-300 group-hover:border-zinc-500"
                                >
                                    <AiOutlinePlus size={10}/>
                                </div>
                            ) || (
                                <div
                                    className="ml-auto flex text-red-500 items-center justify-center w-5 h-5"
                                >
                                    <IoCloseOutline size={18}/>
                                </div>
                            )}
                        </button>
                    ))}
                </div>
            )}

            {values.newServices.length > 0 && (
                <div className="col-span-2 grid mt-3">
                    <Button
                        variant="black"
                        type="button"
                        loading={values.loading}
                        disabled={values.loading}
                        onClick={async () => {
                            setFieldValue('loading', true)
                            await appointmentService.addAppointmentService({
                                appointment_id: values.appointmentId,
                                services: JSON.stringify(values.newServices)
                            })
                            setFieldValue('loading', false)
                            setFieldValue('services', [...values.services, ...values.newServices])
                            setFieldValue('currentServices', [...values.currentServices, ...values.newServicesData])
                            setFieldValue('newServices', [])
                            setFieldValue('newServicesData', [])
                            setFieldValue('serviceAdd', false)
                        }}
                    >
                        {t('Seçilen Hizmetleri Ekle')}
                    </Button>
                </div>
            )}
        </motion.div>
    )
}
