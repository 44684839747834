import {Link} from "react-router-dom";

export default function SmartBusiness() {
    return (
        <section className="py-16 bg-[#fafafa]">
            <div className="container mx-auto">

                <h3 className="text-xl md:text-4xl font-semibold text-center text-[#4b4b4b] mb-4">
                    Akıllı yönetilen merkezler
                </h3>
                <p className="text-[#727272] w-[85%] md:w-[55%] mx-auto font-light text-base md:text-lg text-center">
                    WiseCRM'in akıllı kontrol sistemi, odalarınızın klima, lamba, temiz hava ve kirli hava emiş kontrolünü tek bir platform üzerinden yönetmenize olanak tanır.
                </p>

                <div className="grid md:grid-cols-3 gap-10 md:gap-6 px-4 md:px-16 mt-10">

                    <div className="text-center">
                        <img src="/img/smart/1.webp" alt="" className="w-full"/>
                        <h6 className="text-[#4b4b4b] font-medium text-xl py-2">
                            Akıllı yönetilen merkezler
                        </h6>
                        <p className="text-[#727272] font-light">
                            Odalarınızın klima, lamba ve temiz hava kontrolünü yönetmenizi sağlar.
                        </p>
                    </div>

                    <div className="text-center">
                        <img src="/img/smart/2.webp" alt="" className="w-full"/>
                        <h6 className="text-[#4b4b4b] font-medium text-xl py-2">
                            Oda bilgi tableti
                        </h6>
                        <p className="text-[#727272] font-light">
                            Günlük randevu listesi ve müşteri notları ile işlem yapılacak bölgeleri gösterir.
                        </p>
                    </div>

                    <div className="text-center">
                        <img src="/img/smart/3.webp" alt="" className="w-full"/>
                        <h6 className="text-[#4b4b4b] font-medium text-xl py-2">
                            Tablet imza
                        </h6>
                        <p className="text-[#727272] font-light">
                            Mobil İmzalı Sözleşmeler ile işlemleri hızlandırın, müşteri memnuniyetini artırın.
                        </p>
                    </div>

                </div>

                <nav className="flex justify-center items-center mt-16">
                    <Link
                        to="/smart-control-system"
                        className="h-11 flex items-center min-w-[220px] justify-center px-4 bg-black text-white font-medium"
                    >
                        Detaylı incele
                    </Link>
                </nav>

            </div>
        </section>
    )
}