import {Link, useParams} from "react-router-dom";
import {useTablet} from "~/store/tablet";
import Header from "~/pages/tablet/header";
import Button from "~/components/form/button";
import {useQuery} from "@tanstack/react-query";
import {appointmentService, tabletService} from "~/services/app";
import {appointmentServices, getNamePrefix, moneyFormat, phoneFormat} from "~/utils/format";
import dayjs from "dayjs";
import Status from "~/components/status";
import {motion} from "framer-motion";
import {AiOutlineInfoCircle} from "react-icons/ai";
import {BsArrowLeft, BsCheck2Circle} from "react-icons/bs";
import {calculateAppointmentTime} from "~/utils/date";
import {MdCancel} from "react-icons/md";
import {AppointmentTime} from "~/modals/app/appointment/detail";
import {BiNote} from "react-icons/bi";
import {useState} from "react";
import Notes from "~/pages/tablet/hardware/appointment/notes";
import {Popover} from "@headlessui/react";
import {modal} from "~/store/modal";
import {TailSpin} from "react-loader-spinner";
import {IoCloseOutline} from "react-icons/io5";

export default function AppointmentDetail() {

	const {slug, id} = useParams()
	const tablet = useTablet(slug)

	const [showNotes, setShowNotes] = useState(false)
	const [loading, setLoading] = useState(false)

	console.log('tablet', tablet)

	const {
		data: appointment,
		isFetching,
		refetch
	} = useQuery(['tablet/appointment', id, slug], () => tabletService.appointment(id))

	return (
		<>

			<Header
				device={!!tablet?.sonoff_url}
				name={tablet.tablet.name}
				back={
					appointment?.data?.appointment_status !== 3 ? <Button variant="black" as={Link} to={`/tablet/${slug}`}>
						<BsArrowLeft size={18}/>
						Geri Dön
					</Button> : false
				}
			>
				<Button variant="white" onClick={refetch} loading={isFetching}>
					Yenile
				</Button>
				{appointment?.data?.appointment_status === 8 && (
					<Button
						variant="green"
						onClick={() => {
							modal.append('shared.confirm', {
								title: 'İşlemi başlatıyorsunuz, hizmet vermeye hazırsanız devam edin.',
								onConfirm: ({close, setLoading}) => {
									setLoading(true)
									tabletService.appointmentUpdate({
										hash_id: appointment.data.hash_id,
										appointment_status: 3
									})
										.then(() => {
											refetch()
												.then(() => {
													close()
													if (tablet?.sonoff_url && tablet?.tablet?.sonoff?.led) {
														fetch(
															tablet?.tablet?.sonoff?.led?.split('_')?.[1]
																? `${tablet.sonoff_url}toggle-status/${tablet?.tablet?.sonoff?.led?.split('_')?.[0]}/${tablet?.tablet?.sonoff?.led?.split('_')?.[1]}?status=1`
																: `${tablet.sonoff_url}toggle-status/${tablet?.tablet?.sonoff?.led?.split('_')?.[0]}?status=1`
														)
													}
												})
										})
								}
							})
						}}
					>
						İşleme Başla
					</Button>
				)}
				{appointment?.data?.appointment_status === 3 && (
					<Button variant="delete" onClick={() => {
						modal.append('shared.confirm', {
							title: 'İşlemi başlatıyorsunuz, hizmet vermeye hazırsanız devam edin.',
							onConfirm: ({close, setLoading}) => {
								setLoading(true)
								tabletService.appointmentUpdate({
									hash_id: appointment.data.hash_id,
									appointment_status: 4
								})
									.then(() => {
										refetch()
											.then(() => {
												close()
												if (tablet?.sonoff_url && tablet?.tablet?.sonoff?.led) {
													fetch(
														tablet?.tablet?.sonoff?.led?.split('_')?.[1]
															? `${tablet.sonoff_url}toggle-status/${tablet?.tablet?.sonoff?.led?.split('_')?.[0]}/${tablet?.tablet?.sonoff?.led?.split('_')?.[1]}?status=0`
															: `${tablet.sonoff_url}toggle-status/${tablet?.tablet?.sonoff?.led?.split('_')?.[0]}?status=0`
													)
												}
											})
									})
							}
						})
					}}>
						İşlemi Bitir
					</Button>
				)}
			</Header>

			{appointment && (
				<div className="flex-1 flex-col flex dark:text-white">
					<div className="flex-1 overflow-auto flex flex-col">

						<div className="border-b dark:border-zinc-700 group">
							<div
								className="py-1 px-3 w-full flex self-start items-center justify-between hover:bg-blue-50/20"
							>
								<button
									className="flex text-left items-center gap-x-4">
									<div
										className="w-14 h-14 text-lg font-semibold rounded-full bg-blue-50 dark:bg-blue-400/20 dark:text-blue-500 text-blue-700 flex items-center justify-center">
										{getNamePrefix(appointment?.data?.customer?.customer_name)}
									</div>
									<div className="text-left">
										<h6 className="font-medium">{appointment?.data?.customer?.customer_name}</h6>
										<p className="text-zinc-500">
											{phoneFormat('+' + appointment?.data?.customer?.customer_phone_ext + appointment?.data?.customer?.customer_phone)}
										</p>
									</div>
								</button>

								<Status id={appointment.data.appointment_status}/>

								<div className="hover:bg-zinc-100 transition-colors group p-3 flex items-center gap-6 rounded-md">
									<div className="flex items-center gap-x-3 text-15 text-zinc-500">
										<div className="relative">
											<img src="/img/calendar.svg" className="h-12"/>
											<span
												className="absolute top-0 left-0 w-full h-full flex items-center justify-center text-white text-2xl font-semibold pt-2">
										{dayjs(appointment.data.appointment_date_start).format('DD')}
									</span>
										</div>
										<div className="flex flex-col">
											<div
												className="font-medium text-zinc-700 dark:text-zinc-300">{dayjs(appointment.data.appointment_date_start).format('MMMM')}</div>
											<div className="text-sm dark:text-zinc-400">
												{dayjs(appointment.data.appointment_date_start).format('HH:mm')} -{' '}
												{dayjs(appointment.data.appointment_date_end).format('HH:mm')}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="p-3">

							<div className="bg-zinc-100 dark:bg-zinc-800 flex items-center justify-between rounded py-3 px-4">
								<div>
									<h6 className="text-[11px] text-zinc-500 dark:text-zinc-400 mb-1">HİZMET</h6>
									<p className="text-[15px]">
										{appointmentServices(appointment?.data?.services)}
									</p>
								</div>

								<div className="ml-auto flex items-center text-center gap-x-6">
									<div>
										<h6 className="text-[11px] text-zinc-500 dark:text-zinc-400 mb-1">İPTAL EDİLEN</h6>
										<p className="text-[15px]">{appointment.data.customer.customer_appointment_stats.cancel}</p>
									</div>
									<div>
										<h6 className="text-[11px] text-zinc-500 dark:text-zinc-400 mb-1">ERTELEME</h6>
										<p className="text-[15px]">{appointment.data.customer.customer_appointment_stats.postpone}</p>
									</div>
									<div>
										<h6 className="text-[11px] text-zinc-500 dark:text-zinc-400 mb-1">TAMAMLANAN</h6>
										<p className="text-[15px]">{appointment.data.customer.customer_appointment_stats.complete}</p>
									</div>
								</div>
							</div>

							<div className="py-3 mb-3 dark:border-zinc-700">

								<motion.div
									initial={{opacity: 0}}
									animate={{opacity: 1}}
									exit={{opacity: 0}}
								>

									{appointment?.data?.services?.length === 0 && (
										<div
											className="border border-zinc-300 dark:border-zinc-700 dark:text-zinc-300 rounded p-4 text-sm text-zinc-600">
											Bu randevunun aktif hizmeti bulunmuyor.
										</div>
									)}

									{appointment?.data?.services?.length > 0 && (
										<div className="grid grid-cols-2 gap-x-4 gap-y-2">
											{appointment?.data?.services.map(service => (
												<button
													type="button"
													disabled={[4, 7, 3].includes(appointment?.data?.appointment_status)}
													onClick={() => {
														modal.append('shared.confirm', {
															title: 'Hizmeti kaldırmak istediğinize emin misiniz?',
															onConfirm: async ({close, setLoading: setGlobalLoading}) => {
																setGlobalLoading(true)
																await tabletService.cancelAppointmentService({
																	history_id: service.history_id,
																	appointment_id: appointment.data.appointment_id
																})
																await refetch()
																close()
															}
														})
													}}
													className="h-10 shadow-service dark:shadow-zinc-800 dark:border-zinc-600 border border-[#e1e1e1] hover:border-zinc-500 group flex gap-4 items-center justify-between px-3"
												>
													<div
														className="text-[13px] text-zinc-500 dark:text-zinc-400 whitespace-nowrap">{service.history_seance}.
														Seans
													</div>
													<div
														className="text-sm whitespace-nowrap overflow-hidden text-ellipsis">{service.service_name}</div>
													{![4, 7, 3].includes(appointment?.data?.appointment_status) && (
														<div
															className="ml-auto flex text-red-500 items-center justify-center w-5 h-5"
														>
															{loading === service.history_id && (
																<TailSpin
																	height="18"
																	width="18"
																	color="#333"
																	radius="1"
																	visible={true}
																/>
															) || <IoCloseOutline size={18}/>}
														</div>
													)}
												</button>
											))}
										</div>
									)}

								</motion.div>

							</div>

							{appointment.data.appointment_status === 1 && (
								<div
									className="p-4 rounded-full bg-zinc-100 dark:bg-zinc-700 dark:text-white dark:border-zinc-500 gap-x-4 border text-[15px] border-zinc-400 text-zinc-600 flex items-center">
									<AiOutlineInfoCircle size={24}/>
									Bu randevu için henüz işleme başlanmamış.
								</div>
							)}

							{appointment.data.appointment_status === 3 && (
								<div
									className="p-4 rounded-full bg-zinc-100  dark:bg-zinc-700 dark:text-white dark:border-zinc-500 gap-x-4 border text-[15px] border-zinc-400 text-zinc-600 flex items-center">
									<AiOutlineInfoCircle size={24}/>
									Randevu işlemde, geçen süre: {' '}
									<AppointmentTime data={appointment.data}/>
								</div>
							)}

							{appointment.data.appointment_status === 4 && (
								<div
									className="p-4 rounded-full bg-[#4caf50] gap-x-4 text-[15px] text-white flex items-center">
									<BsCheck2Circle size={24}/>
									Randevu {' '}
									{calculateAppointmentTime(appointment.data.appointment_start_date, appointment.data.appointment_end_date)}
									{' '} içinde tamamlandı.
								</div>
							)}

							{appointment.data.appointment_status === 7 && (
								<div
									className="p-4 rounded-full bg-red-500 gap-x-4 text-[15px] text-white flex items-center">
									<MdCancel size={24}/>
									<div className="flex items-center gap-4">
										<h6 className="font-bold">İptal Sebebi: </h6>
										{appointment.data.appointment_cancel_reason}
									</div>
								</div>
							)}

						</div>

					</div>
				</div>
			)}

			{[3, 8].includes(appointment?.data?.appointment_status) && (
				<Popover>
					{({open}) => (
						<>
							<Popover.Button
								className="w-14 h-14 rounded-full z-[11] fixed bottom-6 right-6 bg-black dark:bg-zinc-600 text-white flex items-center justify-center">
								<BiNote size={24}/>
							</Popover.Button>
							<Popover.Overlay className="fixed inset-0 bg-black opacity-30 dark:opacity-60 z-[10]"/>
							<Popover.Panel>
								<Notes appointmentId={appointment?.data?.appointment_id}
											 customerId={appointment?.data?.appointment_customer_id}/>
							</Popover.Panel>
						</>
					)}
				</Popover>
			)}

		</>
	)
}
