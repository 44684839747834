import Button from "~/components/form/button";
import {useTabletData} from "~/pages/tablet/context";

export default function Settings() {

	const {zoom, setZoom, theme, setTheme} = useTabletData()

	return (
		<div className="fixed h-[calc(100%-3.5rem)] flex-col dark:bg-black z-[13] w-full bg-white flex items-center justify-center gap-4 bottom-0">
			<div className="text-center">
				<h6 className="mb-3 text-lg font-light text-zinc-600 dark:text-zinc-400">Tablet Boyutu</h6>
				<div className="flex items-center gap-3">
					<Button
						variant={zoom === 0.8 ? 'black' : 'white'}
						onClick={() => setZoom(0.8)}
					>
						Küçük
					</Button>
					<Button
						variant={zoom === 1 ? 'black' : 'white'}
						onClick={() => setZoom(1)}
					>
						Normal
					</Button>
					<Button
						variant={zoom === 1.2 ? 'black' : 'white'}
						onClick={() => setZoom(1.2)}
					>
						Orta
					</Button>
					<Button
						variant={zoom === 1.4 ? 'black' : 'white'}
						onClick={() => setZoom(1.4)}
					>
						Büyük
					</Button>
					<Button
						variant={zoom === 1.6 ? 'black' : 'white'}
						onClick={() => setZoom(1.6)}
					>
						Çok Büyük
					</Button>
				</div>
			</div>

			<div className="text-center">
				<h6 className="mb-3 text-lg font-light text-zinc-600 dark:text-zinc-400">Tablet Görünümü</h6>
				<div className="flex items-center gap-3">
					<Button
						variant={theme === 'light' ? 'black' : 'white'}
						onClick={() => setTheme('light')}
					>
						Açık
					</Button>
					<Button
						variant={theme === 'dark' ? 'black' : 'white'}
						onClick={() => setTheme('dark')}
					>
						Koyu
					</Button>
				</div>
			</div>
		</div>
	)
}
