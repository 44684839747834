import {TailSpin} from "react-loader-spinner";

export default function FullScreenLoader({ text = ''}) {
	return (
		<div className="h-full fixed inset-0 bg-zinc-800 w-full flex items-center justify-center flex-col gap-4">
			<TailSpin
				height="50"
				width="50"
				color="#fff"
				radius="1"
				visible={true}
			/>
			{text && <h1 className="text-white text-xl font-medium mt-2.5">{text}</h1>}
		</div>
	)
}
