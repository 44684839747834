import Light from "~/pages/tablet/hardware/devices/light";
import {useParams} from "react-router-dom";
import {useTablet} from "~/store/tablet";
import Vacuum from "~/pages/tablet/hardware/devices/vacuum";
import Fan from "~/pages/tablet/hardware/devices/fan";

export default function Devices() {

	const {slug} = useParams()
	const tablet = useTablet(slug)

	return (
		<div className="fixed h-[calc(100%-3.5rem)] dark:bg-black overflow-auto flex-wrap py-4 z-[13] w-full bg-white flex items-center justify-center gap-4 bottom-0">
			<div className="grid grid-cols-3 gap-4 w-[90%] pb-[80px]">
				{tablet?.tablet?.sonoff?.light && (
					<Light
						size="normal"
						deviceId={tablet?.tablet?.sonoff?.light.split('_')[0]}
						channel={tablet?.tablet?.sonoff?.light.split('_')[1]}
					/>
				)}

				{tablet?.tablet?.sonoff?.vacuum && (
					<Vacuum
						size="normal"
						deviceId={tablet?.tablet?.sonoff?.vacuum.split('_')[0]}
						channel={tablet?.tablet?.sonoff?.vacuum.split('_')[1]}
					/>
				)}

				{tablet?.tablet?.sonoff?.fan && (
					<Fan
						size="normal"
						deviceId={tablet?.tablet?.sonoff?.fan.split('_')[0]}
						channel={tablet?.tablet?.sonoff?.fan.split('_')[1]}
					/>
				)}
			</div>
		</div>
	)
}
