import {useParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {tabletService} from "~/services/app";
import Hardware from "~/pages/tablet/hardware";
import Signature from "~/pages/tablet/signature";
import {useTablet} from "~/store/tablet";
import TabletLogin from "~/pages/tablet/login";
import {useModal} from "~/store/modal";
import {AnimatePresence} from "framer-motion";
import Modals from "~/modals/tablet";
import {TabletProvider} from "~/pages/tablet/context";

export default function Tablet() {

	const {slug} = useParams()
	const {data, isLoading} = useQuery(['tablet', slug], () => tabletService.detail(slug), {
		retry: 0
	})
	const tablet = useTablet(slug)
	const modals = useModal()

	return data && (
		<TabletProvider>
			<div className="flex flex-col">
				<AnimatePresence>
					{modals.length > 0 && <Modals/>}
				</AnimatePresence>
				{!tablet && (
					<TabletLogin data={data.data}/>
				)}
				{tablet && (
					<>
						{data?.data?.type === 'hardware' && <Hardware data={data.data}/>}
						{data?.data?.type === 'signature' && <Signature data={data.data}/>}
					</>
				)}
			</div>
		</TabletProvider>
	)
}
