import Yup from "./validation"

export const salePaymentValidation = Yup.object().shape({
  price: Yup
    .string()
    .required()
    .test(
      'price-lower-than-paidPrice',
      'Miktar ödenecek toplam miktardan yüksek olamaz',
      function (value) {
        // `this.resolve` ile diğer alanlara referans sağlayarak
        // paidPrice değerini alabiliriz.
        const paidPrice = Number(Number(this.resolve(Yup.ref('paid'))).toFixed(2));
        return Number(Number(value).toFixed(2)) <= paidPrice;
      }
    )
    .test(
      'price-greater-than-zero',
      'Lütfen bir miktar belirtin',
      function (value) {
        return Number(value) > 0;
      }
    )
});
