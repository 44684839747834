import {motion} from "framer-motion";
import PropTypes from "prop-types";

export default function AnimatedContent({ delay, children, ...props }) {
	return (
		<motion.div
			initial={{ opacity: 0, marginTop: 5 }}
			animate={{ opacity: 1, marginTop: 0, transition: { delay }}}
			exit={{ opacity: 0, marginTop: 6 }}
			{...props}
		>
			{children}
		</motion.div>
	)
}

AnimatedContent.propTypes = {
	delay: PropTypes.number,
	children: PropTypes.node,
	props: PropTypes.object
}

AnimatedContent.defaultProps = {
	delay: 0
}
