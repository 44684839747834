import notify from "react-hot-toast"
import {store} from "~/store";

function parseData(data) {
    const formData = new FormData()
    for (let [key, value] of Object.entries(data)) {
        if (typeof value === 'object' && value !== null) {
            if (value instanceof File) {
                formData.append(key, value)
            } else {
                [...value].map(file => {
                    formData.append(key + '[]', file)
                })
            }
        } else if (Array.isArray(value)) {
            for (let file of value) {
                formData.append(`${key}${value.length > 1 ? '[]' : ''}`, file?.uploaded ? JSON.stringify(file) : file)
            }
        } else {
            formData.append(key, value)
        }
    }
    return formData
}

function request(url, method = 'get', data = {}, opts = {}) {
    return new Promise(async (resolve, reject) => {
        const options = {
            method
        }
        const settings = {
            success: true,
            error: true,
            ...opts
        }
        const states = store.getState()
        if (states.auth?.token) {
            data.token = states.auth?.token
        }
        if (states.admin?.token) {
            data.adminToken = states.admin.token
        }
        if (states.business?.staffToken) {
            data.staffToken = states.business?.staffToken
        }
        if (states.tablet?.tablet?.token) {
            data.tabletToken = states.tablet?.tablet?.token
        }
        if (data && method === 'post') {
            options.body = parseData(data)
        }
        const result = await (await fetch(`${import.meta.env.VITE_API_URL}/api/v1/${url}`, options)).json()
        if (result?.status === 200) {
            if (result?.message && settings.success) {
                notify.success(result.message)
            }
            resolve(result)
        } else {
            if (result?.message && settings.error) {
                notify.error(result.message)
            }
            reject(result)
        }
    })
}

export const post = (url, data = {}, options = {}) => request(url, 'post', data, options);
export const get = (url, options = {}) => request(url, 'get', {}, options)
