import React from 'react'
import ReactDOM from 'react-dom/client'
import {RouterProvider} from "react-router-dom";
import routes from "~/routes";
import './assets/css/index.css'
import {store} from './store'
import {Provider} from 'react-redux'
import {Toaster} from 'react-hot-toast';
import {GoogleOAuthProvider} from '@react-oauth/google';
import {
	QueryClient,
	QueryClientProvider,
} from '@tanstack/react-query'
import './utils/date'
import {AnimatePresence} from "framer-motion";
import './utils/i18n'
import * as Sentry from "@sentry/react";

Sentry.init({
	dsn: "https://1e1afe62650532e3f9545cf44b935708@o4507123696795648.ingest.de.sentry.io/4507123709640784",
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration(),
	],
	// Performance Monitoring
	// tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	// tracePropagationTargets: ["localhost", /^https:\/\/api\.wisecrm\.io/, /http:\/\/localhost:8080/],
	// replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	// replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false
		}
	}
})

ReactDOM.createRoot(document.getElementById('root')).render(
	<Provider store={store}>
		<QueryClientProvider client={queryClient}>
			<GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}>
				<Toaster/>
				<AnimatePresence>
					<RouterProvider router={routes}/>
				</AnimatePresence>
			</GoogleOAuthProvider>
		</QueryClientProvider>
	</Provider>
)
