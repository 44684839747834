import {findReportRangeDate, REPORT_TOTAL_TYPES, REPORT_TYPES} from "~/utils/const";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {dashboardService, reportService} from "~/services/app";
import Loader from "~/components/loader";
import {moneyFormat, numberFormat} from "~/utils/format";
import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import {dayjs} from "~/utils/date";
import {IoIosArrowDown} from "react-icons/io";
import {useState} from "react";
import {Popover} from "@headlessui/react";
import classNames from "classnames";
import {modal} from "~/store/modal.js";

export default function ReportBox({report, id}) {

    const queryClient = useQueryClient()
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id});

    const style = {
        transform: CSS.Transform.toString(transform),
        // transition
    };

    const {
        data,
        isFetching,
        isLoading
    } = useQuery(['dashboard/report/detail', report.report_id], () => reportService.detail(report.report_id, {
        box_only: 'yes'
    }), {
        keepPreviousData: true
    })

    return (
        <div
            className="bg-white border rounded group aspect-square flex flex-col"
            style={style}
            ref={setNodeRef}
        >
            <header className="h-[50px] border-b flex px-4 items-center gap-3 flex-shrink-0 font-medium">
                {REPORT_TYPES[report.report_type].icon}

                <div className="truncate">
                    {report.report_title}
                </div>

                <nav className="flex ml-auto items-center gap-1.5 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all">
                    <button
                        type="button"
                        className="ml-auto text-red-600"
                        onClick={() => {
                            modal.append('shared.confirm', {
                                title: 'Raporu panodan silmek üzeresiniz?',
                                description: 'Raporun kendisi silinmez, sadece panodan kaldırılır.',
                                onConfirm: async ({ close, setLoading }) => {
                                    setLoading(true)
                                    await dashboardService.deleteReport(id)
                                    await queryClient.invalidateQueries(['dashboard/reports'])
                                    setLoading(false)
                                    close()
                                }
                            })
                        }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                             fill="currentColor">
                            <path
                                d="M292.31-140q-29.92 0-51.12-21.19Q220-182.39 220-212.31V-720h-40v-60h180v-35.38h240V-780h180v60h-40v507.69Q740-182 719-161q-21 21-51.31 21H292.31ZM680-720H280v507.69q0 5.39 3.46 8.85t8.85 3.46h375.38q4.62 0 8.46-3.85 3.85-3.84 3.85-8.46V-720ZM376.16-280h59.99v-360h-59.99v360Zm147.69 0h59.99v-360h-59.99v360ZM280-720v520-520Z"/>
                        </svg>
                    </button>
                    <button
                        type="button"
                        className="ml-auto"
                        {...attributes} {...listeners}
                    >
                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <g stroke="#5D5D5D" strokeWidth="1.2" fill="none" fillRule="evenodd">
                                <path
                                    d="M17.625 8.625 21 12l-3.375 3.375m-9 2.25L12 21l3.375-3.375m-6.75-11.25L12 3l3.375 3.375m-9 2.25L3 12l3.375 3.375M21 12h-6m-3 9v-6m0-12v6m-9 3h6"/>
                            </g>
                        </svg>
                    </button>
                </nav>
            </header>
            <div className="flex items-center justify-center flex-1 relative flex-col py-12 pb-20">
                {(isLoading || isFetching) && <Loader/>}
                {(!isFetching && data) && (
                    <>
                        <div
                            className="text-[#676767] tracking-tight absolute bottom-0 left-0 pb-2 w-full text-xs font-light flex justify-center flex-wrap gap-1.5">
                            {data?.data?.data?.report_description?.filter(d => !['date', 'date_start', 'date_end'].includes(d.column))?.map(desc => (
                                <span className="bg-zinc-100 px-1.5 py-0.5 rounded">
                                    {/*<span className="text-green-600 mr-1">{desc.name}:</span>*/}
                                    {typeof desc.value === 'object' ? desc.value.join(', ') : findReportRangeDate(desc.value)}
                                </span>
                            ))}
                        </div>
                        <div
                            className="text-4xl text-[#676767] font-medium tracking-tight text-center">
                            <ShowTotal total={data?.data?.report.total} />
                            <div className="text-base mt-2 text-zinc-400">
                                {findReportRangeDate(data?.data?.data?.report_description?.find(d => d.column === 'date')?.value)}
                                {data?.data?.data?.report_description?.find(d => d.column === 'date_end')?.value && (
                                    <>
                                        {data?.data?.data?.report_description?.find(d => d.column === 'date_start')?.value && ' - '}
                                        {dayjs(data?.data?.data?.report_description?.find(d => d.column === 'date_end')?.value).format('DD MMMM YYYY')}
                                    </>
                                )}
                            </div>
                        </div>

                    </>
                )}
            </div>
        </div>
    )
}

export function ShowTotal({ total }) {

    const [active, setActive] = useState(Object.keys(total)[0])

    return (
        <>
            <label className="flex justify-center items-center">
                <Popover className="relative mb-1.5">
                    {({ close }) => (
                        <>
                            <Popover.Button className="text-base outline-none font-medium flex items-center gap-2.5">
                                {REPORT_TOTAL_TYPES[active]}
                                <IoIosArrowDown size={16} />
                            </Popover.Button>
                            <Popover.Panel className="absolute max-h-[168px] overflow-auto grid border shadow-[0_2px_4px_0_rgba(0,0,0,0.04)] top-full left-1/2 -translate-x-1/2 translate-y-1 bg-white rounded py-2">
                                {Object.keys(total).map(key => (
                                    <button
                                        type="button"
                                        key={key}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            e.preventDefault()
                                            setActive(key)
                                            close()
                                        }}
                                        className={classNames("h-10 text-base font-medium px-4 flex items-center hover:bg-zinc-100 whitespace-nowrap", {
                                            "!bg-[#eeffee]": active === key
                                        })}
                                    >
                                        {REPORT_TOTAL_TYPES[key]}
                                    </button>
                                ))}
                            </Popover.Panel>
                        </>
                    )}
                </Popover>
            </label>
            {active !== 'total_appointments' && (
                <>
                    {total?.[active] > 0 && <span className="text-[#4caf50]">{moneyFormat(total?.[active])}</span>}
                    {total?.[active] < 0 && <span className="text-red-600">{moneyFormat(total?.[active])}</span>}
                    {total?.[active] === 0 && <span className="text-zinc-400">{moneyFormat(total?.[active])}</span>}
                </>
            )}
            {active === 'total_appointments' && numberFormat(total?.[active])}
        </>
    )
}