import * as Yup from "yup";
import i18n from "~/utils/i18n.js";

Yup.setLocale({
	mixed: {
		required: i18n.t('Bu alan zorunludur'),
		notType: i18n.t('Format uygun değil')
	},
	string: {
		email: i18n.t('Geçerli bir e-posta adresi girin'),
		min: i18n.t('Bu alan minimum ${min} karakter olmalı'),
		max: i18n.t('Bu alan maksimum ${max} karakter olmalı'),
		url: i18n.t('Geçerli bir url girin')
	},
	boolean: {
		oneOf: i18n.t('Bu alanı işaretlemeniz gerekiyor')
	}
})

export default Yup
