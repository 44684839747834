import {BsTabletLandscape} from "react-icons/bs";
import Button from "~/components/form/button";
import {AiOutlineControl} from "react-icons/ai";
import {BiCog} from "react-icons/bi";
import {useTabletData} from "~/pages/tablet/context";

export default function Header({ name, back = false, device = false, children }) {

	const {showDevice, setShowDevice, showSettings, setShowSettings} = useTabletData()

	return (
		<header className="h-14 bg-white dark:bg-zinc-900 dark:border-zinc-700 dark:text-white sticky top-0 z-10 flex-shrink-0 justify-between border-b flex items-center px-4">
			{back ? back : (
				<h6 className="text-lg font-semibold flex items-center gap-3">
					<BsTabletLandscape size={22} />
					{name}
				</h6>
			)}
			<div className="flex gap-2">
				{children}

				{device && (
					<Button
						variant={showDevice ? 'black' : 'white'}
						className="!px-2"
						onClick={() => {
							setShowDevice(!showDevice)
							setShowSettings(false)
						}}
					>
						<AiOutlineControl size={24} />
					</Button>
				)}

				<Button
					variant={showSettings ? 'black' : 'white'}
					className="!px-2"
					onClick={() => {
						setShowSettings(!showSettings)
						setShowDevice(false)
					}}
				>
					<BiCog size={24} />
				</Button>

				{/*<Button*/}
				{/*	variant="white"*/}
				{/*	onClick={() => {*/}
				{/*		if (confirm('Tabletden çıkış yapıyorsunuz, devam istiyor musunuz?')) {*/}
				{/*			removeTablet()*/}
				{/*		}*/}
				{/*	}}*/}
				{/*>*/}
				{/*	Oturumu Sonlandır*/}
				{/*</Button>*/}
			</div>
		</header>
	)
}
