import {createSlice} from "@reduxjs/toolkit";
import {useSelector} from "react-redux";
import {store} from "~/store";

const initialState = {
	staff: null,
	staffToken: localStorage.getItem('staffToken') || null,
	business: false,
	applications: {},
	search: false,
	version: localStorage.getItem('version') || false
}

const businessReducer = createSlice({
	name: 'business',
	initialState,
	reducers: {
		updateStaff: (state, action) => {
			state.staff = action.payload
		},
		updateApplications: (state, action) => {
			state.applications = action.payload
		},
		_setVersion: (state, action) => {
			state.version = action.payload
			localStorage.setItem('version', state.version)
		},
		updateStaffToken: (state, action) => {
			state.staffToken = action.payload
			if (action.payload) {
				localStorage.setItem('staffToken', action.payload)
			} else {
				localStorage.removeItem('staffToken')
			}
		},
		_setSearch: (state, action) => {
			state.search = action.payload
		},
		_logoutStaff: state => {
			localStorage.removeItem('staffToken')
			state.staffToken = null
			state.staff = null
		},
		updateBusiness: (state, action) => {
			state.business = action.payload
		}
	}
})

export const {updateBusiness, _setVersion, _setSearch, _logoutStaff, updateStaffToken, updateApplications, updateStaff} = businessReducer.actions

export const useSearch = () => useSelector(state => state.business.search)
export const useApplications = () => useSelector(state => state.business.applications)
export const useBusiness = () => useSelector(state => state.business.business)
export const useStaff = () => useSelector(state => state.business.staff)
export const usePermissions = () => useSelector(state => state.business.staff.permissions)
export const useStaffToken = () => useSelector(state => state.business.staffToken)
export const useVersion = () => useSelector(state => state.business.version)
export const setBusiness = data => store.dispatch(updateBusiness(data))
export const setStaff = data => store.dispatch(updateStaff(data))
export const setStaffToken = token => store.dispatch(updateStaffToken(token))
export const setApplications = data => store.dispatch(updateApplications(data))
export const logoutStaff = () => store.dispatch(_logoutStaff())
export const setSearch = (data) => store.dispatch(_setSearch(data))
export const setVersion = (data) => store.dispatch(_setVersion(data))

export default businessReducer.reducer
